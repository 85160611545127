import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, Checkbox, Tag, H4, NumericInput } from "@blueprintjs/core"
import { missileSalvoResolver } from "data/salvoTools"
import { rollDN } from "data/util"

import { useState, useEffect } from "react"

export default function MissileSalvoResolver({ targetScreenRating }) {
    let [missileSalvoSize, setMissileSalvoSize] = useState(6);
    let [pdsCount, setPDSCount] = useState(0);
    let [weaponCount, setWeaponCount] = useState(0);
    let [results, setResults] = useState("-- No Results --");

    let rollResults = () => {
        let damageResults = missileSalvoResolver(missileSalvoSize, pdsCount, weaponCount, targetScreenRating)
        let totalDamage = damageResults.reduce((dt,d) => dt+d,0)
        setResults(`Total Damage: ${totalDamage}\nComponents: [${damageResults.join(",")}]`)
    }

    return <div style={{ borderRadius: 10, backgroundColor: "#f7f7f7", padding: 20 }}> 
        <H4> Missile Salvo </H4>
        <FormGroup>
            <ControlGroup>
                <NumericInput size={2} leftIcon="numerical" rightElement={<Tag>Salvo Size</Tag>} type="text" value={missileSalvoSize} onValueChange={setMissileSalvoSize}/>
                <NumericInput size={2} leftIcon="numerical" rightElement={<Tag>PDS Count</Tag>} type="text" value={pdsCount} onValueChange={setPDSCount}/>
                <NumericInput size={2} leftIcon="numerical" rightElement={<Tag>Weapon Count</Tag>} type="text" value={weaponCount} onValueChange={setWeaponCount}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup>
            <ControlGroup>
                <Button style={{ marginTop:-3 }} intent={"primary"} onClick={rollResults}> Roll Salvo </Button>
            </ControlGroup>
        </FormGroup>
        <H4> Missile Salvo Results </H4>
        <pre style={{ fontWeight: "bold" }}>
            {results}
        </pre>
    </div>
}