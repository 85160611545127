import { useRef, useEffect, useState } from "react"
import ToggleableComponent from "./ToggleableComponent"
import { ArmorComponent, RegenerativeArmorComponent } from "./componentSymbols"
export default function ArmorRowDisplay({ 
    row = 0,
    armorCount = 4,
    regenerativeCount = 0,
    armorDamage = 0,
    regenerativeDamage = 0,
    onArmorDamageUpdate = () => {},
    onRegenerativeDamageUpdate = () => {}
}) {
    function* range(start, end) {
        if (start > end) return;
        yield start;
        if (start === end) return;
        yield* range(start + 1, end);
    }
    let armorColumnElements = [...range(0, armorCount - 1)].map((columnIndex) => {
        let damaged = columnIndex < armorDamage
        return <ToggleableComponent key={`${columnIndex}`} disabled={damaged} componentSymbol={<ArmorComponent/>} width={25} height={25} onToggle={() => {
            onArmorDamageUpdate(row, armorDamage + (damaged ? -1 : 1))
        }}/>
    })
    let regenerativeColumnElements = [...range(0, regenerativeCount - 1)].map((columnIndex) => {
        let damaged = columnIndex < regenerativeDamage
        return <ToggleableComponent key={`${columnIndex}`} disabled={damaged} componentSymbol={<RegenerativeArmorComponent/>} width={25} height={25} onToggle={() => {
            onRegenerativeDamageUpdate(row, regenerativeDamage + (damaged ? -1 : 1))
        }}/>
    })
    return <div style={{display: "flex", flexDirection: "row", paddingRight: 5, paddingLeft: 5 }}>
        {armorColumnElements}
        {regenerativeColumnElements}
    </div>
}