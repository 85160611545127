import { Card, H2, H3, Tabs, Tab } from "@blueprintjs/core"
import { rollDN } from "data/util"
import { useState } from "react"

import AIMovementResolver from "./AIMovementResolver"
import SalvoResolver from "./SalvoResolver"

export default function Tools() {
    let [activeTab, setActiveTab] = useState("salvo");
    return <div style={{ display: "flex", flexDirection: "column", padding: 20, gap: 10 }}> 
        <H2> Tools </H2>
        <Tabs onChange={(nt) => setActiveTab(nt)}>
            <Tab id="salvo" title={"Salvo Tool"} panel={<Card><SalvoResolver/></Card>}/>
            <Tab id="ai_movement" title={"AI Movement"} panel={<Card><AIMovementResolver/></Card>}/>
        </Tabs>
    </div>
}