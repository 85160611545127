import PubSub from 'pubsub-js'

let randomString = function(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export default class WSDataBus {
    constructor() { 
        this.socket = null;
        this.connected = false;
        this.bus_node_id = randomString(24)
    }

    connect(ws_uri) {
        this.socket = new WebSocket(ws_uri)
        this.socket.addEventListener("open", () => { 
            this.connected = true;
            PubSub.publish("websocket_connected", { client_id: this.bus_node_id })
        })
        this.socket.addEventListener("message", (message) => {
            let data = JSON.parse(message.data)
            let topic = data.topic;
            let payload = data.payload;
            PubSub.publish(topic, payload)
        })
    }

    disconnect() {
        this.socket.close()
    }

    send(topic, payload) {
        this.socket.send(JSON.stringify({ topic: topic, payload: payload, client_id: this.bus_node_id }));
    }

    subscribe(topic, handler) {
        return PubSub.subscribe(topic, (message, data) => { handler(data) })
    }

    unsubscribe(token) {
        PubSub.unsubscribe(token);
    }
}