import { useEffect, useState } from "react"
import { FormGroup, InputGroup, H1, Button, Card } from "@blueprintjs/core"
import { useSearchParams } from "react-router-dom";

export default function Login() {
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")
    let [searchParams, setSearchParams] = useSearchParams();

    let errorString = (code) => {
        if(code == undefined) { return ""; }
        switch(code) {
            case "UNM": return "Username Missing";
            case "PWM": return "Password Missing";
            default: return `A login error occured. Code: ${code}`
        }
    }

    let login = async () => {
        try {
            let response = await fetch("/user/register", {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })

            })
            console.log(response)
            let data = await response.json()
            console.log(data)
        } catch (err) {
            console.log(err)
        }
    }

    return <div style={{ display: "flex", justifyContent:"center", flexBasis: 200, gap: 20, padding: 20 }}>
        <form action="login" method="post">
            <Card>
                <H1> User Registration </H1>
                <FormGroup label="Username">
                    <InputGroup id="username" name="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
                </FormGroup>
                <FormGroup label="Password">
                    <InputGroup id="password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </FormGroup>
                <Button type="submit" intent="primary" disabled={(username.length < 1 || password.length < 1)}> Login </Button>
                <div>
                    {errorString(searchParams.get("error"))}
                </div>
            </Card>
        </form>
    </div>

}