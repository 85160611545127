import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, H2, FormGroup, ControlGroup, HTMLTable, InputGroup, AnchorButton, HTMLSelect, Dialog } from "@blueprintjs/core"
import Konva from 'konva'
import { Stage, Layer, Circle, Rect } from 'react-konva';
import HexMap from "./HexMap"
import HexCourseProjection from "./HexCourseProjection"
import HexCellOverlay from "./HexCellOverlay"
import { hexToPixel, oddQHexToPixel, pointToFlatHex, projectCourse } from '../Util/hexTools';
import Token from "./CanvasToken"
import RadialMenu from "./RadialMenu";
import RulerLine from "./RulerLine";
import HexCourseController from "./HexCourseController";
import ShipStatusDocument from "components/ShipStatusDocument"
import SalvoBuilder from "components/SalvoBuilder"
import WSDataBus from "data/Components/WSDataBus"

const loadImages = (sources, callback) => {
  var images = {};
  var loadedImages = 0;
  var numImages = 0;
  
  for (var src in sources) {
    numImages++;
  }
  for (var src in sources) {
    images[src] = new Image();
    images[src].onload = function () {
      if (++loadedImages >= numImages) {
        callback(images);
      }
    };
    images[src].src = sources[src];
  }
}

export default function Map() {

  let params = useParams()
  let stageRef = useRef(null)
  let missionMessageBus = useRef(new WSDataBus())

  let [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight})
  let [cellSize, setCellSize] = useState(50)
  let [hoveredHex, setHoveredHex] = useState({ x: 0, y: 0 })
  let [cameraOrigin, setCameraOrigin] = useState({ x: 0, y: 0})
  let [originHex, setOriginHex] = useState(null)
  let [targetToken, setTargetToken] = useState(null)
  let [pendingCourse, setPendingCourse] = useState(null)
  let [radialMenuOrigin, setRadialMenuOrigin] = useState({ x: 0, y: 0 })
  let [radialMenuVisible, setRadialMenuVisible] = useState(null)
  let [radialMenuActions, setRadialMenuActions] = useState([])
  let [dialogOpen, setDialogOpen] = useState(false)
  let [dialogComponent, setDialogComponent] = useState(<div> Dialog Component </div>)
  let [cursorActionMode, setCursorActionMode] = useState(null)
  let [tokens, setTokens] = useState([])
  let [images, setImages] = useState({})
  let [mapZoomLevel, setMapZoomLevel] = useState(1)

  
  

  useEffect(() => {
    let wscs = missionMessageBus.current.subscribe("websocket_connected", () => { console.log("Socket Connected - Attempting Mission Join"); missionMessageBus.current.send("join_mission", params.mission_id) })
    let ujms = missionMessageBus.current.subscribe("user_joined_mission", (data) => { console.log("UJMN")})
    missionMessageBus.current.connect(`${(location.protocol.includes("https") ? "wss:" : "ws:")}//${location.host}/mission_state_bus/${params.mission_id}`)

    loadImages({
      mapGrid: "assets/backgrounds/GreenTileable100pxHexGrid.png"
    }, setImages)

    return () => {
      missionMessageBus.current.unsubscribe(wscs);
      missionMessageBus.current.unsubscribe(ujms);
    }
  }, [params.mission_id])

  let getTargets = () => { console.log("Returning Target List", tokens); return tokens; }

  const tokenClickHandler = (targets, tkn) => { 
    console.log("Token Clicked - ", tkn)
    setRadialMenuVisible(true)
    setRadialMenuOrigin(oddQHexToPixel(cellSize, tkn.position.x, tkn.position.y))
    setRadialMenuActions([{
      iconCode: 0xf047,
      onClick: (e) => { 
        console.log("Start Token Movement", tkn)
        setOriginHex(tkn.position)
        setCursorActionMode("position");
        setRadialMenuVisible(false);
        setTargetToken(tkn);
        console.log("Starting Course Plot", [{ x: tkn.position.x, y: tkn.position.y}])
        setPendingCourse([{ x: tkn.position.x, y: tkn.position.y}])
      }
    },{
      iconCode: 0xf14e,
      onClick: (e) => { 
        console.log("Set Heading");
        setOriginHex(tkn.position);
        setRadialMenuVisible(false);
        setCursorActionMode("heading");
        setTargetToken(tkn);
      }
    },{
      iconCode: 0xf545,
      onClick: (e) => { 
        console.log("Measure To") 
        setCursorActionMode("ruler");
        setOriginHex(tkn.position);
        setRadialMenuVisible(false);
      }
    },{
      iconCode: 0xf71d,
      onClick: (e) => { 
        console.log("Salvo Builder") 
        setDialogOpen(true);
        setCursorActionMode("target");
        console.log("Target List", targets)
        setDialogComponent(<div><SalvoBuilder shipConfig={tkn} targetList={targets} missionMessageBus={missionMessageBus.current}/></div>)
      }
    },{
      iconCode: 0xe039,
      onClick: (e) => { 
        setDialogOpen(true);
        console.log("Ship",tkn, missionMessageBus.current)
        setDialogComponent(<ShipStatusDocument shipID={tkn.id} missionMessageBus={missionMessageBus.current}/>)
        console.log("Ship Status Document") 
      }
    },{
      iconCode: 0xf307,
      onClick: (e) => { 
      console.log("Delete Ship")
      removeShip(tkn.id)
    }
    }])
  }

  let onCellClick = ({x, y}) => {

    if(cursorActionMode == "heading") { 
      
      let originPos = oddQHexToPixel(cellSize, originHex?.x || 0, originHex?.y || 0, false)
      let hoveredPos = oddQHexToPixel(cellSize, hoveredHex?.x || 0, hoveredHex?.y || 0, false)
      let deltaY = hoveredPos.y - originPos.y;
      let deltaX = hoveredPos.x - originPos.x;
      let proposedHeading = 0;
      if(deltaX == 0 && deltaY > 0) { proposedHeading = 0 }
      if(deltaX == 0 && deltaY < 0) { proposedHeading = 180 }
      if(deltaX < 0 && deltaY > 0) { proposedHeading = 300 }
      if(deltaX < 0 && deltaY < 0) { proposedHeading = 240 }
      if(deltaX > 0 && deltaY > 0) { proposedHeading = 60 }
      if(deltaX > 0 && deltaY < 0) { proposedHeading = 120 }

      setOriginHex(null); 
      setCursorActionMode(null); 
      updateShip({ id: targetToken.id, heading: proposedHeading })
      return; 
    }

    if(cursorActionMode == "position") { 
      setOriginHex(hoveredHex); 
      // setCursorActionMode("heading"); 
      // setRadialMenuVisible(false);
      // updateShip({ id: targetToken.id,  position: hoveredHex })
      console.log("Adding to course plot", [...pendingCourse, {x: x, y: y}])
      setPendingCourse([...pendingCourse, {x: x, y: y}])
      return; 
    }

    let originPixel = oddQHexToPixel(cellSize, x, y, true);
    setRadialMenuVisible(true)
    setRadialMenuOrigin(originPixel)
    setRadialMenuActions([{
      iconCode: 0xf6fd,
      onClick: (e) => { console.log("Add Terrain") }
    },{
      iconCode: 0xf055,
      onClick: (e) => { console.log("Adding Ship At");
      addShip({x: x, y: y})
    }
    },{
      iconCode: 0xf545,
      onClick: (e) => { console.log("Ruler") }
    },{
      iconCode: 0xf00e,
      onClick: (e) => { console.log("Zoom In") 
        if(stageRef.current == null) { return; }
        let scaleBy = 1.5;
        let stage = stageRef.current
        var oldScale = stage.scaleX();
        var newScale = oldScale * scaleBy;
        stage.scale({ x: newScale, y: newScale });
        setMapZoomLevel(newScale)
      }
    },{
      iconCode: 0xf010,
      onClick: (e) => { 
        console.log("Zoom Out") 
        if(stageRef.current == null) { return; }
        let scaleBy = 1.5;
        let stage = stageRef.current
        var oldScale = stage.scaleX();
        var newScale = oldScale / scaleBy;
        stage.scale({ x: newScale, y: newScale });
        setMapZoomLevel(newScale)
      }
    }])

  }

  

  useEffect(() => {
    window.addEventListener("contextmenu", (e) => { e.preventDefault() }) // Disable Contenxt Menu Action
    window.addEventListener("resize", (e) => { setWindowSize({ width: window.innerWidth, height: window.innerHeight}) }) // Update Konvas Size on Window Resize
    Konva.dragButtons = [1,2]
    loadTokens()
  }, [])


  const pinchHandler = (e) => {}

  const zoomHandler = (e) => {
        
    if(stageRef.current == null) { return; }
    let scaleBy = 1.1;
    let stage = stageRef.current

    e.evt.preventDefault();

    var oldScale = stage.scaleX();
    var pointer = stage.getPointerPosition();

    var mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    
    let direction = e.evt.deltaY < 0 ? 1 : -1;

    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    stage.scale({ x: newScale, y: newScale });
    setMapZoomLevel(newScale)
    var newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    setCameraOrigin(newPos);
  }

  const loadTokens = async () => {
    let response = await fetch(`/ships?mission=${params.mission_id}`)
    let ships = await response.json()
    console.log("Ship List Loaded", ships);
    setTokens(ships.map(s => {
      return { 
        src: s.token_url,
        onClick: tokenClickHandler.bind(null, ships),
        size: 1.5,
        ...s 
      }
    }))
  }

  const updateShip = async (ship) => {
    console.log("Updating Ship", ship)
    let response = await fetch(`/ship/${ship.id}`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify(ship) })
    if(response.status == 200) { loadTokens() } else { console.error("Error Updating Ship", response.body)}
  }

  const addShip = async (target_position) => {
    let shipData = {
      name: `Test Ship`,
      mass: 10,
      class: "Starship",
      faction: "Blue",
      position: target_position,
      heading: 0,
      velocity: 0
    }
    let response = await fetch(`/ship/${params.mission_id}`, { method: "POST", headers: { "Content-Type": "application/json"}, body: JSON.stringify(shipData)})
    if(response.status == 200) { 
      loadTokens();  
      setRadialMenuVisible(false)
      setRadialMenuOrigin({ x: 0, y: 0 })
    } else { console.error("Error Loading Ships", response.body)}
  }

  const removeShip = async (ship_id) => {
    if(confirm("Delete Ship?")) {
      let response = await fetch(`/ship/${ship_id}`, { method: "DELETE" })
      if(response.status == 200) { 
        loadTokens()
        setRadialMenuVisible(false)
        setRadialMenuOrigin({ x: 0, y: 0 })
      } else { console.error("Error Loading Ships", response.body)}
  }
  }

  let tokenElements = tokens.map((t) => {
    return <Token 
      key={t.id}
      heading={t.heading}
      position={oddQHexToPixel(cellSize, t.position.x, t.position.y )}
      tokenSize={t.size}
      muSize={cellSize}
      src={t.src}
      onClick={t.onClick}
      properties={{ id: t.id, grid_position: t.position, ...t }}
    />
  })

  let courseProjections = tokens.reduce((a, t) => {
    if(t.pending_move == []) { return a; }
    a.push(<HexCourseProjection key={`cp_${t.id}`} waypoints={t?.pending_move?.course || []} finalHeading={t?.pending_move?.heading} cellSize={cellSize} color={"gray"}/>)
    return a;
  }, [])

  const mousePosUpdate = (e) => {
    if(stageRef.current == null) { return; }
    let stage = stageRef.current

    e.evt.preventDefault();

    var oldScale = stage.scaleX();
    var pointer = stage.getPointerPosition();

    var mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    let hh = pointToFlatHex(cellSize, mousePointTo.x, mousePointTo.y);
    var col = hh.q
    var row = hh.r + (col + (col&1)) / 2
    setHoveredHex({x: col, y: -row})
  }

  var mapBackground = {
    backgroundImage: `url("assets/backgrounds/testbackground.png")`, 
    backgroundPosition: `${Math.round(cameraOrigin.x % (512 * mapZoomLevel))}px ${Math.round(cameraOrigin.y % (512 * mapZoomLevel))}px`, 
    backgroundSize: `${Math.round(512 * mapZoomLevel)}px ${Math.round(512 * mapZoomLevel)}px`,
    backgroundRepeat: "repeat"
  }
  

  return <div><Stage ref={stageRef} width={windowSize.width} height={windowSize.height}
    draggable={true}
    onWheel={zoomHandler}
    onMouseMove={mousePosUpdate}
    style={mapBackground}
    onDragEnd={(e) => { setCameraOrigin(e.currentTarget.position()) }}
    onDragMove={(e) => { setCameraOrigin(e.currentTarget.position()) }}
  >
    <Layer>
      <Rect
          x={-cameraOrigin.x / mapZoomLevel}
          y={-cameraOrigin.y / mapZoomLevel}
          width={windowSize.width / mapZoomLevel}
          height={windowSize.height / mapZoomLevel}
          fillPatternImage={images["mapGrid"]}
          fillPatternOffset={{ x: -(((cameraOrigin.x / mapZoomLevel) % 150) + 75), y: -((cameraOrigin.y / mapZoomLevel) % 173) }}
          listening={false}
        />
      <Circle x={0} y={0} stroke="cyan" radius={5} />
      <HexCellOverlay cellSize={cellSize} gridX={hoveredHex.x} gridY={hoveredHex.y} color={"#c6c6c6"} onClick={onCellClick} onTap={onCellClick}/>
      {courseProjections}
      {tokenElements}
      <RadialMenu scale={2/mapZoomLevel} visible={radialMenuVisible} origin={radialMenuOrigin} outerSize={70} innerSize={30} indicatorSize={15} actions={radialMenuActions} onClose={() => { 
        setRadialMenuVisible(false); 
        setOriginHex(null);
        setCursorActionMode(null);
      }}/>
      {(originHex !== null ? <RulerLine cellSize={cellSize} origin={originHex} target={hoveredHex}/> : <Circle x={0} y={0} stroke="cyan" radius={0}/>)}
      {(pendingCourse !== null ? <HexCourseProjection waypoints={pendingCourse} cellSize={cellSize}/> : <Circle x={0} y={0} stroke="cyan" radius={0}/>)}
      {(pendingCourse !== null ? <HexCourseController originWaypoint={pendingCourse.at(-1)} cellSize={cellSize} onRevert={() => {
        let newCourse = [...pendingCourse];
        newCourse.pop();
        setPendingCourse(newCourse)
        setOriginHex(newCourse.at(-1))
      }} onCommit={(finalHeading) => {
        console.log("Committing Pending Course with Heading", finalHeading, pendingCourse, targetToken);
        updateShip({ id: targetToken.id, pending_move: { course: pendingCourse, heading: finalHeading } })
        setPendingCourse(null)
        setOriginHex(null)
      }}/> : <Circle x={0} y={0} stroke="cyan" radius={0}/>)}
    </Layer>
  </Stage>
  <Dialog isOpen={dialogOpen} onClose={() => { setDialogOpen(false) }} children={dialogComponent}/>
  </div>
}
