import { useRef, useEffect, useState } from "react"
import { FormGroup, InputGroup, NumericInput, HTMLSelect, Tag, ControlGroup, Button } from "@blueprintjs/core"
export default function ShipDetails({ onUpdate, ship }) {

    let [pendingShip, setPendingShip] = useState({...ship})

    const saveChanges = async () => {
        onUpdate(pendingShip)
    }

    const updateShipX = async (v) => {
        let updates = {...pendingShip};
        updates.position.x = v;
        setPendingShip(updates)
    }
    const updateShipY = async (v) => {
        let updates = {...pendingShip};
        updates.position.y = v;
        setPendingShip(updates)
    }

    const updateShipHeading = async (e) => {
        let updates = {...pendingShip};
        updates.heading = e.currentTarget.value;
        setPendingShip(updates)
    }

    const updateShipVelocity = async (v) => {
        let updates = {...pendingShip};
        updates.velocity = v;
        setPendingShip(updates)
    }

    const updateShipFaction = async (e) => {
        let updates = {...pendingShip};
        updates.faction = e.currentTarget.value;
        setPendingShip(updates)
    }

    return <div style={{ display: "flex", flexDirection: "column" }}> 
        <FormGroup label="Faction">
            <HTMLSelect value={pendingShip.faction} onChange={updateShipFaction}>
                <option value={"blue"}> Blue </option>
                <option value={"red"}> Red </option>
                <option value={"green"}> Green </option>
                <option value={"orange"}> Orange </option>
                <option value={"purple"}> Purple </option>
                <option value={"cyan"}> Cyan </option>
            </HTMLSelect>
        </FormGroup>
        <FormGroup label="Position">
            <ControlGroup>
                <NumericInput value={pendingShip.position.x} onValueChange={updateShipX} rightElement={<Tag>X</Tag>}/>
                <NumericInput value={pendingShip.position.y} onValueChange={updateShipY} rightElement={<Tag>Y</Tag>}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Heading">
            <HTMLSelect value={pendingShip.heading} onChange={updateShipHeading}>
                <option value={0}> 0 </option>
                <option value={60}> 60 </option>
                <option value={120}> 120 </option>
                <option value={180}> 180 </option>
                <option value={240}> 240 </option>
                <option value={300}> 300 </option>
            </HTMLSelect>
        </FormGroup>
        
        <FormGroup label="Velocity">
            <NumericInput value={pendingShip.velocity} onValueChange={updateShipVelocity}/>
        </FormGroup>
        <Button intent="success" onClick={saveChanges}> Save </Button>
    </div>
}