import { useState } from "react"
import { rollDN, randomFromArray, randomInt } from "data/util"
import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, H3 } from "@blueprintjs/core"

const turnProbabilityArray = [
    ["P", (max_mag) => max_mag],
    ["P", (max_mag) => max_mag],
    ["P", (max_mag) => Math.ceil(max_mag/2)],
    ["P", (max_mag) => Math.floor(max_mag/2)],
    ["", () => 0],
    ["", () => 0],
    ["", () => 0],
    ["", () => 0],
    ["S", (max_mag) => Math.floor(max_mag/2)],
    ["S", (max_mag) => Math.ceil(max_mag/2)],
    ["S", (max_mag) => max_mag],
    ["S", (max_mag) => max_mag],
]

const getCircularProbability = (sourceArray, si, sliceSize) => {
    let startIndex = si % sourceArray.length;
    if (startIndex + sliceSize <= sourceArray.length) { 
        return sourceArray.slice(startIndex, startIndex + sliceSize)
    }
    let front = sourceArray.slice(startIndex)
    let back = sourceArray.slice(0, sliceSize - front.length)
    return front.concat(back)
    
}

export default function AIMovement() {
    let [targetBearing, setTargetBearing] = useState(0)
    let [targetRange, setTargetRange] = useState(36)
    let [shipThrustRating, setShipThrustRating] = useState(4)
    let [shipDriveType, setShipDriveType] = useState(0)
    let [shipStartingVelocity, setShipStartingVelocity] = useState(0)
    let [movementOrder, setMovementOrder] = useState("")

    let generateMovementOrder = () => {
        let turn_direction = "hold";
        let available_turn_points = (shipDriveType == "advanced" ? shipThrustRating : Math.floor(shipThrustRating/2))
        switch(targetBearing) {
            case 300: { turn_direction = "left" } break;
            case 60: { turn_direction = "right" } break;
            case 220: { turn_direction = "left" } break;
            case 120: { turn_direction = "right" } break;
            case 180: { randomFromArray(["left", "right"]) } break;
        }
        let orderString = `${shipStartingVelocity}`
        let startIndex = Math.floor(targetBearing/30) + Math.floor(turnProbabilityArray.length/4)
        let turnTypes = getCircularProbability(turnProbabilityArray, startIndex, 6)
        let turnConfig = randomFromArray(turnTypes);
        let turnDirection = turnConfig[0];
        let turnMagnitude = turnConfig[1](available_turn_points);
        if (turnMagnitude == 0) { turnDirection = ""; }
        orderString += `${turnDirection}${(turnMagnitude !== 0 ? turnMagnitude: "")}`
        let availableThrust = shipThrustRating - turnMagnitude
        let thrustMagnitude = 0;
        let turnsToIntercept = ( targetRange / shipStartingVelocity )
        let turnsToZero = ( shipStartingVelocity / availableThrust )
        console.log("TTI", turnsToIntercept, turnsToZero)
        if(turnsToIntercept >= turnsToZero) { thrustMagnitude = randomInt(availableThrust) }
        if(turnsToIntercept <= turnsToZero) { thrustMagnitude = randomInt(-availableThrust) }
        setMovementOrder(`${orderString}${(thrustMagnitude >= 0 ? "+" : "")}${thrustMagnitude}`)

    }
 
    return <div> 
        <H3> AI Movement Resolver </H3>
        <FormGroup label="Bearing to Target/Intercept">
            <HTMLSelect value={targetBearing} onChange={(event) => {setTargetBearing(event.target.value)}}>
                <option value={0}> Fore </option> 
                <option value={300}> Port Fore </option> 
                <option value={60}> Starboard Fore </option> 
                <option value={220}> Aft Port </option> 
                <option value={120}> Aft Starboard </option> 
                <option value={180}> Aft </option> 
            </HTMLSelect>
        </FormGroup>
        <FormGroup label="Range to Target/Intercept">
            <InputGroup type="text" value={targetRange} onChange={e => setTargetRange(parseInt(e.target.value, 10))}/>
        </FormGroup>
        <FormGroup label="Thrust Rating - Starting Velocity">
            <ControlGroup>
                <HTMLSelect value={shipDriveType} onChange={(event) => {setShipDriveType(event.target.value)}}>
                    <option value={"standard"}> Standard </option> 
                    <option value={"advanced"}> Advanced </option> 
                </HTMLSelect>
                <InputGroup type="text" value={shipThrustRating} onChange={e => setShipThrustRating(parseInt(e.target.value, 10))}/>
                <InputGroup type="text" value={shipStartingVelocity} onChange={e => setShipStartingVelocity(parseInt(e.target.value, 10))}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Generate Movement Order">
            <Button intent="primary" onClick={generateMovementOrder}>Generate</Button>
            <div>{movementOrder}</div>
        </FormGroup>
    </div>
}
