import { Navbar, AnchorButton, NavbarHeading, NavbarDivider, Alignment } from "@blueprintjs/core"
import { getUserData } from "data/AppData"
import { useEffect, useState } from "react"

export default function SiteNavbar() {
    
    let [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        (async () => {
            let userData = await getUserData()
            if(userData) { 
                setIsLoggedIn(true)
            } else {
                setIsLoggedIn(false)
            }
        })()
    }, [])

    return <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
            <NavbarHeading> Full Thrust: Continuum - Digitial Mission System </NavbarHeading>
            <NavbarDivider/>
            <AnchorButton href="#/missions" minimal={true} icon="list"> Mission List </AnchorButton>
            <AnchorButton href="#/ships" minimal={true} icon="airplane"> Fleet Manager </AnchorButton>
            <AnchorButton href="#/map" minimal={true} icon="map"> Map </AnchorButton>
            <AnchorButton href="#/order_sheet" minimal={true} icon="edit"> Order Sheet </AnchorButton>
            <AnchorButton href="#/tools" minimal={true} icon="build"> Tools </AnchorButton>
            <AnchorButton href="#/users" minimal={true} icon="user"> Users </AnchorButton>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
            {(isLoggedIn ? <AnchorButton href="/logout" intent="danger" minimal={true} icon="lock"> Logout </AnchorButton> : <AnchorButton href="#/login" minimal={true} intent="primary" icon="lock"> Login </AnchorButton>)}
        </Navbar.Group>
    </Navbar>
}