import { useRef, useEffect, useState } from "react"
import { Line, Group, Circle, Text } from 'react-konva';
import { hexToPixel, oddQHexToPixel, pointToFlatHex, projectCourse, oddq_distance } from '../Util/hexTools';

export default function RulerLine({ cellSize, origin, target, fontSize = 20 }) {

    let rangeLineOrigin = oddQHexToPixel(cellSize, origin?.x || 0, origin?.y || 0, true)
    let rangeLineEnd = oddQHexToPixel(cellSize, target?.x || 0, target?.y || 0, true)
    let rangeLineMidpoint = { x: rangeLineOrigin.x + ((rangeLineEnd.x - rangeLineOrigin.x)/2),  y: rangeLineOrigin.y + ((rangeLineEnd.y - rangeLineOrigin.y)/2) }
    let rangeLinePoints = [rangeLineOrigin.x, rangeLineOrigin.y, rangeLineEnd.x, rangeLineEnd.y]

    return (<Group>
        <Line points={rangeLinePoints} closed={false} strokeWidth={2} stroke={"red"} listening={false}/>
        {/* <Circle x={rangeLineMidpoint.x} y={rangeLineMidpoint.y} radius={fontSize * 0.75} fill={"white"} stroke={"green"}/> */}
        <Text x={rangeLineMidpoint.x - fontSize/2} y={rangeLineMidpoint.y - fontSize/2} fontSize={fontSize} text={`${oddq_distance({ col: origin.x, row: origin.y }, { col: target.x, row: target.y })}`}/>
        
    </Group>)
}