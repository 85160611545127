import s from "connect-redis";
import { useRef, useEffect, useState } from "react"
import { Stage, Layer, Rect, Circle, Image, Text, Html, Group, Arc, Path } from 'react-konva';

export default function RadialMenu({
    origin = { x: 0, y: 0 },
    innerSize = 30,
    outerSize = 60,
    indicatorSize = 20,
    scale = 1,
    actions = [],
    visible = true,
    fontSize = 20,
    onClose = () => {}
}) {

    let [hoveredButton, setHoveredButton] = useState(-1)
    let arcSegment = Math.floor(360/actions.length)
    let initialOrientation = arcSegment + 90
    let buttonElements = actions.map((action, action_index) => {
        let button_orientation = initialOrientation + (action_index * arcSegment)
        let buttonOriginUnitY = Math.sin(((button_orientation + (arcSegment/2))/360) * 2 * Math.PI)
        let buttonOriginUnitX = Math.cos(((button_orientation + (arcSegment/2))/360) * 2 * Math.PI)
        let ringMagnitude = ((outerSize - innerSize)*(0.6)) + innerSize;
        let iconLocationY = (buttonOriginUnitY * ringMagnitude) - (fontSize/2)
        let iconLocationX = (buttonOriginUnitX * ringMagnitude) - (fontSize/2)
        let arcBaseY = buttonOriginUnitY * 5
        let arcBaseX = buttonOriginUnitX * 5
        let isHovered = hoveredButton == action_index;
        return (<Group key={`action_${action_index}`}
            onMouseEnter={() => { setHoveredButton(action_index) }}
            onMouseLeave={() => { setHoveredButton(-1) }}
            
        >
            <Arc
                angle={arcSegment}
                rotation={button_orientation}
                innerRadius={innerSize}
                outerRadius={outerSize}
                x={arcBaseX}
                y={arcBaseY}
                fill="rgba(0.79,0.79,0.79, 0.2)"
                stroke="black"
                strokeWidth={1}
                onClick={action.onClick}
                onTap={action.onClick}
            />
            <Text 
                y={iconLocationY}
                x={iconLocationX}
                text={`${String.fromCodePoint(action.iconCode || 0xf192)}`} 
                fontSize={fontSize}
                strokeWidth={0}
                stroke={(isHovered ? "white" : "black")}
                fill={(isHovered ? "white" : "black")}
                fontFamily='"Font Awesome 5 Pro"'
                listening={false}
            />
            {/* <Circle 
                y={iconLocationY}
                x={iconLocationX}
                radius={1}
                fill="red"
                stroke="red"
                strokeWidth={1}
            />
            <Circle 
                y={iconLocationY + fontSize}
                x={iconLocationX + fontSize}
                radius={1}
                fill="blue"
                stroke="blue"
                strokeWidth={1}
            />
            <Circle 
                y={iconLocationY + (fontSize/2)}
                x={iconLocationX + (fontSize/2)}
                radius={1}
                fill="green"
                stroke="green"
                strokeWidth={1}
            /> */}
            
        </Group>)
    })
    return (visible ? <Group scale={{x : scale, y: scale}} x={origin.x} y={origin.y}>
        {buttonElements}
        <Circle 
            radius={indicatorSize}
            onClick={onClose}
            onTap={onClose}
            fill="rgba(0.79,0.79,0.79, 0.2)"
            stroke="black"
            strokeWidth={1}
        />
        
    </Group> : <Circle radius={0}/>)
}