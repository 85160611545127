import { useEffect, useState, useReducer, useRef } from "react"
import styles from "./SystemAttributeManager.module.css"
import { InputGroup, NumericInput, FormGroup, Checkbox } from "@blueprintjs/core"
import ArcManager from "./ArcManager"

let hasAdvancedOption = [
    "drive",
    "ftl",
    "sensors",
    "adfc",
    "screen",
    "fireControl"
]

let hasCapacityOption = [
    "bay",
    "magazine"
]

export default function SystemAttributeManager({
    config,
    onUpdate = () => {},
}) {

    const updateArcs = (v) => {
        let new_arcs = `${v}`.replace(/[^a-fA-f]/gi, "");
        onUpdate({ arcs: `${new_arcs}`.toLocaleLowerCase() })
    }

    return <div className={styles.sam_base}>
        {(config.class !== undefined) ? <FormGroup label="Class"><NumericInput value={config.class} onValueChange={(nv) => onUpdate({ class: nv })}/></FormGroup> : ""}
        {(config.thrust !== undefined) ? <FormGroup label="Thrust"><NumericInput value={config.thrust} onValueChange={(nv) => onUpdate({ thrust: nv })}/></FormGroup> : ""}
        {(hasCapacityOption.includes(config.name)) ? <FormGroup label="Capacity"><NumericInput value={config.capacity} onValueChange={(nv) => onUpdate({ capacity: nv })}/></FormGroup> : ""}
        {(config.arcs !== undefined) ? <FormGroup label="Arcs"><ArcManager arcs={config.arcs} onUpdate={updateArcs}/></FormGroup> : ""}
        {(hasAdvancedOption.includes(config.name)) ? <FormGroup label="Advanced"><Checkbox checked={config.advanced == true} onChange={onUpdate.bind(null, { advanced: !(config?.advanced || false) })}/></FormGroup> : ""}
    </div>
}