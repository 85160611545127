
// Generic System Fallback Icon
export const GenericSystem = () => {
    return <g id="generic-component">
        <path key="cmpt-symbol" style={{ stroke: "black", fill: "black" }} d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"/>
    </g>
}

// Arc Composers
export const CompositeBeamArc = ({ arcs = "abcdef", fills = "" }) => { 
    let componentArcs = []
    if(arcs.toLocaleLowerCase().includes("a")) { componentArcs.push(CircularWeaponArc({ arcAngle: 0, opacity: ( fills.toLocaleLowerCase().includes("a") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("b")) { componentArcs.push(CircularWeaponArc({ arcAngle: 60, opacity: ( fills.toLocaleLowerCase().includes("b") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("c")) { componentArcs.push(CircularWeaponArc({ arcAngle: 120, opacity: ( fills.toLocaleLowerCase().includes("c") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("d")) { componentArcs.push(CircularWeaponArc({ arcAngle: 180, opacity: ( fills.toLocaleLowerCase().includes("d") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("e")) { componentArcs.push(CircularWeaponArc({ arcAngle: 240, opacity: ( fills.toLocaleLowerCase().includes("e") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("f")) { componentArcs.push(CircularWeaponArc({ arcAngle: 300, opacity: ( fills.toLocaleLowerCase().includes("f") ? 0.5 : 0) })) }
    return <g transform={`matrix(0,1,-1,0,256,256)`}>
        {componentArcs}
    </g>;
}

export const CircularWeaponArc = ({ arcAngle = 0, opacity = 0 }) => {
    return <g id="Weapon-Arc-Filled" key={`arc_${arcAngle}`} transform={`rotate(${arcAngle})`}>
        <path d="M-221.702,-128C-244.171,-89.083 -256,-44.937 -256,-0C-256,44.937 -244.171,89.083 -221.702,128L-146.249,84.437C-161.071,58.765 -168.874,29.644 -168.874,-0C-168.874,-29.644 -161.071,-58.765 -146.249,-84.437L-221.702,-128Z" style={{ fillOpacity: opacity }}/>
        <path d="M-221.702,-128L-146.249,-84.437C-161.071,-58.765 -168.874,-29.644 -168.874,-0C-168.874,29.644 -161.071,58.765 -146.249,84.437L-221.702,128C-244.171,89.083 -256,44.937 -256,-0C-256,-44.937 -244.171,-89.083 -221.702,-128ZM-219.347,-119.423C-239.282,-82.809 -249.75,-41.755 -249.75,-0C-249.75,41.755 -239.282,82.809 -219.347,119.423C-219.347,119.423 -154.69,82.093 -154.69,82.093C-168.093,56.838 -175.124,28.654 -175.124,-0C-175.124,-28.654 -168.093,-56.838 -154.69,-82.093L-219.347,-119.423Z"/>
    </g>
}

export const CompositeKineticArc = ({ arcs = "abcdef", fills = "" }) => { 
    let componentArcs = []
    if(arcs.toLocaleLowerCase().includes("a")) { componentArcs.push(HexWeaponArc({ arcAngle: 0, opacity: ( fills.toLocaleLowerCase().includes("a") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("b")) { componentArcs.push(HexWeaponArc({ arcAngle: 60, opacity: ( fills.toLocaleLowerCase().includes("b") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("c")) { componentArcs.push(HexWeaponArc({ arcAngle: 120, opacity: ( fills.toLocaleLowerCase().includes("c") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("d")) { componentArcs.push(HexWeaponArc({ arcAngle: 180, opacity: ( fills.toLocaleLowerCase().includes("d") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("e")) { componentArcs.push(HexWeaponArc({ arcAngle: 240, opacity: ( fills.toLocaleLowerCase().includes("e") ? 0.5 : 0) })) }
    if(arcs.toLocaleLowerCase().includes("f")) { componentArcs.push(HexWeaponArc({ arcAngle: 300, opacity: ( fills.toLocaleLowerCase().includes("f") ? 0.5 : 0) })) }
    return <g>
        {componentArcs}
    </g>;
}

export const HexWeaponArc = ({ arcAngle = 0, opacity = 0 }) => {
    return <g key={`hwa_${arcAngle}`} transform={`rotate(${arcAngle}, 256, 256)`}>
        <path id="Hex-Arc" d="M128,34.298L384,34.298L340.409,109.751L171.584,109.751L128,34.298ZM138.893,40.585L175.213,103.463L336.78,103.463L373.106,40.585L138.893,40.585Z"/>
    </g>
}

export const CompositePulserArc = ({ arcs = "abcdef" }) => { 
    let componentArcs = []
    componentArcs.push(PulserWeaponArc({ arcAngle: 0, filled: !arcs.toLocaleLowerCase().includes("a") }))
    componentArcs.push(PulserWeaponArc({ arcAngle: 60, filled: !arcs.toLocaleLowerCase().includes("b") }))
    componentArcs.push(PulserWeaponArc({ arcAngle: 120, filled: !arcs.toLocaleLowerCase().includes("c") }))
    componentArcs.push(PulserWeaponArc({ arcAngle: 180, filled: !arcs.toLocaleLowerCase().includes("d") }))
    componentArcs.push(PulserWeaponArc({ arcAngle: 240, filled: !arcs.toLocaleLowerCase().includes("e") }))
    componentArcs.push(PulserWeaponArc({ arcAngle: 300, filled: !arcs.toLocaleLowerCase().includes("f") }))
    return <g>
        {componentArcs}
    </g>;
}

export const PulserWeaponArc = ({ arcAngle = 0, filled = false }) => {
    return <g key={`pwa_${arcAngle}`} transform={`rotate(${arcAngle}, 256, 256)`}>
       <g transform="matrix(1.03695,0,0,0.999563,-87.229,-124.82)">
            {(filled ? <path d="M331,126.096L406,256L256,256L331,126.096Z"/> : "")}
            <path id="PulserArc" d="M331,126.096L406,256L256,256L331,126.096ZM331,138.265C331,138.265 266.636,249.747 266.636,249.747L395.364,249.747L331,138.265Z"/>
        </g>
    </g>
}

export const CenterCircle = ({ filled = false}) => {
    if(filled) { 
        return <g id="Center-Circle-FIlled" style={{fillRule: "evenodd", clipRule:"evenodd", strokeLinejoin:"round", strokeMiterlimit:2}} transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <circle cx="0" cy="0" r="177.969"/>
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C94.716,-171.613 171.613,-94.716 171.613,0C171.613,94.716 94.716,171.613 0,171.613C-94.716,171.613 -171.613,94.716 -171.613,0C-171.613,-94.716 -94.716,-171.613 0,-171.613Z"/>
        </g>
    }

    return <g id="Center-Circle" style={{fillRule: "evenodd", clipRule:"evenodd", strokeLinejoin:"round", strokeMiterlimit:2}} transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
        <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C94.716,-171.613 171.613,-94.716 171.613,0C171.613,94.716 94.716,171.613 0,171.613C-94.716,171.613 -171.613,94.716 -171.613,0C-171.613,-94.716 -94.716,-171.613 0,-171.613Z"/>
    </g>
}

export const CenterNumber = ({ number = 1, color = "black"}) => {
    switch(number) {
        case 2: { return <g id="_2" transform="matrix(1,0,0,1,-4.46121,133.716)"><path style={{ fill: color }} d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z"/></g> }
        case 3: { return <g id="_3" transform="matrix(1,0,0,1,-4.46121,133.716)"><path style={{ fill: color }} d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z"/></g> }
        case 4: { return <g id="_4" transform="matrix(1,0,0,1,-4.46121,133.716)"><path style={{ fill: color }} d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z"/></g> }
        case 5: { return <g id="_5" transform="matrix(1,0,0,1,-4.46121,133.716)"><path style={{ fill: color }} d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z"/></g> }
        case 6: { return <g id="_6" transform="matrix(1,0,0,1,-4.46121,133.716)"><path style={{ fill: color }} d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z"/></g> }
        default: { return <g id="_1" transform="matrix(1,0,0,1,-7.33184,132.516)"><path style={{ fill: color }} d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z"/></g> }
    }
    
}

export const CenterNumeral = ({ number = 1, color = "black"}) => {
    switch(number) {
        case 2: { return <g transform="matrix(1.92489,0,0,1.26419,-319.369,-183.617)">
            <g id="II">
                <rect x="263.731" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
                <rect x="306.77" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
            </g>
        </g> }
        case 3: { return <g transform="matrix(1.92489,0,0,1.26419,-360.791,-183.617)">
            <g id="III">
                <rect x="263.731" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
                <rect x="306.77" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
                <rect x="349.808" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
            </g>
        </g> }
        case 4: { return <g transform="matrix(1.92489,0,0,1.26419,-366.992,-183.617)">
            <g id="IV">
                <rect x="263.731" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/>
                <path d="M300.069,256L320.686,439.493L361.921,439.493L383.569,256L356.251,256L348.004,338.726C346.2,360.632 344.138,382.538 342.592,404.701L342.077,404.701C340.015,386.403 337.953,350.581 335.891,332.541L327.387,256L300.069,256Z" style={{"fillRule":"nonzero"}}/>
            </g>
        </g> }
        default: { return <g transform="matrix(1.92489,0,0,1.26419,-277.947,-183.617)"><rect id="I" x="263.731" y="256" width="27.318" height="183.493" style={{"fill" : color, "fillRule":"nonzero"}}/></g> }
    }
    
}

// Weapon Mounts

export const BeamMount = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="beamMount">
        <CenterNumber number={beamClass}/>
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle/>
    </g>
}

export const MissileLauncher = ({ arcs = "abcdef" }) => {
    return <g key="beamMount">
        <CompositeBeamArc arcs={arcs}/>

        <g id="MissileLauncher">
            <g transform="matrix(28.6243,0,0,28.6243,-402.358,-6848.26)">
                <circle cx="23" cy="248.19" r="6" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px" }}/>
            </g>
            <g transform="matrix(28.6243,0,0,28.6243,-402.358,-6848.26)">
                <path d="M23,244.19L20,251.19L23,250.19L26,251.19L23,244.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.29px","strokeLinejoin":"round","strokeMiterlimit":"2"}}/>
            </g>
        </g>
    </g>
}

export const PlasmaCannon = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="plasmaCannon">
        <CenterNumber number={beamClass}/>
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle/>
        <g transform="matrix(0.842842,4.7581e-17,0,0.842842,256,256)">
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-170.554C-94.131,-170.554 -170.554,-94.131 -170.554,0C-170.554,94.131 -94.131,170.554 0,170.554C94.131,170.554 170.554,94.131 170.554,0C170.554,-94.131 94.131,-170.554 0,-170.554Z"/>
        </g>
    </g>
}

export const BoardingTorpedo = ({ arcs = "abcdef" }) => {
    return <g key="boardingTorpedo">
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle/>
        <g transform="matrix(1,0,0,1,-38.4522,134.345)">
            <g id="BT">
                <path d="M241.652,48.322L248.052,48.322C255.252,48.322 258.186,51.522 258.186,62.455L258.186,91.255C258.186,102.189 255.252,105.389 248.052,105.389L241.652,105.389L241.652,48.322ZM213.386,216.589L253.119,216.589C276.852,216.589 287.252,199.255 287.252,176.589L287.252,146.455C287.252,125.122 278.186,120.322 260.319,116.855L260.319,116.322C276.586,112.589 286.186,107.522 286.452,90.989L286.452,60.855C286.452,40.589 276.052,26.722 253.386,26.722L213.386,26.722L213.386,216.589ZM241.652,128.055L247.252,128.055C255.252,128.055 258.986,131.255 258.986,142.989L258.986,178.989C258.986,190.722 255.252,193.922 247.252,193.922L241.652,193.922L241.652,128.055Z" style={{"fillRule":"nonzero"}}/>
                <path d="M297.919,26.722L297.919,51.522L322.719,51.522L322.719,216.589L350.986,216.589L350.986,51.522L375.519,51.522L375.519,26.722L297.919,26.722Z" style={{"fillRule":"nonzero"}}/>
            </g>
        </g>
    </g>
}


export const PhaserMount = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="phaserMount">
        <rect id="PhaserBox" x="133.123" y="133.123" width="245.753" height="245.753"/>
        <CenterNumber number={beamClass} color={"white"}/>
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle/>
    </g>
}

export const GraserMount = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="graserMount">
        <CenterCircle filled={true}/>
        <CenterNumber number={beamClass} color={"white"}/>
        <CompositeBeamArc arcs={arcs}/>
    </g>
}

export const HeavyGraserMount = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="heavygraserMount">
        <g id="HeavyGraserTriangle" transform="matrix(-0.779796,9.54975e-17,-1.03181e-16,-0.842536,476.177,487.978)">
            <path d="M282.352,18.002L504.394,429.017L60.31,429.017L282.352,18.002Z"/>
            <path d="M282.352,18.002L504.394,429.017L60.31,429.017L282.352,18.002ZM282.352,73.293L461.166,404.29C376.231,404.29 188.473,404.29 103.538,404.29C103.538,404.29 282.352,73.293 282.352,73.293Z"/>
        </g>
        <CompositeBeamArc arcs={arcs}/>
        <CenterNumber number={beamClass} color={"white"}/>
        
    </g>
}

export const HIGraserMount = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="higraserMount">
        <g id="HIGraserTriangle" transform="matrix(-0.779796,9.54975e-17,-1.03181e-16,-0.842536,476.177,487.978)">
            <path d="M282.352,18.002L504.394,429.017L60.31,429.017L282.352,18.002Z" style={{ fill: "rgb(128,128,128)" }}/>
            <path d="M282.352,18.002L504.394,429.017L60.31,429.017L282.352,18.002ZM282.352,73.293L461.166,404.29C376.231,404.29 188.473,404.29 103.538,404.29C103.538,404.29 282.352,73.293 282.352,73.293Z" style={{ fill: "rgb(128,128,128)" }}/>
        </g>
        <CompositeBeamArc arcs={arcs}/>
        <CenterNumber number={beamClass} color={"white"}/>
    </g>
}

export const PulseTorpedoMount = ({ type = "normal", arcs = "abcdef" }) => {
    let typeTransforms = {
        "normal" : "matrix(0.848062,-0.848062,0.848062,0.848062,-178.208,256)",
        "long" : "matrix(0.597767,-1.11022e-16,5.53349e-17,1.19934,102.972,-51.0313)",
        "short" : "matrix(1.19934,-6.4426e-17,1.11022e-16,0.695974,-51.0313,77.8305)"
    }
    return <g key="beamMount">
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle filled={true}/>
        <g id="Torpedo-Symbol" style={{fillRule: "evenodd", clipRule:"evenodd", strokeLinejoin:"round", strokeMiterlimit:2}}>
            <g transform="matrix(0.991728,0,0,0.991728,2.11767,2.11767)">
                <circle cx="256" cy="256" r="50.417" style={{ fill: "white" }}/>
                <path d="M256,205.583C283.826,205.583 306.417,228.174 306.417,256C306.417,283.826 283.826,306.417 256,306.417C228.174,306.417 205.583,283.826 205.583,256C205.583,228.174 228.174,205.583 256,205.583ZM256,211.885C280.348,211.885 300.115,231.652 300.115,256C300.115,280.348 280.348,300.115 256,300.115C231.652,300.115 211.885,280.348 211.885,256C211.885,231.652 231.652,211.885 256,211.885Z" style={{ fill: "white" }}/>
            </g>
            <g transform={typeTransforms[type]}>
                <path d="M256,144.403L269.51,241.873L362.725,256L269.51,270.127L256,367.597L242.49,270.127L149.275,256L242.49,241.873L256,144.403Z" style={{ fill: "white" }}/>
                <path d="M256,144.403L269.51,241.873L362.725,256L269.51,270.127L256,367.597L242.49,270.127L149.275,256L242.49,241.873L256,144.403ZM256,182.359L264.348,242.589C264.665,244.874 266.448,246.68 268.729,247.025L327.947,256C327.947,256 268.729,264.975 268.729,264.975C266.448,265.32 264.665,267.126 264.348,269.411L256,329.641C256,329.641 247.652,269.411 247.652,269.411C247.335,267.126 245.552,265.32 243.271,264.975L184.053,256C184.053,256 243.271,247.025 243.271,247.025C245.552,246.68 247.335,244.874 247.652,242.589L256,182.359Z" style={{ fill: "white" }}/>
            </g>
        </g>
    </g>
}
export const AreaDefenseSystem = ({ arcs = "abcdef" }) => {
    return <g> 
            <CompositeBeamArc arcs={arcs}/>
            <PointDefenseSystem/>
        </g>
}
export const PointDefenseSystem = () => {
    return (<g id="PDS" transform="matrix(0.683594,0,0,0.683594,81,81)" style={{"fillRule":"evenodd","clipRule":"evenodd","strokeLineJoin":"round","strokeMiterLimit":"2"}}>
    <path d="M256,0C397.29,0 512,114.71 512,256C512,397.29 397.29,512 256,512C114.71,512 0,397.29 0,256C0,114.71 114.71,0 256,0ZM256,30.476C380.47,30.476 481.524,131.53 481.524,256C481.524,380.47 380.47,481.524 256,481.524C131.53,481.524 30.476,380.47 30.476,256C30.476,131.53 131.53,30.476 256,30.476Z"/>
    <g transform="matrix(1.00852,0,0,0.994929,-2.18169,1.29813)">
        <path d="M435.49,74.058C387.886,25.804 323.322,-1.305 256,-1.305C188.678,-1.305 124.114,25.804 76.51,74.058L256,256L435.49,74.058Z"/>
        <path d="M435.49,74.058C387.886,25.804 323.322,-1.305 256,-1.305C188.678,-1.305 124.114,25.804 76.51,74.058L256,256L435.49,74.058ZM391.304,75.528L256,212.68C256,212.68 120.696,75.528 120.696,75.528C159.384,45.723 206.858,29.327 256,29.327C305.142,29.327 352.616,45.723 391.304,75.528L391.304,75.528Z"/>
    </g>
    <g transform="matrix(-1.00852,1.23508e-16,-1.21844e-16,-0.994929,514.182,510.702)">
        <path d="M435.49,74.058C387.886,25.804 323.322,-1.305 256,-1.305C188.678,-1.305 124.114,25.804 76.51,74.058L256,256L435.49,74.058Z"/>
        <path d="M435.49,74.058C387.886,25.804 323.322,-1.305 256,-1.305C188.678,-1.305 124.114,25.804 76.51,74.058L256,256L435.49,74.058ZM391.304,75.528L256,212.68C256,212.68 120.696,75.528 120.696,75.528C159.384,45.723 206.858,29.327 256,29.327C305.142,29.327 352.616,45.723 391.304,75.528L391.304,75.528Z"/>
    </g>
</g>)
}

export const FusionArray = ({ arcs = "abcdef" }) => {
    return (<g>
        <CompositeBeamArc arcs={arcs}/>
        <g id="FusionArray">
            <path d="M410.612,209.081L410.612,302.919C410.612,328.815 389.588,349.839 363.692,349.839L148.308,349.839C122.412,349.839 101.388,328.815 101.388,302.919L101.388,209.081C101.388,183.185 122.412,162.161 148.308,162.161L363.692,162.161C389.588,162.161 410.612,183.185 410.612,209.081ZM398.112,209.081C398.112,190.084 382.689,174.661 363.692,174.661L148.308,174.661C129.311,174.661 113.888,190.084 113.888,209.081L113.888,302.919C113.888,321.916 129.311,337.339 148.308,337.339L363.692,337.339C382.689,337.339 398.112,321.916 398.112,302.919L398.112,209.081Z"/>
            <path d="M375.69,220.077L375.69,291.923C375.69,311.749 359.594,327.846 339.767,327.846L172.233,327.846C152.406,327.846 136.31,311.749 136.31,291.923L136.31,220.077C136.31,200.251 152.406,184.154 172.233,184.154L339.767,184.154C359.594,184.154 375.69,200.251 375.69,220.077ZM363.19,220.077C363.19,207.15 352.695,196.654 339.767,196.654L172.233,196.654C159.305,196.654 148.81,207.15 148.81,220.077L148.81,291.923C148.81,304.85 159.305,315.346 172.233,315.346L339.767,315.346C352.695,315.346 363.19,304.85 363.19,291.923L363.19,220.077Z"/>
        </g>    
    </g>)
}

export const GatlingBattery = ({ arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <g id="Gatling-Battery">
            <path d="M120.927,280.984L380.183,280.984" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            <g transform="matrix(1,0,0,1,50,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,100,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,-50,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,-100,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <path id="_2-3-Fill" d="M89.499,309.985C120.768,309.917 422.738,309.293 422.501,309.985C396.775,385.069 326.131,430.944 256,431C188.993,431.053 119.171,390.436 89.499,309.985"/>
        </g>
        <g id="Center-Circle" transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C-94.716,-171.613 -171.613,-94.716 -171.613,0C-171.613,94.716 -94.716,171.613 0,171.613C94.716,171.613 171.613,94.716 171.613,0C171.613,-94.716 94.716,-171.613 0,-171.613Z"/>
        </g>
    </g>
}

export const EMPCannon = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <CenterNumeral number={beamClass}/>
    </g>
}

export const NeedleBeam = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle filled={true}/>
        <CenterNumeral number={beamClass} color={"white"}/>
    </g>
}

export const PlasmaBoltLauncher = ({ emitterClass = 1 , arcs = "abcdef"}) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <g transform="matrix(0.951287,0,0,0.931221,12.4705,-5.65803)">
            <g id="PBLStar">
                <path d="M256,35.662L327.716,107.847L429.468,107.515L429.137,209.268L501.321,280.984L429.137,352.7L429.468,454.452L327.716,454.121L256,526.305L184.284,454.121L82.532,454.452L82.863,352.7L10.679,280.984L82.863,209.268L82.532,107.515L184.284,107.847L256,35.662Z" style={{"fill":"white"}}/>
                <path d="M256,35.662L327.716,107.847L429.468,107.515L429.137,209.268L501.321,280.984L429.137,352.7L429.468,454.452L327.716,454.121L256,526.305L184.284,454.121L82.532,454.452L82.863,352.7L10.679,280.984L82.863,209.268L82.532,107.515L184.284,107.847L256,35.662ZM256,45.085L188.895,112.627C187.661,113.87 185.996,114.564 184.263,114.558L89.124,114.249L89.433,209.246C89.439,211.066 88.721,212.81 87.443,214.079L20.102,280.984L87.443,347.888C88.721,349.157 89.439,350.902 89.433,352.722L89.124,447.719L184.263,447.41C185.996,447.404 187.661,448.098 188.895,449.34C188.895,449.34 256,516.883 256,516.883L323.105,449.34C324.339,448.098 326.004,447.404 327.737,447.41L422.876,447.719L422.567,352.722C422.561,350.902 423.279,349.157 424.557,347.888L491.898,280.984L424.557,214.079C423.279,212.81 422.561,211.066 422.567,209.246L422.876,114.249L327.737,114.558C326.004,114.564 324.339,113.87 323.105,112.627L256,45.085Z"/>
            </g>
        </g>
        <CenterNumber number={emitterClass} color={"black"}/>
    </g>
}

export const Grapeshot = () => {
    return <g transform="matrix(39.3092,0,0,39.3092,-8195.48,-22236.3)">
        <g id="Grapeshot">
            <path d="M215,572.19L221,572.19L218,577.19L215,572.19Z" style={{"fillRule":"nonzero"}}/>
            <path d="M215,572.19L209,572.19L212,577.19L215,572.19Z" style={{"fillRule":"nonzero"}}/>
            <path d="M218,567.19L212,567.19L215,572.19L218,567.19Z" style={{"fillRule":"nonzero"}}/>
            <circle cx="215" cy="572.19" r="3" style={{"fill":"none","stroke":"black","strokeWidth":"1px"}}/>
            <rect x="207" y="564.19" width="16" height="16" style={{"fill":"none"}}/>
        </g>
    </g>
}
export const GravityGun = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <g transform="matrix(0.913079,5.15461e-17,0,0.913079,256,256)">
            <circle id="GravGun" cx="0" cy="0" r="160"/>
        </g>
        <CenterNumber number={beamClass} color={"white"}/>
    </g>
}

export const KineticGun = ({ gunClass = 1, arcs = "abcdef" }) => {
    return <g>
        <CompositeKineticArc arcs={arcs}/>
        <CenterNumber number={gunClass}/>
    </g>
}

export const MesonProjector = ({ arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <g id="MesonProjector">
            <g transform="matrix(1,0,0,1,-3.49171e-05,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,71.1237,0)">
                <path d="M148.23,280.984L221.522,280.984" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <path id="_2-3-Fill" d="M89.499,309.985C120.768,309.917 422.738,309.293 422.501,309.985C396.775,385.069 326.131,430.944 256,431C188.993,431.053 119.171,390.436 89.499,309.985"/>
        </g>
        <g id="Center-Circle" transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C-94.716,-171.613 -171.613,-94.716 -171.613,0C-171.613,94.716 -94.716,171.613 0,171.613C94.716,171.613 171.613,94.716 171.613,0C171.613,-94.716 94.716,-171.613 0,-171.613Z"/>
        </g>
    </g>
}

export const TwinParticleArray = ({ arcs = "abcdef" }) => {
    return <g>
        <CompositeBeamArc arcs={arcs}/>
        <g id="TwinParticleArray">
            <g transform="matrix(1,0,0,1,-80,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,-8.87625,0)">
                <path d="M148.23,280.984L221.522,280.984" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,79.9999,0)">
                <path d="M256,309.985L256,175.79" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <g transform="matrix(1,0,0,1,151.124,0)">
                <path d="M148.23,280.984L221.522,280.984" style={{"fill":"none","stroke":"black","strokeWidth":"25px"}}/>
            </g>
            <path id="_2-3-Fill" d="M89.499,309.985C120.768,309.917 422.738,309.293 422.501,309.985C396.775,385.069 326.131,430.944 256,431C188.993,431.053 119.171,390.436 89.499,309.985"/>
        </g>
        <g id="Center-Circle" transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C-94.716,-171.613 -171.613,-94.716 -171.613,0C-171.613,94.716 -94.716,171.613 0,171.613C94.716,171.613 171.613,94.716 171.613,0C171.613,-94.716 94.716,-171.613 0,-171.613Z"/>
        </g>
    </g>
}

export const MultipleKineticPenetrator = () => {
    return <g>
        <g id="PulserCenter" transform="matrix(0.759922,-0.438741,0.469233,0.812735,-58.6638,160.258)">
            <path d="M256,96.165L404.041,176.082L404.041,335.918L256,415.835L107.959,335.918L107.959,176.082L256,96.165ZM256,103.855L115.081,179.927L115.081,332.073L256,408.145L396.919,332.073L396.919,179.927L256,103.855Z"/>
        </g>
        <g transform="matrix(-1.78118,-2.18132e-16,1.50615e-16,-1.22986,711.983,289.144)">
            <path d="M256,26.949L298.11,213.924L213.89,213.924L256,26.949Z"/>
            <path d="M256,26.949L298.11,213.924L213.89,213.924L256,26.949ZM256,59.725L223.56,203.76L288.44,203.76L256,59.725Z"/>
        </g>
    </g>
}

export const Pulser = ({ arcs = "abcdef" }) => {
    return <g>
        <CompositePulserArc arcs={arcs}/>
        <g transform="matrix(0.759922,-0.438741,0.469233,0.812735,-58.6638,160.258)">
            <path id="PulserCenter" d="M256,96.165L404.041,176.082L404.041,335.918L256,415.835L107.959,335.918L107.959,176.082L256,96.165ZM256,103.855L115.081,179.927L115.081,332.073L256,408.145L396.919,332.073L396.919,179.927L256,103.855Z"/>
        </g>
    </g>
}

export const Scattergun = () => {
    return <g transform="matrix(39.3092,0,0,39.3092,-8195.48,-22236.3)">
        <g id="ScatterGun">
            <path d="M215,572.19L221,572.19L218,577.19L215,572.19Z" style={{"fillRule":"nonzero"}}/>
            <path d="M215,572.19L209,572.19L212,577.19L215,572.19Z" style={{"fillRule":"nonzero"}}/>
            <path d="M218,567.19L212,567.19L215,572.19L218,567.19Z" style={{"fillRule":"nonzero"}}/>
            <g transform="matrix(0.0254393,0,0,0.0254393,208.488,565.678)">
                <path d="M138.072,59.454L373.928,59.454L491.855,256L373.928,452.546L138.072,452.546L21.273,256L138.072,59.454ZM141.629,65.704L28.543,256C28.543,256 141.629,446.296 141.629,446.296L370.389,446.296L484.567,256C469.433,230.778 370.389,65.704 370.389,65.704L141.629,65.704Z"/>
            </g>
        </g>
    </g>
}

export const Transporter = ({ beamClass = 1, arcs = "abcdef" }) => {
    return <g key="transporterMount">
        <path id="TransporterBox" d="M378.877,133.123L378.877,378.877L133.123,378.877L133.123,133.123L378.877,133.123ZM370.543,141.457C370.543,141.457 141.457,141.457 141.457,141.457L141.457,370.543C141.457,370.543 370.543,370.543 370.543,370.543L370.543,141.457Z"/>
        <CenterNumber number={beamClass} color={"black"}/>
        <CompositeBeamArc arcs={arcs}/>
        <CenterCircle/>
    </g>
}

export const SpinalBeam = ({ range = "medium"}) => {
    return (<g id="SpinalBeam">
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="319.19" r="2" style={{"fill":"white","stroke":"black","strokeWidth":"0.25px"}}/>
            </g>
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="315.19" r="3" style={{"fill":"white","stroke":"black","strokeWidth":"0.25px"}}/>
            </g>
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="310.19" r="5" style={{"fill":"white","stroke":"black","strokeWidth":"0.25px"}}/>
            </g>
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <path d="M247,330.19L257,303.19L237,303.19L247,330.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px","strokeLineJoin":"miter","strokeMiterLimit":"4"}}/>
            </g>
            {(range.toLocaleLowerCase() == "short" ? <g id="S" transform="matrix(0.843873,0,0,0.843873,15.5469,-57.3725)">
                <path d="M279.291,257.89L260.867,257.89L260.867,286.395C260.867,302.559 273.555,307.252 284.853,307.252C296.324,307.252 309.013,302.559 309.013,286.395L309.013,264.494C309.013,251.98 279.291,225.213 279.291,216.348L279.291,201.053C279.291,194.796 281.203,193.405 285.027,193.405C288.677,193.405 290.589,194.796 290.589,201.053L290.589,225.56L309.013,225.56L309.013,199.489C309.013,183.324 296.324,178.631 284.853,178.631C273.555,178.631 260.867,183.324 260.867,199.489L260.867,217.217C260.867,237.38 290.589,254.413 290.589,271.795L290.589,284.83C290.589,291.088 288.677,292.478 285.027,292.478C281.203,292.478 279.291,291.088 279.291,284.83L279.291,257.89Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
            {(range.toLocaleLowerCase() == "long" ? <g id="L" transform="matrix(0.843873,0,0,0.843873,17.747,-57.3725)">
                <path d="M303.277,304.819L303.277,288.654L279.812,288.654L279.812,181.065L261.388,181.065L261.388,304.819L303.277,304.819Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
            {(range.toLocaleLowerCase() == "medium" ? <g id="M" transform="matrix(0.843873,0,0,0.843873,4.69291,-57.3725)">
                <path d="M306.232,304.819L312.663,251.459C313.358,244.854 316.139,213.046 317.182,203.834L317.877,203.834C317.356,219.477 317.182,233.208 317.182,247.808L317.182,304.819L333.346,304.819L333.346,181.065L306.058,181.065L301.017,232.339C299.975,242.246 299.801,257.89 299.279,270.752L298.41,270.752C298.236,255.109 296.498,239.813 295.455,232.165L289.546,181.065L262.257,181.065L262.257,304.819L278.422,304.819L278.422,227.994C278.422,220.52 277.9,211.308 277.727,203.834L278.422,203.834C279.638,214.437 281.377,232.513 282.767,245.201L289.372,304.819L306.232,304.819Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
        </g>)
}

export const SpinalPlasma = ({ range = "medium" }) => {
    return (<g id="SpinalPlasma">
        <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
            <circle cx="247" cy="319.19" r="2"/>
        </g>
        <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
            <circle cx="247" cy="315.19" r="3"/>
        </g>
        <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
            <circle cx="247" cy="310.19" r="5"/>
        </g>
        <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
            <path d="M247,330.19L257,303.19L237,303.19L247,330.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px"}}/>
        </g>
        {(range.toLocaleLowerCase() == "short" ? <g id="S" transform="matrix(0.843873,0,0,0.843873,15.5469,-57.3725)">
            <path d="M279.291,257.89L260.867,257.89L260.867,286.395C260.867,302.559 273.555,307.252 284.853,307.252C296.324,307.252 309.013,302.559 309.013,286.395L309.013,264.494C309.013,251.98 279.291,225.213 279.291,216.348L279.291,201.053C279.291,194.796 281.203,193.405 285.027,193.405C288.677,193.405 290.589,194.796 290.589,201.053L290.589,225.56L309.013,225.56L309.013,199.489C309.013,183.324 296.324,178.631 284.853,178.631C273.555,178.631 260.867,183.324 260.867,199.489L260.867,217.217C260.867,237.38 290.589,254.413 290.589,271.795L290.589,284.83C290.589,291.088 288.677,292.478 285.027,292.478C281.203,292.478 279.291,291.088 279.291,284.83L279.291,257.89Z" style={{"fill":"white","fillRule":"nonzero"}}/>
        </g> : "" )}
        {(range.toLocaleLowerCase() == "long" ? <g id="L" transform="matrix(0.843873,0,0,0.843873,17.747,-57.3725)">
            <path d="M303.277,304.819L303.277,288.654L279.812,288.654L279.812,181.065L261.388,181.065L261.388,304.819L303.277,304.819Z" style={{"fill":"white","fillRule":"nonzero"}}/>
        </g> : "" )}
        {(range.toLocaleLowerCase() == "medium" ? <g id="M" transform="matrix(0.843873,0,0,0.843873,4.69291,-57.3725)">
            <path d="M306.232,304.819L312.663,251.459C313.358,244.854 316.139,213.046 317.182,203.834L317.877,203.834C317.356,219.477 317.182,233.208 317.182,247.808L317.182,304.819L333.346,304.819L333.346,181.065L306.058,181.065L301.017,232.339C299.975,242.246 299.801,257.89 299.279,270.752L298.41,270.752C298.236,255.109 296.498,239.813 295.455,232.165L289.546,181.065L262.257,181.065L262.257,304.819L278.422,304.819L278.422,227.994C278.422,220.52 277.9,211.308 277.727,203.834L278.422,203.834C279.638,214.437 281.377,232.513 282.767,245.201L289.372,304.819L306.232,304.819Z" style={{"fill":"white","fillRule":"nonzero"}}/>
        </g> : "" )}
    </g>)
}

export const SpinalPSP = ({range = "m" }) => {
    return (<g transform="matrix(1.68694,0,0,1.68694,-175.858,-77.4279)">
        <g id="PSP">
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="319.19" r="2" style={{"fill":"white","stroke":"black","strokeWidth":"0.15px"}}/>
            </g>
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="315.19" r="3" style={{"fill":"white","stroke":"black","strokeWidth":"0.15px"}}/>
            </g>
            <g transform="matrix(16.6708,0,0,16.6708,-3861.7,-5023.49)">
                <circle cx="247" cy="310.19" r="5" style={{"fill":"white","stroke":"black","strokeWidth":"0.15px"}}/>
            </g>
            {(range.toLocaleLowerCase() == "short" ? <g id="S" transform="matrix(1.01794,0,0,1.01794,-34.0511,-99.6602)">
                <path d="M279.291,257.89L260.867,257.89L260.867,286.395C260.867,302.559 273.555,307.252 284.853,307.252C296.324,307.252 309.013,302.559 309.013,286.395L309.013,264.494C309.013,251.98 279.291,225.213 279.291,216.348L279.291,201.053C279.291,194.796 281.203,193.405 285.027,193.405C288.677,193.405 290.589,194.796 290.589,201.053L290.589,225.56L309.013,225.56L309.013,199.489C309.013,183.324 296.324,178.631 284.853,178.631C273.555,178.631 260.867,183.324 260.867,199.489L260.867,217.217C260.867,237.38 290.589,254.413 290.589,271.795L290.589,284.83C290.589,291.088 288.677,292.478 285.027,292.478C281.203,292.478 279.291,291.088 279.291,284.83L279.291,257.89Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
            {(range.toLocaleLowerCase() == "long" ? <g id="L" transform="matrix(1.01794,0,0,1.01794,-31.3971,-99.6602)">
                <path d="M303.277,304.819L303.277,288.654L279.812,288.654L279.812,181.065L261.388,181.065L261.388,304.819L303.277,304.819Z" style={{"fillRule":"nonzero"}}/>
            </g> : "")}
            {(range.toLocaleLowerCase() == "medium" ? <g id="M" transform="matrix(1.01794,0,0,1.01794,-47.1439,-99.6602)">
                <path d="M306.232,304.819L312.663,251.459C313.358,244.854 316.139,213.046 317.182,203.834L317.877,203.834C317.356,219.477 317.182,233.208 317.182,247.808L317.182,304.819L333.346,304.819L333.346,181.065L306.058,181.065L301.017,232.339C299.975,242.246 299.801,257.89 299.279,270.752L298.41,270.752C298.236,255.109 296.498,239.813 295.455,232.165L289.546,181.065L262.257,181.065L262.257,304.819L278.422,304.819L278.422,227.994C278.422,220.52 277.9,211.308 277.727,203.834L278.422,203.834C279.638,214.437 281.377,232.513 282.767,245.201L289.372,304.819L306.232,304.819Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
        </g>
    </g>)
}

export const TurretedSubmunition = ({ arcs = "abcdef" }) => {
    return <g id="TSM">
        <CompositeBeamArc arcs={arcs}/>
        <g id="Center-Circle" transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C-94.716,-171.613 -171.613,-94.716 -171.613,0C-171.613,94.716 -94.716,171.613 0,171.613C94.716,171.613 171.613,94.716 171.613,0C171.613,-94.716 94.716,-171.613 0,-171.613Z"/>
        </g>
        <g id="TurretedSubmunition">
            <g transform="matrix(1.21357,0,0,1.21357,-54.6744,-77.8938)">
                <path d="M256,226.698C299.116,226.698 334.121,261.703 334.121,304.819C334.121,347.935 299.116,382.94 256,382.94C212.884,382.94 177.879,347.935 177.879,304.819C177.879,261.703 212.884,226.698 256,226.698ZM256,230.131C214.779,230.131 181.313,263.598 181.313,304.819C181.313,346.04 214.779,379.506 256,379.506C297.221,379.506 330.687,346.04 330.687,304.819C330.687,263.598 297.221,230.131 256,230.131Z"/>
            </g>
            <g transform="matrix(-1.21357,-1.4862e-16,1.4862e-16,-1.21357,572.243,453.584)">
                <path d="M260.589,151.876L319.96,270.619L201.217,270.619L260.589,151.876Z"/>
                <path d="M260.589,151.876L319.96,270.619L201.217,270.619L260.589,151.876ZM260.589,159.553C260.589,159.553 206.773,267.185 206.773,267.185L314.405,267.185L260.589,159.553Z"/>
            </g>
        </g>
    </g>
}

// System Components

export const FireControl = ({advanced = false}) => {
    if(advanced) {
        return (<g id="AdvFireControl">
                <g transform="matrix(56.25,0,0,56.25,-587.75,-34244.7)">
                    <circle cx="15" cy="612.19" r="2"/>
                </g>
                <g transform="matrix(56.25,0,0,56.25,-587.75,-34114.7)">
                    <circle cx="15" cy="612.19" r="2"/>
                </g>
                <g transform="matrix(56.25,0,0,56.25,-587.75,-34179.7)">
                    <rect x="11" y="608.19" width="8" height="8" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px"}}/>
                </g>
            </g>)
    }
    return (<g id="FireControl">
        <g transform="matrix(56.25,0,0,56.25,-587.75,-34179.7)">
            <circle cx="15" cy="612.19" r="2"/>
        </g>
        <g transform="matrix(56.25,0,0,56.25,-587.75,-34179.7)">
            <rect x="11" y="608.19" width="8" height="8" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px"}}/>
        </g>
    </g>)
}

export const AreaDefenseFireControl = ({advanced = false}) => {
    if(advanced) {
        return (<g transform="matrix(1,0,0,1,0,-110)">
                    <g id="Adv-Area-Defense-FireControl">
                        <g transform="matrix(0.493429,0,0,0.4911,129.682,130.278)">
                            <path d="M458.663,52.376L458.663,459.624L53.337,459.624L53.337,52.376L458.663,52.376ZM433.331,77.829L78.669,77.829L78.669,434.171L433.331,434.171L433.331,77.829Z"/>
                        </g>
                        <g transform="matrix(0.571429,0,0,0.571429,109.714,109.714)">
                            <path d="M81,81L256,256L431,81L81,81Z"/>
                            <path d="M81,81L431,81L256,256L81,81ZM186.622,124.75L256,194.128C256,194.128 325.378,124.75 325.378,124.75L186.622,124.75Z"/>
                        </g>
                        <g transform="matrix(0.571429,0,0,0.571429,109.714,109.714)">
                            <path d="M256,256L431,431L81,431L256,256Z"/>
                        <path d="M256,256L431,431L81,431L256,256ZM256,317.872C256,317.872 186.622,387.25 186.622,387.25L325.378,387.25L256,317.872Z"/>
                    </g>
                    <g transform="matrix(0.493429,0,0,0.4911,129.682,350.278)">
                        <path d="M458.663,52.376L458.663,459.624L53.337,459.624L53.337,52.376L458.663,52.376ZM433.331,77.829L78.669,77.829L78.669,434.171L433.331,434.171L433.331,77.829Z"/>
                    </g>
                    <g transform="matrix(0.571429,0,0,0.571429,109.714,329.714)">
                        <path d="M81,81L256,256L431,81L81,81Z"/>
                        <path d="M81,81L431,81L256,256L81,81ZM186.622,124.75L256,194.128L325.378,124.75L186.622,124.75Z"/>
                    </g>
                    <g transform="matrix(0.571429,0,0,0.571429,109.714,329.714)">
                        <path d="M256,256L431,431L81,431L256,256Z"/>
                        <path d="M256,256L431,431L81,431L256,256ZM256,317.872C256,317.872 186.622,387.25 186.622,387.25L325.378,387.25L256,317.872Z"/>
                    </g>
                    <g transform="matrix(1,0,0,1,0,110)">
                        <path d="M405.747,9.808L405.747,502.192L106.253,502.192L106.253,9.808L405.747,9.808ZM395.33,20.225L116.67,20.225L116.67,491.775C116.67,491.775 395.33,491.775 395.33,491.775L395.33,20.225Z"/>
                    </g>
                </g>
            </g>)
    }
    return (<g id="Area-Defence-Fire-Control">
            <g transform="matrix(0.8635,0,0,0.859425,34.9439,35.9872)">
                <path d="M458.663,52.376L458.663,459.624L53.337,459.624L53.337,52.376L458.663,52.376ZM429.712,81.465C429.712,81.465 170.463,81.465 82.288,81.465C82.288,81.465 82.288,430.535 82.288,430.535C82.288,430.535 429.712,430.535 429.712,430.535L429.712,81.465Z"/>
            </g>
            <path d="M81,81L256,256L431,81L81,81Z"/>
            <path d="M81,81L431,81L256,256L81,81ZM141.355,106L256,220.645L370.645,106L141.355,106Z"/>
            <path d="M256,256L431,431L81,431L256,256Z"/>
            <path d="M256,256L431,431L81,431L256,256ZM256,291.355L141.355,406L370.645,406L256,291.355Z"/>
        </g>)
}

export const Screen = ({advanced = false}) => {
    if(advanced) {
        return (<g id="Advanced-Shield">
            <g transform="matrix(45,0,0,45.0109,-9419,-26939.1)">
                <g id="AdvancedScreen">
                    <g transform="matrix(0.7071,-0.7071,0.7071,0.7071,-365.669,329.577)">
                        <rect x="212.879" y="604.069" width="4.243" height="4.243"/>
                    </g>
                    <path d="M211,605.19L215,601.19L219,605.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                    <path d="M210,604.19L215,599.19L220,604.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                </g>
            </g>
        </g>)
    }
    return (<g transform="matrix(49.3769,0,0,49.3769,-4039.81,-29972)">
        <g id="Standard-Screen">
            <circle cx="87" cy="614.19" r="2"/>
            <path d="M84.172,611.362C85.734,609.8 88.267,609.8 89.829,611.362" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.75px"}}/>
            <path d="M82.758,609.948C85.101,607.604 88.901,607.605 91.243,609.948" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.75px"}}/>
        </g>
    </g>)
}

export const Sensors = ({ advanced = false }) => {
    return (
        <g transform="matrix(26.4351,0,0,26.4351,-3735.71,-6511.4)">
            <g id="AdvancedSensors">
                <g transform="matrix(1,0,0,1,0,-351.19)">
                    <path d="M157,606.19C157,609.503 154.313,612.19 151,612.19C147.687,612.19 145,609.503 145,606.19L157,606.19Z" style={{"fill":(advanced ? "black" : "none"), "fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                </g>
                <g transform="matrix(1,0,0,1,0,-351.19)">
                    <path d="M149,606.19L151,598.19L153,606.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                </g>
                <g transform="matrix(1,0,0,1,0,-351.19)">
                    <path d="M149,612.001L147,616.19L155,616.19L153,612.001" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                </g>
            </g>
        </g>
    )
}

export const FTLDrive = ({ advanced = false, tug = false, massRating = 0 }) => {
    if(advanced) {
        return <g id="AdvancedFTL">
            <g transform="matrix(29.1667,0,0,29.1667,-1348.17,-7210.67)">
                <g>
                    <g transform="matrix(1,0,0,1,0,-476.19)">
                        <path d="M49,733.402C51,737.402 53,737.402 55,731.402C57,725.402 59,729.402 61,733.402" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                    </g>
                    <g transform="matrix(1,0,0,1,0,-476.19)">
                        <path d="M49,728.19L49,736.19L55,739.19L61,736.19L61,728.19L55,725.19L49,728.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                    </g>
                </g>
            </g>
        </g>
    }
    return (
        <g id="StandardFTL">
            <g transform="matrix(29.1667,0,0,29.1667,-414.833,-21099.5)">
                <rect x="17" y="726.19" width="12" height="12" style={{"fill":"none", "stroke":"black", "strokeWidth":"0.5px" }}/>
            </g>
            <g transform="matrix(29.1667,0,0,29.1667,-414.833,-21099.5)">
                <path d="M17,733.402C19,737.402 21,737.402 23,731.402C25,725.402 27,729.402 29,733.402" style={{"fill":"none", "stroke":"black", "strokeWidth":"0.5px" }}/>
            </g>
        </g>)
}

export const SublightDrive = ({ advanced = false, tug = false, thrust = 0 }) => {
    if(advanced) {
        return <g id="AdvancedSublight">
            <CenterNumber number={thrust}/>
            <g transform="matrix(29.1667,0,0,29.1667,-1348.17,-7210.67)">
                <g>
                    <g transform="matrix(1,0,0,1,0,-476.19)">
                        <path d="M49,728.19L49,736.19L55,739.19L61,736.19L61,728.19L55,725.19L49,728.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                    </g>
                </g>
            </g>
        </g>
    }
    return (
        <g  id="StandardSublight">
            <CenterNumber number={thrust}/>
            <g transform="matrix(17.5,0,0,17.5,-146.5,-11989.8)">
                <path d="M13,707.19L33,707.19L33,697.19L23,691.19L13,697.19L13,707.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
        </g>)
}

export const SuicideCharge = () => {
    return (<g id="Suicide-Charge">
        <g id="Suicide-Charge1" transform="matrix(0.983316,5.55112e-17,0,0.983316,256,256)">
            <circle cx="0" cy="0" r="177.969"/>
            <path d="M0,-177.969C98.224,-177.969 177.969,-98.224 177.969,0C177.969,98.224 98.224,177.969 0,177.969C-98.224,177.969 -177.969,98.224 -177.969,0C-177.969,-98.224 -98.224,-177.969 0,-177.969ZM0,-171.613C-94.716,-171.613 -171.613,-94.716 -171.613,0C-171.613,94.716 -94.716,171.613 0,171.613C94.716,171.613 171.613,94.716 171.613,0C171.613,-94.716 94.716,-171.613 0,-171.613Z"/>
        </g>
        <g transform="matrix(-0.45971,-0.79624,0.689837,-0.398278,238.337,633.244)">
            <path d="M345.731,256L435.461,435.461L256,435.461L345.731,256Z" style={{"fill":"white"}}/>
        </g>
        <g transform="matrix(-0.45971,0.79624,-0.689837,-0.398278,591.534,82.6745)">
            <path d="M345.731,256L435.461,435.461L256,435.461L345.731,256Z" style={{"fill":"white"}}/>
        </g>
        <g transform="matrix(0.919419,0,0,0.796556,-61.8713,52.0818)">
            <path d="M345.731,256L435.461,435.461L256,435.461L345.731,256Z" style={{"fill":"white"}}/>
        </g>
    </g>)
}

export const CloakingDevice = () => {
    return <g transform="matrix(22.2222,0,0,22.2222,-5232.89,-8192.67)">
        <g id="Cloaking-Device">
            <circle cx="247" cy="380.19" r="9" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
            <path d="M247,389.19C242.03,389.19 238,385.16 238,380.19C238,375.22 242.03,371.19 247,371.19L247,389.19Z" style={{"fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
    </g>
}
export const CloakingField = () => {
    return <g transform="matrix(0.572671,0,0,0.572671,109.396,37.6282)">
        <g id="Cloaking-Field">
            <g id="Cloaking-Device" transform="matrix(22.2222,0,0,22.2222,-5232.89,-8192.67)">
                <circle cx="247" cy="380.19" r="9" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M247,389.19C242.03,389.19 238,385.16 238,380.19C238,375.22 242.03,371.19 247,371.19L247,389.19Z" style={{"fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            <g transform="matrix(1.7462,0,0,1.7462,-191.028,-65.7064)">
                <path d="M441.442,115.546L441.442,396.454C441.442,447.629 399.895,489.176 348.721,489.176L163.279,489.176C112.105,489.176 70.558,447.629 70.558,396.454L70.558,115.546C70.558,64.371 112.105,22.824 163.279,22.824L348.721,22.824C399.895,22.824 441.442,64.371 441.442,115.546ZM428.942,115.546C428.942,71.27 392.997,35.324 348.721,35.324L163.279,35.324C119.003,35.324 83.058,71.27 83.058,115.546L83.058,396.454C83.058,440.73 119.003,476.676 163.279,476.676L348.721,476.676C392.997,476.676 428.942,440.73 428.942,396.454L428.942,115.546Z"/>
            </g>
            <g transform="matrix(1.78765,0,0,1.81233,-187.756,-46.7096)">
                <path d="M238.83,306.5L238.83,422.122L121.612,422.122L121.612,306.5L238.83,306.5ZM226.62,318.544L133.822,318.544L133.822,410.078L226.62,410.078L226.62,318.544Z"/>
            </g>
            <g transform="matrix(1.78765,0,0,1.81233,55.4127,-46.7096)">
                <path d="M238.83,306.5L238.83,422.122L121.612,422.122L121.612,306.5L238.83,306.5ZM226.62,318.544L133.822,318.544L133.822,410.078L226.62,410.078L226.62,318.544Z"/>
            </g>
        </g>
    </g>
}

export const ECM = () => {
    return <g transform="matrix(37.5,0,0,40.9091,-6606.45,-23140.7)">
        <g id="ECM">
            <g>
                <circle cx="183" cy="570.19" r="4" style={{"fill":"none","stroke":"black","strokeWidth":"0.75px"}}/>
                <circle cx="181" cy="573.19" r="4" style={{"fill":"none","stroke":"black","strokeWidth":"0.75px"}}/>
                <circle cx="185" cy="573.19" r="4" style={{"fill":"none","stroke":"black","strokeWidth":"0.75px"}}/>
            </g>
        </g>
    </g>
}

export const Hanger = ({isRack = false}) => {
    return <g id="Hanger">
        <g id="Hanger" transform="matrix(18.7524,0,0,18.7524,-1975.53,-14074.4)">
            <path d="M111,776.19L127,776.19L119,752.19L111,776.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
        {( isRack ? <g id="RobotRectangle" transform="matrix(1.13864,0,0,1.05889,-35.4917,-41.5304)">
            <path d="M395.663,56.365L395.663,505.602L116.337,505.602L116.337,56.365L395.663,56.365ZM386.514,66.203L125.486,66.203L125.486,495.765L386.514,495.765L386.514,66.203Z"/>
        </g> : "" )}
    </g>
}

export const DamageControlTeam = () => {
    return 
}

export const Bay = ({ type = "cargo", capacity = 0 }) => {
    return <g id="Bay">
            <g transform="matrix(18.753,0,0,18.753,-3175.79,-14074.8)">
                <rect x="175" y="752.19" width="16" height="24" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            {( capacity >= 10 ? <g id="RightNumbers" transform="matrix(1,0,0,1,40,0)">
                {( capacity % 10 == 0 ? <g id="_0" serif_id="0" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,30.455C150.233,55.522 165.433,67.255 185.7,67.255C210.767,67.255 224.1,55.788 224.1,30.455L224.1,-88.745C224.1,-118.078 210.5,-126.878 187.033,-126.878C163.567,-126.878 150.233,-118.078 150.233,-88.745L150.233,30.455ZM178.5,-92.212C178.5,-99.678 182.233,-104.212 186.5,-104.212C192.1,-104.212 195.833,-99.678 195.833,-92.212L195.833,30.455C195.833,35.788 195.3,44.588 187.567,44.588C179.033,44.588 178.5,36.588 178.5,29.388L178.5,-92.212Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 9 ? <g id="_9" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 8 ? <g id="_8" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 7 ? <g id="_7" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 6 ? <g id="_6" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 5 ? <g id="_5" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 4 ? <g id="_4" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 3 ? <g id="_3" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 2 ? <g id="_2" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 1 ? <g id="_1" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
            {( capacity >= 10 ? <g id="LeftNumbers" transform="matrix(1,0,0,1,-40,0)">
                {( Math.floor(capacity / 10) == 9 ? <g id="_91" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 8 ? <g id="_81" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 7 ? <g id="_71" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 6 ? <g id="_61" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 5 ? <g id="_51" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 4 ? <g id="_41" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 3 ? <g id="_31" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 2 ? <g id="_21" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 1 ? <g id="_11" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
            {( capacity < 10 ? <g id="CenterNumbers">
                {( capacity == 0 ? <g id="_02" serif_id="0" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,30.455C150.233,55.522 165.433,67.255 185.7,67.255C210.767,67.255 224.1,55.788 224.1,30.455L224.1,-88.745C224.1,-118.078 210.5,-126.878 187.033,-126.878C163.567,-126.878 150.233,-118.078 150.233,-88.745L150.233,30.455ZM178.5,-92.212C178.5,-99.678 182.233,-104.212 186.5,-104.212C192.1,-104.212 195.833,-99.678 195.833,-92.212L195.833,30.455C195.833,35.788 195.3,44.588 187.567,44.588C179.033,44.588 178.5,36.588 178.5,29.388L178.5,-92.212Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 9 ? <g id="_92" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 8 ? <g id="_82" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 7 ? <g id="_72" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 6 ? <g id="_62" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 5 ? <g id="_52" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 4 ? <g id="_42" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 3 ? <g id="_32" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 2 ? <g id="_22" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 1 ? <g id="_12" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
            {( type.toLocaleLowerCase() == "cargo" ? <g id="H" transform="matrix(1.41446,0,0,1.41446,-150.932,-128.974)">
                <path d="M282.078,272.17L282.078,206.307L293.309,206.307L293.309,272.17L313.487,272.17L313.487,136.638L293.309,136.638L293.309,188.604L282.078,188.604L282.078,136.638L261.901,136.638L261.901,272.17L282.078,272.17Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
            {( type.toLocaleLowerCase() == "troop" ? <g id="T" transform="matrix(1.41446,0,0,1.41446,-148.509,-128.974)">
                <path d="M258.284,136.638L258.284,154.341L275.987,154.341L275.987,272.17L296.165,272.17L296.165,154.341L313.677,154.341L313.677,136.638L258.284,136.638Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
            {( type.toLocaleLowerCase() == "passenger" ? <g id="P" transform="matrix(1.41446,0,0,1.41446,-150.932,-128.974)">
                <path d="M281.507,272.17L281.507,220.203C303.017,221.345 314.248,214.492 314.058,192.411L314.058,163.097C314.629,145.013 304.731,136.638 282.459,136.638L261.33,136.638L261.33,272.17L281.507,272.17ZM281.507,152.818C289.502,152.247 293.88,153.199 293.88,165.762L293.88,197.932C293.309,204.213 287.408,204.023 281.507,204.023L281.507,152.818Z" style={{"fillRule":"nonzero"}}/>
            </g> : "" )}
        </g>
}

export const Magazine = ({ modifier = "standard", capacity = 0 }) => {
    return <g id="Magazine">
            <g transform="matrix(18.753,0,0,18.753,-3175.79,-14074.8)">
                <rect x="175" y="752.19" width="16" height="24" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            {( modifier.toLocaleLowerCase() == "standard" ? <g id="StandardMissile" transform="matrix(23.6043,0,0,23.6043,-1420.02,-4052.31)">
                <path d="M71,174.19L68,182.19L71,180.19L74,182.19L71,174.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px"}}/>
            </g> : "" )}
            {( modifier.toLocaleLowerCase() == "er" ? <g id="StandardMissile" transform="matrix(23.6043,0,0,23.6043,-1420.02,-4052.31)">
                <path d="M71,174.19L68,182.19L71,180.19L74,182.19L71,174.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px"}}/>
            </g> : "" )}
            {( modifier.toLocaleLowerCase() == "twostage" ? <g id="MDM" transform="matrix(20.9816,0,0,20.9816,-3457.86,-5043.2)">
                <path d="M177,243.19L180,249.19L178,248.19L180,252.19L177,250.19L174,252.19L176,248.19L174,249.19L177,243.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px"}}/>
            </g> : "" )}
            {( capacity >= 10 ? <g id="RightNumbers" transform="matrix(1,0,0,1,40,0)">
                {( capacity % 10 == 0 ? <g id="_0" serif_id="0" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,30.455C150.233,55.522 165.433,67.255 185.7,67.255C210.767,67.255 224.1,55.788 224.1,30.455L224.1,-88.745C224.1,-118.078 210.5,-126.878 187.033,-126.878C163.567,-126.878 150.233,-118.078 150.233,-88.745L150.233,30.455ZM178.5,-92.212C178.5,-99.678 182.233,-104.212 186.5,-104.212C192.1,-104.212 195.833,-99.678 195.833,-92.212L195.833,30.455C195.833,35.788 195.3,44.588 187.567,44.588C179.033,44.588 178.5,36.588 178.5,29.388L178.5,-92.212Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 9 ? <g id="_9" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 8 ? <g id="_8" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 7 ? <g id="_7" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 6 ? <g id="_6" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 5 ? <g id="_5" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 4 ? <g id="_4" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 3 ? <g id="_3" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 2 ? <g id="_2" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity % 10 == 1 ? <g id="_1" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
            {( capacity >= 10 ? <g id="LeftNumbers" transform="matrix(1,0,0,1,-40,0)">
                {( Math.floor(capacity / 10) == 9 ? <g id="_91" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 8 ? <g id="_81" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 7 ? <g id="_71" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 6 ? <g id="_61" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 5 ? <g id="_51" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 4 ? <g id="_41" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 3 ? <g id="_31" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 2 ? <g id="_21" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( Math.floor(capacity / 10) == 1 ? <g id="_11" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
            {( capacity < 10 ? <g id="CenterNumbers">
                {( capacity == 0 ? <g id="_02" serif_id="0" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,30.455C150.233,55.522 165.433,67.255 185.7,67.255C210.767,67.255 224.1,55.788 224.1,30.455L224.1,-88.745C224.1,-118.078 210.5,-126.878 187.033,-126.878C163.567,-126.878 150.233,-118.078 150.233,-88.745L150.233,30.455ZM178.5,-92.212C178.5,-99.678 182.233,-104.212 186.5,-104.212C192.1,-104.212 195.833,-99.678 195.833,-92.212L195.833,30.455C195.833,35.788 195.3,44.588 187.567,44.588C179.033,44.588 178.5,36.588 178.5,29.388L178.5,-92.212Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 9 ? <g id="_92" serif_id="9" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,393.39)">
                    <path d="M150.233,-29.012C150.233,-3.145 163.567,1.122 174.767,1.122C182.5,1.122 190.767,-2.612 195.833,-7.945L195.833,30.988C195.833,37.655 194.767,42.988 187.033,42.988C179.3,42.988 178.5,37.655 178.5,30.988L178.5,7.522L150.233,7.522L150.233,26.455C150.233,51.255 159.833,65.655 186.767,65.655C213.167,65.655 224.1,50.988 224.1,27.522L224.1,-93.278C224.1,-116.212 211.833,-128.478 187.567,-128.478C155.833,-128.478 150.233,-110.878 150.233,-91.145L150.233,-29.012ZM178.5,-93.545C178.5,-99.145 178.767,-105.812 186.5,-105.812C195.833,-105.812 195.833,-97.545 195.833,-89.812L195.833,-35.678C195.833,-30.078 194.233,-24.478 187.567,-24.478C180.1,-24.478 178.5,-29.012 178.5,-35.678L178.5,-93.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 8 ? <g id="_82" serif_id="8" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.1254,391.99)">
                    <path d="M150.233,32.588C150.233,54.988 164.1,67.255 186.233,67.255C209.433,67.255 224.1,54.988 224.1,36.588L224.1,-11.145C224.1,-18.078 218.5,-28.212 201.7,-32.745L201.7,-33.278C219.567,-34.612 224.1,-50.878 224.1,-63.945L223.833,-90.612C223.567,-118.078 204.367,-126.878 185.967,-126.878C171.3,-126.878 150.233,-119.678 150.233,-90.612L150.233,-61.012C150.767,-46.612 154.5,-34.345 173.167,-33.278L173.167,-32.745C152.9,-27.678 150.233,-16.478 150.233,-2.878L150.233,32.588ZM195.833,30.988C195.833,36.588 195.567,44.588 187.567,44.588C181.967,44.588 178.5,40.055 178.5,30.988L178.5,-9.812C178.5,-14.612 180.1,-20.212 187.033,-20.212C193.967,-20.212 195.833,-14.612 195.833,-9.812L195.833,30.988ZM195.833,-57.545C195.833,-49.278 192.633,-46.078 187.833,-46.078C180.9,-46.078 178.5,-48.212 178.5,-58.345L178.5,-91.945C178.767,-98.878 180.367,-104.212 187.3,-104.212C193.433,-104.212 195.833,-101.812 195.833,-91.945L195.833,-57.545Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 7 ? <g id="_72" serif_id="7" transform="matrix(0.874903,1.49778e-34,-1.49778e-34,0.874903,92.0087,391.99)">
                    <path d="M148.1,-102.078L198.233,-102.078L155.033,65.122L185.167,65.122L226.5,-101.278L226.5,-124.745L148.1,-124.745L148.1,-102.078Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 6 ? <g id="_62" serif_id="6" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M302.011,133.984C302.011,104.884 287.011,100.084 274.411,100.084C265.711,100.084 256.411,104.284 250.711,110.284L250.711,53.284C250.711,45.784 251.911,39.784 260.611,39.784C269.311,39.784 270.211,45.784 270.211,53.284L270.211,93.484L302.011,93.484L302.011,58.384C302.011,30.484 291.211,14.284 260.911,14.284C231.211,14.284 218.911,30.784 218.911,57.184L218.911,193.084C218.911,218.884 232.711,232.684 260.011,232.684C295.711,232.684 302.011,212.884 302.011,190.684L302.011,133.984ZM270.211,193.384C270.211,199.684 269.911,207.184 261.211,207.184C250.711,207.184 250.711,197.884 250.711,189.184L250.711,141.484C250.711,135.184 252.511,128.884 260.011,128.884C268.411,128.884 270.211,133.984 270.211,141.484L270.211,193.384Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 5 ? <g id="_52" serif_id="5" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,196.384C218.911,224.284 238.411,232.684 259.111,232.684C293.911,232.684 302.011,213.184 302.011,188.884L302.011,136.984C302.011,115.684 297.511,96.184 275.311,96.184C266.611,96.184 258.511,100.384 251.911,105.484L251.911,42.184L299.911,42.184L299.911,16.684L220.111,16.684L220.111,130.384L251.611,130.384C252.511,125.584 255.511,123.784 260.611,123.784C268.411,123.784 270.211,130.384 270.211,138.184L270.211,191.584C270.211,199.684 269.611,207.184 261.811,207.184C251.311,207.184 250.711,199.684 250.711,191.584L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 4 ? <g id="_42" serif_id="4" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M251.011,16.684L212.311,153.784L212.311,175.984L265.711,175.984L265.711,230.284L296.011,230.284L296.011,175.984L308.611,175.984L308.611,150.484L296.011,150.484L296.011,16.684L251.011,16.684ZM238.711,150.484C249.811,115.684 258.211,79.984 265.111,43.684L265.711,43.684L265.711,150.484L238.711,150.484Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 3 ? <g id="_32" serif_id="3" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M218.911,151.684L218.911,181.984C218.911,212.584 226.111,232.684 260.611,232.684C285.211,232.684 302.011,223.684 302.011,189.484L302.011,153.484C302.011,139.984 300.511,130.684 276.811,122.884L276.811,121.684C298.111,116.884 302.011,103.684 302.011,90.484L302.011,55.984C302.011,32.284 291.811,14.284 261.511,14.284C233.911,14.284 218.911,25.084 218.911,57.784L218.911,92.584L250.711,92.584L250.711,60.184C251.311,51.784 249.211,39.784 261.511,39.784C272.011,39.784 269.611,53.884 270.211,69.184L270.211,88.384C270.211,101.284 267.211,110.584 250.711,109.384L250.711,134.884L255.511,134.884C264.811,134.884 270.211,139.384 270.211,151.984L270.211,190.684C270.211,205.684 265.411,207.184 259.411,207.184C255.811,207.184 250.711,205.384 250.711,184.384L250.711,151.684L218.911,151.684Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 2 ? <g id="_22" serif_id="2" transform="matrix(0.777692,0,0,0.777692,53.3195,270.809)">
                    <path d="M253.711,204.784C255.211,157.684 302.011,122.884 302.011,63.484C302.011,38.284 295.711,14.284 265.111,14.284C233.011,14.284 218.911,26.584 218.911,59.584L218.911,93.184L250.711,93.184L250.711,55.684C250.711,46.084 253.711,39.784 261.511,39.784C267.811,39.784 270.211,44.284 270.211,55.684L270.211,75.484C270.211,112.384 218.911,154.384 218.911,216.784L218.911,230.284L300.811,230.284L300.811,204.784L253.711,204.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
                {( capacity == 1 ? <g id="_12" serif_id="1" transform="matrix(0.777692,0,0,0.777692,51.087,269.875)">
                    <path d="M224.911,66.784L248.311,66.784L248.311,230.284L280.111,230.284L280.111,16.684L254.011,16.684C254.311,36.184 242.311,41.884 224.911,44.584L224.911,66.784Z" style={{"fillRule":"nonzero"}}/>
                </g> : "" )}
            </g> : "" )}
        </g>
}


export const LaunchTube = ({ catapult = false }) => {
    return <g>
            {( catapult ? <g id="PulserCenter" transform="matrix(0.759922,-0.438741,0.469233,0.812735,-58.6638,160.258)">
                <path d="M256,96.165L404.041,176.082L404.041,335.918L256,415.835L107.959,335.918L107.959,176.082L256,96.165ZM256,103.855L115.081,179.927L115.081,332.073L256,408.145L396.919,332.073L396.919,179.927L256,103.855Z"/>
            </g> : "" )}
            <g id="LaunchTube">
                <g transform="matrix(28.6243,0,0,28.6243,-402.358,-6848.26)">
                    <circle cx="23" cy="248.19" r="6" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px"}}/>
                </g>
                <g transform="matrix(28.6243,0,0,28.6243,-402.358,-6848.26)">
                    <path d="M23,244.19L20,251.19L23,250.19L26,251.19L23,244.19Z" style={{"fillRule":"nonzero"}}/>
                </g>
            </g>
        </g>
}

export const Decoy = ({ type = "cruiser" }) => {
    return <g transform="matrix(32.4777,0,0,32.4777,-7766.67,-18359.9)">
        <g id="Decoy">
            {( type.toLocaleLowerCase() == "capital" ? <circle id="Capital" cx="247" cy="572.19" r="2" style={{"stroke":"black","strokeWidth":"0.25px"}}/> : "" )}
            {( type.toLocaleLowerCase() == "cruiser" ?<g id="Cruiser" transform="matrix(1,0,0,1,0,-32)">
                <circle cx="247" cy="604.19" r="1" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px"}}/>
            </g> : "" )}
            <path d="M253.946,572.219C252.566,569.812 249.972,568.19 247,568.19C244.057,568.19 241.485,569.779 240.096,572.145L247,578.19L253.946,572.219Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
    </g>
}

export const MineLayer = () => {
    return <g transform="matrix(13.8744,0,0,13.8744,-1395.05,-8126.77)">
        <g id="MineLayer">
            <g>
                <path d="M117,594.19L121,594.19L119,597.19L117,594.19Z" style={{"fillRule":"nonzero"}}/>
                <path d="M119,595.19L119,592.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M121.598,596.69L119,595.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M116.402,596.69L119,595.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            <g>
                <path d="M117,612.19L121,612.19L119,615.19L117,612.19Z" style={{"fillRule":"nonzero"}}/>
                <path d="M119,613.19L119,610.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M121.598,614.69L119,613.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M116.402,614.69L119,613.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            <g>
                <path d="M117,603.19L121,603.19L119,606.19L117,603.19Z" style={{"fillRule":"nonzero"}}/>
                <path d="M119,604.19L119,601.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M121.598,605.69L119,604.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M116.402,605.69L119,604.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            <rect x="113" y="589.19" width="12" height="30" style={{"fill":"none","stroke":"black","strokeWidth":"0.25px","strokeLinecap":"butt"}}/>
        </g>
    </g>
}

export const MineSweeper = () => {
    return <g transform="matrix(32,0,0,35.7303,-3552,-20252.5)">
        <g id="MineSweeper">
            <g>
                <path d="M117,571.19L121,571.19L119,574.19L117,571.19Z" style={{"fillRule":"nonzero"}}/>
                <path d="M119,572.19L119,569.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M121.598,573.69L119,572.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
                <path d="M116.402,573.69L119,572.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
            </g>
            <path d="M112,571.19L119,578.19L126,571.19" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px","strokeLinecap":"butt"}}/>
            <rect x="111" y="564.19" width="16" height="16" style={{"fill":"none"}}/>
        </g>
    </g>
}

export const Ortillery = () => {
    return <g id="Ortillery">
        <g transform="matrix(-18.7524,-2.2965e-15,2.2965e-15,-18.7524,2487.53,14586.4)">
            <path d="M111,776.19L127,776.19L119,752.19L111,776.19Z" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
        <g transform="matrix(-30.6429,-3.75267e-15,3.75267e-15,-30.6429,1757.5,6271.31)">
            <path d="M49,194.19L46,202.19L49,200.19L52,202.19L49,194.19Z" style={{"fillRule":"nonzero","stroke":"black","strokeWidth":"0.25px"}}/>
        </g>
    </g>
}

export const ReflexField = () => {
    return <g transform="matrix(25,0,0,25,-5919,-10048.7)">
        <g id="ReflexField">
            <circle cx="247" cy="412.19" r="9" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
            <circle cx="247" cy="412.19" r="4.5" style={{"stroke":"black","strokeWidth":"0.5px"}}/>
            <circle cx="247" cy="412.19" r="6.75" style={{"fill":"none","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
    </g>
}
 
export const StealthFieldGenerator = () => {
    return <g transform="matrix(49.3769,0,0,49.3769,-4039.81,-29972)">
    <g id="Stealth-Field-Generator">
        <g transform="matrix(0.0143206,-0.0143206,0.0143206,0.0143206,78.2543,612.776)">
            <path d="M354.754,305.368C354.754,278.116 332.629,255.991 305.377,255.991L206.623,255.991C179.371,255.991 157.246,278.116 157.246,305.368L157.246,404.121C157.246,431.373 179.371,453.498 206.623,453.498L305.377,453.498C332.629,453.498 354.754,431.373 354.754,404.121L354.754,305.368Z"/>
            <path d="M354.754,305.368L354.754,404.121C354.754,431.373 332.629,453.498 305.377,453.498L206.623,453.498C179.371,453.498 157.246,431.373 157.246,404.121L157.246,305.368C157.246,278.116 179.371,255.991 206.623,255.991L305.377,255.991C332.629,255.991 354.754,278.116 354.754,305.368ZM344.337,305.368C344.337,283.865 326.88,266.407 305.377,266.407L206.623,266.407C185.12,266.407 167.663,283.865 167.663,305.368L167.663,404.121C167.663,425.624 185.12,443.082 206.623,443.082L305.377,443.082C326.88,443.082 344.337,425.624 344.337,404.121L344.337,305.368Z"/>
        </g>
        <path d="M84.172,611.362C85.734,609.8 88.267,609.8 89.829,611.362" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.75px"}}/>
        <path d="M82.758,609.948C85.101,607.604 88.901,607.605 91.243,609.948" style={{"fill":"none","fillRule":"nonzero","stroke":"black","strokeWidth":"0.75px"}}/>
    </g>
</g>
}

export const Holofield = () => {
    return <g id="HoloField">
        <g transform="matrix(18.7505,0,0,18.7505,-2575.33,-14073)">
            <path d="M151,752.19L145,760.19L145,772.19L149,776.19L153,776.19L157,772.19L157,760.19L151,752.19Z" style={{"fillRule":"nonzero","stroke":"black","strokeWidth":"0.5px"}}/>
        </g>
        <g transform="matrix(1,0,0,1,-6,0)">
            <path d="M231.929,30.993L120.927,181.065L120.927,409.661L190.095,481.007" style={{"fill":"none","stroke":"black","strokeWidth":"10.42px","strokeLinecap":"round","strokeLinejoin":"round","strokeMiterlimit":"1.5"}}/>
        </g>
        <g transform="matrix(1,0,0,1,-36,0)">
            <path d="M231.929,30.993L120.927,181.065L120.927,409.661L190.095,481.007" style={{"fill":"none","stroke":"black","strokeWidth":"10.42px","strokeLinecap":"round","strokeLinejoin":"round","strokeMiterlimit":"1.5"}}/>
        </g>
        <g transform="matrix(-1,0,0,1,517.856,0)">
            <path d="M231.929,30.993L120.927,181.065L120.927,409.661L190.095,481.007" style={{"fill":"none","stroke":"black","strokeWidth":"10.42px","strokeLinecap":"round","strokeLinejoin":"round","strokeMiterlimit":"1.5"}}/>
        </g>
        <g transform="matrix(-1,0,0,1,547.856,0)">
            <path d="M231.929,30.993L120.927,181.065L120.927,409.661L190.095,481.007" style={{"fill":"none","stroke":"black","strokeWidth":"10.42px","strokeLinecap":"round","strokeLinejoin":"round","strokeMiterlimit":"1.5"}}/>
        </g>
    </g>
}

export const DCStar = () => {
    return  <g id="DCStar">
        <path d="M256,31.091L306.495,186.499L469.901,186.499L337.703,282.547L388.198,437.955L256,341.908L123.802,437.955L174.297,282.547L42.099,186.499L205.505,186.499L256,31.091Z"/>
        <path d="M256,31.091L306.495,186.499L469.901,186.499L337.703,282.547L388.198,437.955L256,341.908L123.802,437.955L174.297,282.547L42.099,186.499L205.505,186.499L256,31.091ZM256,64.8L215.412,189.718C214.017,194.01 210.018,196.916 205.505,196.916L74.158,196.916L180.42,274.12C184.071,276.772 185.598,281.474 184.204,285.766L143.616,410.684L249.877,333.48C253.528,330.828 258.472,330.828 262.123,333.48L368.384,410.684L327.796,285.766C326.402,281.474 327.929,276.772 331.58,274.12L437.842,196.916L306.495,196.916C301.982,196.916 297.983,194.01 296.588,189.718L256,64.8Z"/>
    </g>
}

export const Turret = ({ arcs = "abcdef" }) => {
    return <g>
            <HexWeaponArc arcs={arcs}/>
            <g transform="matrix(0.85495,-0.493606,0.52791,0.914368,-98.0124,148.285)">
                <path id="TurretCenter" d="M256,96.165L404.041,176.082L404.041,335.918L256,415.835L107.959,335.918L107.959,176.082L256,96.165ZM256,103L114.29,179.5L114.29,332.5L256,409L397.71,332.5L397.71,179.5L256,103Z"/>
            </g>
        </g>
}

export const MarineTeam = () => {
    return <g transform="matrix(0.878912,0,0,0.878906,2.87192,31)">
        <path id="Marine" d="M260.7,192C289,192 315.7,202.7 335.1,221.1L335.1,496.2C335.9,504.1 328.8,512 320,512C311.2,512 304,504.8 304,496L304,236.7C291.3,228.6 276.3,224 260.7,224L219.3,224C203.7,224 188.7,228.6 176,236.8L176,496C176,504.8 168.8,512 160,512C151.2,512 144,504.8 144,496L144,275.8L62.32,439.2C58.37,447.1 48.76,450.3 40.85,446.3C32.95,442.4 29.74,432.7 33.7,424.8L119.2,253.9C138.1,215.1 176.9,192 219.3,192L260.7,192ZM320,80C320,124.2 284.2,160 240,160C195.8,160 160,124.2 160,80C160,35.82 195.8,0 240,0C284.2,0 320,35.82 320,80ZM240,32C213.5,32 192,53.49 192,80C192,106.5 213.5,128 240,128C266.5,128 288,106.5 288,80C288,53.49 266.5,32 240,32ZM512,208C512,199.2 519.2,192 528,192C536.8,192 544,199.2 544,208L544,292.5C544,299.4 539.6,305.5 533.1,307.6L480,325.3L480,352L483.2,368L528,368C536.8,368 544,375.2 544,384C544,392.8 536.8,400 528,400L489.6,400L508.2,492.9C510.2,502.8 502.6,512 492.5,512L400,512C391.2,512 384,504.8 384,496L384,395.7C374.4,390.2 368,379.8 368,368L368,224C368,212.2 374.4,201.8 384,196.3L384,160C384,142.3 398.3,128 416,128L416,32C407.2,32 400,24.84 400,16C400,7.164 407.2,0 416,0L432,0C440.8,0 448,7.164 448,16L448,128C465.7,128 480,142.3 480,160L480,288L512,280L512,208ZM416,214.7L400,224L400,368L416,377.3L416,480L472.1,480L448,355.2L448,160L416,160L416,214.7Z" style={{"fillRule":"nonzero"}}/>
    </g>
}

// Hull Components

export const HullComponent = ({ hasDCP = false }) => {
    return <g key="hullComponent">
        <path d="M488.693,23.307L23.307,23.307L23.307,488.693L488.693,488.693L488.693,23.307ZM467.859,44.141L467.859,467.859C467.859,467.859 132.012,467.859 44.141,467.859C44.141,467.859 44.141,44.141 44.141,44.141C44.141,44.141 467.859,44.141 467.859,44.141L467.859,44.141Z"/>
        {( hasDCP === true ? <g id="DCStar">
            <path d="M256,31.091L306.495,186.499L469.901,186.499L337.703,282.547L388.198,437.955L256,341.908L123.802,437.955L174.297,282.547L42.099,186.499L205.505,186.499L256,31.091Z"/>
            <path d="M256,31.091L306.495,186.499L469.901,186.499L337.703,282.547L388.198,437.955L256,341.908L123.802,437.955L174.297,282.547L42.099,186.499L205.505,186.499L256,31.091ZM256,64.8L215.412,189.718C214.017,194.01 210.018,196.916 205.505,196.916L74.158,196.916L180.42,274.12C184.071,276.772 185.598,281.474 184.204,285.766L143.616,410.684L249.877,333.48C253.528,330.828 258.472,330.828 262.123,333.48L368.384,410.684L327.796,285.766C326.402,281.474 327.929,276.772 331.58,274.12L437.842,196.916L306.495,196.916C301.982,196.916 297.983,194.01 296.588,189.718L256,64.8Z"/>
        </g> : "")}
    </g>
}

export const ArmorComponent = () => {
    return <g id="Armor-Component" style={{fillRule: "evenodd", clipRule:"evenodd", strokeLinejoin:"round", strokeMiterlimit:2}} transform="matrix(0.987726,0,0,0.987726,3.14214,3.14214)">
        <path d="M256,20.611C384.247,20.611 488.368,126.085 488.368,256C488.368,385.915 384.247,491.389 256,491.389C127.753,491.389 23.632,385.915 23.632,256C23.632,126.085 127.753,20.611 256,20.611ZM256,41.703C372.682,41.703 467.275,137.801 467.275,256C467.275,374.199 372.682,470.297 256,470.297C139.318,470.297 44.725,374.199 44.725,256C44.725,137.801 139.318,41.703 256,41.703Z"/>
    </g>
}

export const RegenerativeArmorComponent = () => {
    return <g id="Regenerating-Armor" style={{fillRule: "evenodd", clipRule:"evenodd", strokeLinejoin:"round", strokeMiterlimit:2}} transform="matrix(0.715851,0.715851,-0.683581,0.683581,247.739,-102.255)">
        <path d="M416.515,87.908L95.485,87.908L95.485,424.092L416.515,424.092L416.515,87.908ZM395.936,109.458L395.936,402.542C338.004,402.542 173.996,402.542 116.064,402.542C116.064,341.875 116.064,170.125 116.064,109.458L395.936,109.458Z"/>
    </g>
}


