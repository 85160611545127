import { 
  BeamMount,
  PulseTorpedoMount,
  PhaserMount,
  GraserMount,
  HeavyGraserMount,
  HIGraserMount,
  GenericSystem,
  SublightDrive,
  FTLDrive,
  Sensors,
  Screen,
  FireControl,
  AreaDefenseFireControl,
  SuicideCharge,
  PointDefenseSystem,
  AreaDefenseSystem,
  FusionArray,
  GatlingBattery,
  EMPCannon,
  Grapeshot,
  GravityGun,
  KineticGun,
  MesonProjector,
  TwinParticleArray,
  NeedleBeam,
  MultipleKineticPenetrator,
  PlasmaBoltLauncher,
  Pulser,
  Scattergun,
  Transporter,
  SpinalBeam,
  SpinalPlasma,
  SpinalPSP,
  TurretedSubmunition,
  CloakingDevice,
  CloakingField,
  ECM,
  Hanger,
  Decoy,
  LaunchTube,
  Bay,
  Ortillery,
  MineLayer,
  MineSweeper,
  Magazine,
  ReflexField,
  StealthFieldGenerator,
  Holofield,
  DCStar,
  Turret,
  MarineTeam,
  PlasmaCannon,
  MissileLauncher
} from "components/componentSymbols"

import { getCircularSlice } from "data/util";

const weaponArcBuilder = (weapon) => {
  if(typeof weapon.arcs == "string") { return weapon.arcs; }
  if(weapon.leftArc && weapon.numArcs) {
      let arcs = "abcdef";
      let startIndex = 0;
      switch(weapon.leftArc) {
          case "F": startIndex = 0; break;
          case "FS": startIndex = 1; break;
          case "AS": startIndex = 2; break;
          case "A": startIndex = 3; break;
          case "AP": startIndex = 4; break;
          case "FP": startIndex = 5; break;
      }
      return getCircularSlice(arcs, startIndex, weapon.numArcs) 
  }
  return "a";
}

export let WeaponConfigMap = {
    "ads" : { "label" : "Area Defense System", "name": "ads", "arcs": "fab" },
    "beam" : { "label": "Beam", "name": "beam", "class": 1, "arcs": "abcdef" },
    "emp" : { "label": "EMP", "name": "emp", "class": 1, "arcs": "abcdef" },
    "fusion" : { "label": "Fusion", "name": "fusion", "arcs": "a" },
    "gatling" : { "label": "Gatling Laser", "name": "gatling", "arcs": "a" },
    "grapeshot" : { "label": "Grapeshot", "name": "grapeshot" },
    "graser" : {
      "label": "Graser", "name": "graser",
      "class": 1,
      "arcs": "abcdef",
      "heavy": false,
      "highIntensity": false
    },
    "gravitic" : {
      "label": "Gravity Gun", "name": "gravitic",
      "class": 1,
      "arcs": "a"
    },
    "kgun" : {
      "label": "Kinetic Gun", "name": "kgun",
      "class": 1,
      "arcs": "a",
      "modifier": "short"
    },
    "meson" : { "label": "Meson Projector", "name": "meson",  "arcs": "a" },
    "mkp" : { "label": "Multiple Kinetic Penetrator", "name": "mkp",  "arcs": "a" },
    "needle" : {
      "label": "Needle Beam", "name": "needle",
      "class": 1,
      "arcs": "ab"
    },
    "phaser" : {
      "label": "Phaser", "name": "phaser",
      "class": 1,
      "arcs": "fab"
    },
    "pbl" : { "label": "Plasma Bolt Launcher", "name": "pbl", "class": 1,  "arcs": "a" },
    "plasmaCannon" : {
      "label": "Plasma Cannon", "name": "plasmaCannon",
      "class": 1,
      "arcs": "fab"
    },
    "pds" : { "label": "Point Defense System", "name": "pds" },
    "torpedoPulse" : { "label": "Pulse Torpedo Launcher", "name": "torpedoPulse",  "arcs": "a" },
    "pulser" : { "label": "Pulser", "name": "pulser", "arcs": "a" },
    "scatterGun" : { "label": "Scatter Gun", "name": "scatterGun" },
    "spinalBeam" : { "label": "Spinal Mount - Beam", "name": "spinalBeam", "range": "short" },
    "spinalPlasma" : { "label": "Spinal Mount - Plasma", "name": "spinalPlasma", "range": "medium" },
    "spinalSingularity" : { "label": "Point Singularity Projector", "name": "spinalSingularity", "range": "long" },
    "transporter" : {
      "label": "Transporter", "name": "transporter",
      "class": 1,
      "arcs": "abcdef"

    },
    "submunition" : { "label": "Submunition Launcher", "name": "submunition",  "arcs": "fab"},
    "particle" : { "label": "Twin Particle Array", "name": "particle",  "arcs": "a" }
};
export let SystemConfigMap = {
    "drive" : { "label": "Normal Space Drive", "name": "drive", "thrust": 1, "advanced": false },
    "ftl" : { "label": "FTL Drive", "name": "ftl", "advanced": false },
    "sensors" : { "label": "Advanced Sensors", "name": "sensors", "advanced": false },
    "suicide" : { "label": "Suicide Charge", "name": "suicide" },
    "adfc" : { "label": "Area Defense Fire Control", "name": "adfc", "advanced": false },
    "cloakingDevice" : { "label": "Cloaking Device", "name": "cloakDevice" },
    "cloakingField" : { "label": "Cloaking Field", "name": "cloakField" },
    "screen" : { "label": "Screen", "name": "screen", "area": false, "advanced": false },
    "ecm" : { "label": "ECM Emitter", "name": "ecm", "area": false },
    "marines" : { "label": "Marines", "name": "marines" },
    "hanger" : { "label": "Hanger", "name": "hangar", "isRack": false, "critRules": false },
    "fireControl" : { "label": "Fire Control System", "name": "fireControl", "advanced": false },
    "bay" : { "label": "Storage Bay", "name": "bay", "capacity": 1, "type": "cargo" },
    "holofield" : { "label": "Holo Field", "name": "holofield" },
    "launchTube" : { "label": "Launch Tube", "name": "launchTube", "catapult": true },
    "mineLayer" : { "label": "Mine Layer", "name": "mineLayer", "capacity": 2 },
    "mineSweeper" : { "label": "Mine Sweeper", "name": "mineSweeper" },
    "ortillery" : { "label": "Orbital Artillery", "name": "ortillery" },
    "reflex" : { "label": "Reflex Field", "name": "reflex" },
    "magazine" : { "label": "Magazine", "name": "magazine", "capacity": 2 },
    "stealthField" : { "label": "Stealth Field", "name": "stealthField" },
    "turret": {
      "label": "Turret", 
      "name": "turret",
      "leftArc": "F",
      "numArcs": 1,
      "weapons": [],
      "size": 4
    },
};

export let OrdnanceConfigMap = {
  "amt" : { "label" : "Anti-Matter Missile", "name" : "amt" },
  "missile" : { "label" : "Heavy Missile", "name" : "missile" },
  "rocketPod" : { "label" : "Rocket Pod", "name" : "rocketPod", "arcs": "fab" },
  "salvoLauncher" : { "label" : "Salvo Launcher", "name" : "salvoLauncher", "arcs": "fab" },
  "salvo" : { "label" : "Salvo Rack", "name" : "salvo" },
}

export var FighterWeaponTypeConfigMap = {
  "assault": "beam",
  "attack": "beam",
  "graser": "graser",
  "interceptor": "beam",
  "lightAttack": "beam",
  "light": "beam",
  "lightInterceptor": "beam",
  "missile": "beam",
  "MKP": "mkp",
  "multiRole": "beam",
  "plasma": "plasmaCannon",
  "standard": "beam",
  "torpedo": "torpedoPulse"
}

export const SystemIconKeyMap = {
  "drive": (systemConfig) => { return <SublightDrive rating={systemConfig.thrust} thrust={systemConfig.thrust} advanced={systemConfig.advanced}/>; },
  "ftl": (systemConfig) => { return <FTLDrive advanced={systemConfig.advanced}/>; },
  "sensors": (systemConfig) => { return <Sensors advanced={systemConfig.advanced}/>; },
  "suicide": (systemConfig) => { return <SuicideCharge/>; },
  "adfc": (systemConfig) => { return <AreaDefenseFireControl advanced={systemConfig.advanced}/>; },
  "cloakDevice": (systemConfig) => { return <CloakingDevice/>; },
  "cloakField": (systemConfig) => { return <CloakingField/>; },
  "screen": (systemConfig) => { return <Screen advanced={systemConfig.advanced}/>; },
  "ecm": (systemConfig) => { return <ECM/>; },
  "damageControl": (systemConfig) => { return <DCStar/>; },
  "marines": (systemConfig) => { return <MarineTeam/>; },
  "hangar": (systemConfig) => { return <Hanger isRack={ systemConfig.isRack || false }/>; },
  "fireControl": (systemConfig) => { return <FireControl advanced={systemConfig.advanced}/>; },
  "bay": (systemConfig) => { return <Bay type={systemConfig.type || "cargo"} capacity={systemConfig.capacity || 1}/>; },
  "holofield": (systemConfig) => { return <Holofield/>; },
  "launchTube": (systemConfig) => { return <LaunchTube catapult={systemConfig.catapult || false }/>; },
  "mineLayer": (systemConfig) => { return <MineLayer/>; },
  "mineSweeper": (systemConfig) => { return <MineSweeper/>; },
  "ortillery": (systemConfig) => { return <Ortillery/>; },
  "reflex": (systemConfig) => { return <ReflexField/>; },
  "magazine": (systemConfig) => { return <Magazine capacity={systemConfig.capacity || 1 } modifier={systemConfig.modifier || "standard"}/>; },
  "stealthField": (systemConfig) => { return <StealthFieldGenerator/>; },
  "turret": (systemConfig) => { return <Turret arcs={weaponArcBuilder(systemConfig)}/>; },
  "decoy": (systemConfig) => { return <Decoy type={systemConfig.type || "cruiser"}/>; },
}

export const WeaponIconKeyMap = {
  "ads" : (systemConfig) => { return <AreaDefenseSystem arcs={weaponArcBuilder(systemConfig)}/>; },
  "beam" : (systemConfig) => { return <BeamMount beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "plasmaCannon" : (systemConfig) => { return <PlasmaCannon beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "emp" : (systemConfig) => { return <EMPCannon beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "fusion" : (systemConfig) => { return <FusionArray arcs={weaponArcBuilder(systemConfig)}/>; },
  "gatling" : (systemConfig) => { return <GatlingBattery arcs={weaponArcBuilder(systemConfig)}/>; },
  "grapeshot" : (systemConfig) => { return <Grapeshot/>; },
  "graser" : (systemConfig) => { 
      if(systemConfig.heavy && systemConfig.highIntensity) { return <HIGraserMount beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/> }    
      if(systemConfig.heavy) { return <HeavyGraserMount beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/> }
      return <GraserMount beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>
  },
  "gravitic" : (systemConfig) => { return <GravityGun beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "kgun" : (systemConfig) => { return <KineticGun gunClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "meson" : (systemConfig) => { return <MesonProjector/>; },
  "mkp" : (systemConfig) => { return <MultipleKineticPenetrator/>; },
  "needle" : (systemConfig) => { return <NeedleBeam beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "phaser" : (systemConfig) => { return <PhaserMount beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "pbl" : (systemConfig) => { return <PlasmaBoltLauncher emitterClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "pds" : (systemConfig) => { return <PointDefenseSystem/>; },
  "torpedoPulse" : (systemConfig) => { return <PulseTorpedoMount type={systemConfig.type || systemConfig.modifier} arcs={weaponArcBuilder(systemConfig)}/>; },
  "pulser" : (systemConfig) => { return <Pulser arcs={weaponArcBuilder(systemConfig)}/>; },
  "scatterGun" : (systemConfig) => { return <Scattergun/>; },
  "spinalBeam" : (systemConfig) => { return <SpinalBeam range={systemConfig.range || "medium"}/>; },
  "spinalNova" : (systemConfig) => { return <SpinalBeam range={systemConfig.range || "medium"}/>; },
  "spinalPlasma" : (systemConfig) => { return <SpinalPlasma range={systemConfig.range || "medium"}/>; },
  "spinalSingularity" : (systemConfig) => { return <SpinalPSP range={systemConfig.range || "medium"}/>; },
  "spinalWave" : (systemConfig) => { return <SpinalBeam range={systemConfig.range || "medium"}/>; },
  "transporter" : (systemConfig) => { return <Transporter beamClass={systemConfig.class} arcs={weaponArcBuilder(systemConfig)}/>; },
  "submunition" : (systemConfig) => { return <TurretedSubmunition arcs={weaponArcBuilder(systemConfig)}/>; },
  "particle" : (systemConfig) => { return <TwinParticleArray arcs={weaponArcBuilder(systemConfig)}/>; },
}

export const OrdnanceIconKeyMap = {
  "amt" : (systemConfig) => { return <GenericSystem/> },
  "missile" : (systemConfig) => { return <MissileLauncher arcs={weaponArcBuilder(systemConfig)}/> },
  "rocketPod" : (systemConfig) => { return <GenericSystem/> },
  "salvoLauncher" : (systemConfig) => { return <MissileLauncher arcs={weaponArcBuilder(systemConfig)} modifier/> },
  "salvo" : (systemConfig) => { return <MissileLauncher arcs={"fab"} modifier={systemConfig.modifier}/> },
}