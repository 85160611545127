import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ProgressBar, FormGroup, Button } from "@blueprintjs/core"
import ShipList from "./ShipList"
import { SaveableTextField } from "components/SaveableTextField"
import WSDataBus from "data/Components/WSDataBus"
let randomString = function(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export default function Mission() {

    let params = useParams();
    let [currentTurn, setCurrentTurn] = useState(0)
    let [missionData, setMissionData] = useState(null)
    let missionMessageBus = useRef(new WSDataBus())

    useEffect(() => {
        missionMessageBus.current.connect(`${(location.protocol.includes("https") ? "wss:" : "ws:")}//${location.host}/mission_state_bus/${params.mission_id}`)
        loadMission()

        return () => {}
        
    }, [params.mission_id])

    const loadMission = async () => {
        let response = await fetch(`/mission/${params.mission_id}`)    
        let data = await response.json()
        setMissionData(data);
        setCurrentTurn(data.current_turn_number)
        
    }
    const updateCurrentTurn = async (newValue) => {
        let response = await fetch(`/mission/${params.mission_id}`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify({ current_turn_number: newValue })})
        if(response.status == 200) { setCurrentTurn(newValue) } else { console.error("Error Updating Turn", response.body) }
    }

    const turnReset = async () => {
        let response = await fetch(`/ships/turn_reset/${params.mission_id}`, { method: "POST", headers: { "Content-Type": "application/json"}, body: JSON.stringify({})})
        if(response.status == 200) { } else { console.error("Turn Reset Error", response.body)}
    }

    if(missionData == null) { return <ProgressBar/> }

    return (<div style={{ padding: 5 }}>
        <FormGroup label="Current Turn">
            <SaveableTextField value={currentTurn} onSave={(new_value) => { updateCurrentTurn(new_value) }}/>
            <Button intent="success" onClick={() => { updateCurrentTurn(currentTurn + 1); }}> Next Turn </Button>
            <Button intent="warning" onClick={turnReset}> Turn Status Reset </Button>
        </FormGroup>
        <ShipList mission_id={params.mission_id} missionMessageBus={missionMessageBus.current}/>
    </div>)
}