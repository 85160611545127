import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { FormGroup, InputGroup, Button, ControlGroup } from "@blueprintjs/core"

export default function UserDetails({ user = {}, onSave = () => {}, onClose = () => {}, onRemove = () => {} }) {

    let idFieldRef = useRef(null)

    let [userData, setUserData] = useState(user)

    const updateUsername = (e) => {
        let data = { ...userData }
        data.username = e.target.value
        setUserData(data);
    }

    useEffect(() => {
        if(idFieldRef.current != null) { idFieldRef.current.readOnly = true; }
    }, [idFieldRef])

    return <div>
        <FormGroup label="ID">
            <InputGroup size={64} inputRef={idFieldRef} type="text" defaultValue={userData.id}/>
        </FormGroup>
        <FormGroup label="Username">
            <InputGroup type="text" value={userData.username} onChange={updateUsername}/>
        </FormGroup>
        <ControlGroup fill={true}>
            <Button intent="success" onClick={() => onSave(userData)}>Save</Button>
            <Button intent="danger" onClick={() => onRemove()}>Remove</Button>
            <Button intent="none" onClick={() => onClose()}>Close</Button>
        </ControlGroup>
    </div>
}