import { useEffect, useState } from "react"
import { FormGroup, InputGroup, H1, Button, Card } from "@blueprintjs/core"

export default function RegisterUser() {
    let [username, setUsername] = useState("")
    let [password, setPassword] = useState("")
    let [usernameValid, setUsernameValid] = useState(false);

    let register = async () => {
        try {
            let response = await fetch("/user/register", {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    username: username,
                    password: password
                })

            })
            let data = await response.json()
            console.log(data);
        } catch (err) {
            console.log(err)
        }
    }

    let isPasswordValid = () => {
        if( password.length < 1) { return false }
        return true
    }

    useEffect(() => {
        (async () => {
            if(username.length < 1) {
                setUsernameValid(false)
                return
            }
            try {
                let response = await fetch(`/user/username_check?username=${username}`, { method: "GET" })
                let data = await response.json()
                console.log("Checked Username: ", data)
                setUsernameValid(data.valid)
            } catch (err) {
                console.log(err)
                alert("Error Checking Username")
                setUsernameValid(false)
            }
        })()
    }, [username])

    return <div style={{ display: "flex", justifyContent:"center", flexBasis: 200, gap: 20, padding: 20 }}>
        <Card>
            <H1> User Registration </H1>
            <FormGroup label="Username" labelInfo={(usernameValid ? "(Required)" : "(Invalid Username)")} intent={(usernameValid ? "success" : "danger")}>
                <InputGroup type="text" value={username} onChange={(e) => setUsername(e.target.value)}/>
            </FormGroup>
            <FormGroup label="Password" labelInfo={(isPasswordValid() ? "(Required)" : "(Invalid Password)")}>
                <InputGroup type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
            </FormGroup>
            <Button intent="primary" onClick={register}> Register </Button>
        </Card>
    </div>
}