import { useRef, useEffect, useState } from "react"
export default function ToggleableComponent({ 
    componentSymbol = <path key="cmpt-symbol" style={{ stroke: "black", fill: "black" }} d="M176 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64c-35.3 0-64 28.7-64 64H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64v56H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H64c0 35.3 28.7 64 64 64v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448h56v40c0 13.3 10.7 24 24 24s24-10.7 24-24V448c35.3 0 64-28.7 64-64h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V280h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448V176h40c13.3 0 24-10.7 24-24s-10.7-24-24-24H448c0-35.3-28.7-64-64-64V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H280V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H176V24zM160 128H352c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm192 32H160V352H352V160z"/>,
    label = "Cmpt",
    onToggle = () => {},
    onActivate = () => {},
    disabled = false,
    destroyed = false,
    activated = false,
    width = 50,
    height = 50,
}) {
    let compositeSymbolComponents =  [<g key="component" id="cmpt">{componentSymbol}</g>];
    if(disabled && !destroyed) { 
        compositeSymbolComponents.push(<g key="disabled-overlay" id="dsbld">
            <path d="M18.5,18.5L493.5,493.5" style={{ strokeWidth: "33.33px", stroke:"red", fill: "none" }}/>
            <path d="M18.5,493.5L493.5,18.5" style={{ strokeWidth: "33.33px", stroke:"red", fill: "none" }}/>
        </g>) 
    }
    if(destroyed) { 
        compositeSymbolComponents.push(<g key="destroyed-overlay" transform="matrix(0.700564,0,0,0.700564,54.2376,76.6712)">
        <path id="Destroyed" d="M499.6,11.3C506.3,0.6 520.1,-3.2 531.3,2.8C542.5,8.8 547.1,22.3 541.9,33.8L404.8,338.6C407,340.9 409.1,343.3 411.1,345.7L508.3,291C518.8,285.1 531.9,287.9 539.2,297.4C546.5,306.9 545.5,320.4 537,328.9L450,415.9L378.5,415.9C365.3,378.6 329.8,351.9 288,351.9C246.2,351.9 210.6,378.6 197.5,415.9L117.8,415.9L42.3,363.7C32.6,357 29.2,344.1 34.4,333.4C39.6,322.7 51.8,317.5 63.1,321L160.3,351.4C163.3,347.5 166.4,343.7 169.7,340.1L107.4,236.3C101.3,226.2 103.5,213.2 112.5,205.6C121.5,198 134.7,198.1 143.6,205.7L246,293.6C247.5,293.2 249,292.8 250.5,292.5L264.1,149.8C265.3,137.5 275.6,128.1 288,128.1C300.4,128.1 310.7,137.5 311.9,149.8L325.4,291.7L499.6,11.3ZM32,448L544,448C561.7,448 576,462.3 576,480C576,497.7 561.7,512 544,512L32,512C14.3,512 0,497.7 0,480C0,462.3 14.3,448 32,448ZM288,0C301.3,0 312,10.7 312,24L312,72C312,85.3 301.3,96 288,96C274.7,96 264,85.3 264,72L264,24C264,10.7 274.7,0 288,0Z" style={{"fill":"rgb(255,101,0)","fillRule":"nonzero","stroke":"black","strokeWidth":"5px"}}/>
    </g>)
    }
    if(activated) { 
        compositeSymbolComponents.push(<g key="activated_overlay" transform="matrix(1,0,0,1,61.2511,32.6203)">
        <path id="Activated" d="M361.088,-7.62C396.775,-7.62 425.749,21.353 425.749,57.04C425.749,92.728 396.775,121.701 361.088,121.701C325.401,121.701 296.428,92.728 296.428,57.04C296.428,21.353 325.401,-7.62 361.088,-7.62ZM361.088,24.71C378.932,24.71 393.419,39.197 393.419,57.04C393.419,74.884 378.932,89.371 361.088,89.371C343.245,89.371 328.758,74.884 328.758,57.04C328.758,39.197 343.245,24.71 361.088,24.71Z" style={{"fill":"rgb(9,223,0)"}}/>
    </g>)
    }
    return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", minWidth: width, padding: 5 }} onClick={onToggle} onContextMenu={onActivate}>
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            {compositeSymbolComponents}
        </svg>            
    </div>
}