import { useRef, useEffect, useState } from 'react'
import { Button, Checkbox, InputGroup, FormGroup, HTMLSelect, H4 } from "@blueprintjs/core"
import {  WeaponConfigMap } from "data/salvoTools"
import ShipStatusDocument from './ShipStatusDocument'

let calculateTargetScreenRating = (shipConfig) => {
    return shipConfig.systems.reduce((tsr, s) => {
        if(s.name == "screen" && s.disabled !== true) { 
            tsr.total += 1; 
            if(s.advanced == true) { tsr.advanced = true; }
        }
        if(tsr > 2) { tsr.total = 2; }
        return tsr
    }, { total: 0, advanced: false })
}
let calculateTargetStealthRating = (shipConfig) => {}

let weaponLabelMap = {
    "ads" : "Area Defense System",
    "beam" : "Beam",
    "emp" : "EMP",
    "fusion" : "Fusion Array",
    "gatling" : "Gatling Array",
    "grapeshot" : "Grapeshot",
    "graser" : "Graser",
    "gravitic" : "Gravity Gun",
    "kgun" : "Kinetic",
    "meson" : "Meson Projector",
    "mkp" : "Multiple Kinetic Penetrator",
    "needle" : "Needle Beam",
    "phaser" : "Phaser",
    "pbl" : "Plasma Bolt Launcher",
    "plasmaCannon" : "Plasma Cannon",
    "pds" : "Point Defense System",
    "torpedoPulse" : "Pulse Torpedo",
    "pulser" : "Pulser",
    "scatterGun" : "Scatter Gun",
    "spinalBeam" : "Spinal Mount Beam",
    "spinalNova" : "Spinal Mount Nova Cannon",
    "spinalPlasma" : "Spinal Mount Plasma Cannon",
    "spinalSingularity" : "Point Singularity Projector",
    "spinalWave" : "Wave Gun",
    "transporter" : "Transporter",
    "submunition" : "Submunition Pack",
    "particle" : "Twin Particle Array"
}

const getCircularSlice = (sourceArray, si, sliceSize) => {
    let startIndex = si % sourceArray.length;
    if (startIndex + sliceSize <= sourceArray.length) { 
        return sourceArray.slice(startIndex, startIndex + sliceSize)
    }
    let front = sourceArray.slice(startIndex)
    let back = sourceArray.slice(0, sliceSize - front.length)
    return front.concat(back)
    
}

const weaponArcBuilder = (weapon) => {
    if(typeof weapon.arcs == "string") { return weapon.arcs; }
    if(weapon.leftArc && weapon.numArcs) {
        let arcs = "abcdef";
        let startIndex = 0;
        switch(weapon.leftArc) {
            case "F": startIndex = 0; break;
            case "FS": startIndex = 1; break;
            case "AS": startIndex = 2; break;
            case "A": startIndex = 3; break;
            case "AP": startIndex = 4; break;
            case "FP": startIndex = 5; break;
        }
        return getCircularSlice(arcs, startIndex, weapon.numArcs) 
    }
    return "a";
}




export default function SalvoBuilder({ shipConfig, targetList = [], missionMessageBus }) {

    let [targetBearing, setTargetBearing] = useState("a");
    let [targetRange, setTargetRange] = useState(0);
    let [targetScreenRating, setTargetScreenRating] = useState(0);
    let [targetScreenType, setTargetScreenType] = useState("standard");
    let [targetStealthRating, setTargetStealthRating] = useState(0);
    let [selectedWeapons, setSelectedWeapons] = useState([]);
    let [activeTargetIndex, setActiveTargetIndex] = useState(-1)
    let [targetConfig, setTargetConfig] = useState(null);
    let [salvoResults, setSalvoResults] = useState("");
    let [phaserMode, setPhaserMode] = useState("normal")
    let [pulserMode, setPulserMode] = useState(0)
    let [pdsTargetType, setPDSTargetType] = useState("normal")

    let hasPhaserSelected = false;
    let hasPulserSelected = false;
    let hasPDSSelected = false;
    let activeWeaponElements = shipConfig.weapons.map((w, i) => {
        
        let arcs = weaponArcBuilder(w)
        let isSelected = selectedWeapons.includes(i)
        if(w.name == "phaser" && isSelected) { hasPhaserSelected = true }
        if(w.name == "pulser" && isSelected) { hasPulserSelected = true }
        if((w.name == "pds" || w.name == "ads" || (w.name == "phaser" && phaserMode == "pds")) && isSelected) { hasPDSSelected = true }
        return <div key={`weapon_${i}`}> <Checkbox 
            disabled={(w.disabled == true || !arcs.includes(targetBearing) || w.activated == true)} 
            checked={isSelected} 
            label={`${weaponLabelMap[w.name] || "Unknown"} ${w.class || ""} (${arcs})`}
            onChange={() => {
                let weaponSet = new Set(selectedWeapons);
                let selected = weaponSet.has(i)
                if(selected) { weaponSet.delete(i) } else { weaponSet.add(i)}
                setSelectedWeapons(Array.from(weaponSet))
            }}
        /> </div>
    });

    let setNewTarget = (e) => {
        let newTargetConfig = targetList[e.target.value];
        let screenRating = calculateTargetScreenRating(newTargetConfig)
        setTargetScreenRating(screenRating.total);
        setTargetScreenType((screenRating.advanced ? "advanced" : "standard"));
        setActiveTargetIndex(e.target.value);
        setTargetConfig(newTargetConfig);
    }

    let fireSalvo = () => {
        // if(confirm("Are you sure you want to fire the selected weapons?")) {
            let weaponSet = new Set(selectedWeapons);
            
            // Collect Selected Weapons
            let selectedConfigs = shipConfig.weapons.reduce((wl, w, i) => {
                let selected = weaponSet.has(i)
                if(selected) { wl.push(w) }
                return wl;
            }, [])

            let damageRollers = selectedConfigs.map((cfg) => { 
                let drFunction = WeaponConfigMap[cfg.name]["roll_damage"];
                switch(cfg.name) {
                    case "ads" : { return drFunction.bind(null, targetRange, targetScreenRating, targetStealthRating, pdsTargetType); } break;
                    case "beam" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating); } break;
                    case "emp" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating); } break;
                    case "fusion" : { return drFunction.bind(null, targetRange, (targetScreenType == "advanced" ? targetScreenRating : 0), targetStealthRating); } break;
                    case "gatling" : { return drFunction.bind(null, targetRange, targetScreenRating, targetStealthRating); } break;
                    case "grapeshot" : { return drFunction.bind(null, targetRange, targetScreenRating, targetStealthRating, pdsTargetType); } break;
                    case "graser" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating, (cfg?.heavy == true ? true : false), (cfg?.highIntensity == true ? true : false)); } break;
                    case "gravitic" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating, targetConfig.velocity); } break;
                    case "kgun" : { 
                        let rangeBand = 6;
                        if(cfg.modifier == "long") { rangeBand = 9; }
                        if(cfg.modifier == "short") { rangeBand = 4; }
                        return drFunction.bind(null, targetRange, cfg.class, (targetScreenType == "advanced" ? targetScreenRating : 0), targetStealthRating, rangeBand); 
                    } break;
                    case "meson" : { return drFunction.bind(null, targetRange, targetStealthRating); } break;
                    case "mkp" : { return drFunction.bind(null, targetRange, targetStealthRating); } break;
                    case "needle" : { return drFunction.bind(null, targetRange, cfg.class, targetStealthRating); } break;
                    case "phaser" : { 
                        if(phaserMode == "needle") { return WeaponConfigMap["needle"]["roll_damage"].bind(null, targetRange, cfg.class, targetStealthRating); }
                        if(phaserMode == "pds") { return WeaponConfigMap["pds"]["roll_damage"].bind(null, targetRange, targetScreenRating, targetStealthRating, pdsTargetType); }
                        return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating); 
                    } break; // Add Phaser Mode Select
                    case "pbl" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating ); } break;
                    case "plasmaCannon" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating ); } break;
                    case "pds" : { return drFunction.bind(null, targetRange, targetScreenRating, targetScreenRating, pdsTargetType); } break;
                    case "torpedoPulse" : { 
                        let rangeBand = 6;
                        if(cfg.modifier == "long") { rangeBand = 9; }
                        if(cfg.modifier == "short") { rangeBand = 4; }
                        return drFunction.bind(null, targetRange, targetStealthRating, rangeBand); } break;
                    case "pulser" : { 
                        return drFunction.bind(null, targetRange, pulserMode, targetScreenRating, targetStealthRating); 
                    } break; // Add Pulser Mode Select
                    case "scatterGun" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating, "missile"); } break;
                    case "spinalBeam" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating)  } break;
                    case "spinalNova" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating); } break;
                    case "spinalPlasma" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating); } break;
                    case "spinalSingularity" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating); } break;
                    case "spinalWave" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating); } break;
                    case "transporter" : { return drFunction.bind(null, targetRange, cfg.class, targetScreenRating, targetStealthRating); } break;
                    case "submunition" : { return drFunction.bind(null, targetRange, targetScreenRating, targetStealthRating); } break;
                    case "particle" : { return drFunction.bind(null, targetRange, targetScreenRating, targetStealthRating); } break;
                }
            })

            let damageResults = damageRollers.map((dr) => { return dr(); })

            let salvoResultsText = damageResults.reduce((resultsText, result, index) => {
                let weapon = selectedConfigs[index];
                let weaponName = weaponLabelMap[weapon.name];
                resultsText += `${weaponName} ${weapon?.class || ""} - ${result.damage_total} [${result.hits.join(",")}] (${result.type})\n`
                return resultsText;
            }, "")

            setSalvoResults(salvoResultsText)
        // }
    }

    return <div style={{ padding: 5 }}> 
        <H4> Salvo Builder : {shipConfig.name} </H4>
        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}> 
            <FormGroup label="Target">
                <HTMLSelect value={activeTargetIndex} onChange={setNewTarget}>
                    <option value={-1} disabled={true}> -- Select Target -- </option>
                    {targetList.map((pt, i) => {
                        return <option disabled={(shipConfig.id == pt.id)} key={`target_${pt.id}`} value={i}>{pt.name}</option>
                    })}
                </HTMLSelect>
            </FormGroup>
            <FormGroup label="Target Range">
                <InputGroup type="text" value={targetRange} onChange={(e) => { setTargetRange(e.target.value)}}/>
            </FormGroup>
            <FormGroup label="Target Bearing (Relative)">
                <HTMLSelect value={targetBearing} onChange={(e) => { setTargetBearing(e.target.value); setSelectedWeapons([]); }}>
                    <option value={"a"}>Forward</option>
                    <option value={"b"}>Starboard Forward</option>
                    <option value={"c"}>Starboard Aft</option>
                    <option value={"d"}>Aft</option>
                    <option value={"e"}>Port Aft</option>
                    <option value={"f"}>Port Forward</option>
                </HTMLSelect>
            </FormGroup>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}> 
            <FormGroup label="Target Screen Rating">
                <InputGroup type="text" value={targetScreenRating} onChange={(e) => { setTargetScreenRating(e.target.value)}}/>
            </FormGroup>
            <FormGroup label="Screen Type">
                <HTMLSelect value={targetScreenType} onChange={(e) => { setTargetScreenType(e.target.value); }}>
                    <option value={"standard"}>Standard</option>
                    <option value={"advanced"}>Advanced</option>
                </HTMLSelect>
            </FormGroup>
            <FormGroup label="Target Stealth Rating">
                <InputGroup type="text" value={targetStealthRating} onChange={(e) => { setTargetStealthRating(e.target.value)}}/>
            </FormGroup>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: 5 }}> 
            <FormGroup label="Phaser Mode">
                <HTMLSelect value={phaserMode} onChange={(e) => { setPhaserMode(e.target.value); }} disabled={!hasPhaserSelected}>
                    <option value={"standard"}>Standard</option>
                    <option value={"needle"}>Needle</option>
                    <option value={"pds"}>PDS/ADS</option>
                </HTMLSelect>
            </FormGroup>
            <FormGroup label="Pulser Mode">
                <HTMLSelect value={pulserMode} onChange={(e) => { setPulserMode(e.target.value); }} disabled={!hasPulserSelected}>
                    <option value={0}>Gatling</option>
                    <option value={1}>Twin Particle</option>
                    <option value={2}>Meson</option>
                </HTMLSelect>
            </FormGroup>
            <FormGroup label="PDS/ADS Target Type">
                <HTMLSelect value={pdsTargetType} onChange={(e) => { setPDSTargetType(e.target.value); }} disabled={!hasPDSSelected}>
                    <option value={"normal"}>Missile/Fighter</option>
                    <option value={"ship"}>Ship</option>
                </HTMLSelect>
            </FormGroup>
        </div>
        {activeWeaponElements}
        <div>
            <Button intent="danger" onClick={fireSalvo}> Fire Salvo </Button>
        </div>
        <div>
            <H4> Salvo Results </H4>
            <pre>
                {salvoResults}
            </pre>
        </div>
        {(targetConfig !== null ? <ShipStatusDocument shipID={targetConfig.id} missionMessageBus={missionMessageBus}/> : "")}
    </div>;
}