import { RegularPolygon, Line, Group, Text, Circle } from 'react-konva';
import { hexToPixel, oddQHexToPixel } from '../Util/hexTools';
import { useState, useEffect } from "react"

export default function HexCourseProjection({
        cellSize, 
        originWaypoint,
        lineWidth = 1,
        color = "red", 
        invertY = true,
        onCommit = () => {},
        onRevert = () => {}
    }) {

    let [showHeadingSelect, setShowHeadingSelect] = useState(false);

    useEffect(() => { setShowHeadingSelect(false); }, [])

    if(!originWaypoint) { return <Group></Group> }
    let controlOriginPixel = oddQHexToPixel(cellSize, originWaypoint.x, originWaypoint.y, invertY);
    return <Group>
        <RegularPolygon 
            sides={6}    
            radius={cellSize}  
            stroke={(showHeadingSelect ? "red" : "green")} 
            rotation={30} 
            fill={"white"}
            strokeWidth={lineWidth} 
            x={controlOriginPixel.x}
            y={controlOriginPixel.y}
            onClick={() => { if(showHeadingSelect) { onRevert(); } else { setShowHeadingSelect(true)} }}
            onTap={() => { if(showHeadingSelect) { onRevert(); } else { setShowHeadingSelect(true)}  }}/>
        {( showHeadingSelect ? [
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={0} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"gray"} onClick={() => { onCommit(0) }} onTap={() => { onCommit(0) }}/>,
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={60} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"green"} onClick={() => { onCommit(60) }} onTap={() => { onCommit(60) }}/>,
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={120} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"blue"} onClick={() => { onCommit(120) }} onTap={() => { onCommit(120) }}/>,
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={180} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"red"} onClick={() => { onCommit(180) }} onTap={() => { onCommit(180) }}/>,
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={240} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"orange"} onClick={() => { onCommit(240) }} onTap={() => { onCommit(240) }}/>,
            <Circle x={controlOriginPixel.x} y={controlOriginPixel.y} offsetY={cellSize * 1.5} rotation={300} radius={cellSize/2} stroke={"green"} strokeWidth={1} fill={"yellow"} onClick={() => { onCommit(300) }} onTap={() => { onCommit(300) }}/>
            ] : <Circle radius={0}/>)}
      </Group>
}