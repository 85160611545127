import { RegularPolygon, Circle, Group, Text } from 'react-konva';
import { hexToPixel, oddQHexToPixel } from '../Util/hexTools';
import { useState } from "react"

export default function HexCell({
        cellSize, 
        gridX, 
        gridY, 
        color = "black", 
        invertY = true, 
        showCoordinates = true, 
        onClick = (pos) => { console.log("Cell Clicked", pos); },
        onMouseDown = (e) => {},
        onMouseUp = (e) => {},
        onMouseEnter = (e) => {},
        onMouseLeave = (e) => {},
    }) {
    let originPixel = oddQHexToPixel(cellSize, gridX, gridY, invertY);
    let [isHovered, setIsHovered] = useState(false);
    return <RegularPolygon 
            sides={6} 
            x={originPixel.x} y={originPixel.y}
            radius={cellSize}  
            stroke={color} 
            rotation={30} 
            fill={(isHovered ? "lightgray" : "white")}
            onMouseEnter={(e) => { setIsHovered(true); onMouseEnter({x: gridX, y: gridY}, e) }}
            onMouseLeave={(e) => { setIsHovered(false); onMouseLeave({x: gridX, y: gridY}, e) }}
            onMouseDown={(e) => { if(e.evt.button != 0) {return;} onMouseDown({x: gridX, y: gridY}, e) }}
            onMouseUp={(e) => { if(e.evt.button != 0) {return;} onMouseUp({x: gridX, y: gridY}, e) }}
            strokeWidth={1} 
            onClick={() => { onClick({x: gridX, y: gridY}) }}/>
}