import { Navbar, Button, NavbarHeading, NavbarDivider, Alignment, BreadcrumbProps, Icon, ControlGroup, InputGroup, NumericInput, HTMLSelect, Tag } from "@blueprintjs/core"
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import { useState } from "react"

export default function OrderSheet() {

    let [orders, setOrders] = useState([]);
    let [turnNumber, setTurnNumber] = useState(0)
    let exampleOrder = [1, "H", 1, -2]
    return <div> 
        <Navbar>
            <Navbar.Group align={Alignment.LEFT}>
                <Button minimal={true}> Create New </Button>
                <Button minimal={true}> Load Existing </Button>
                <Button minimal={true}> Host Current </Button>
                <Button minimal={true}> Join </Button>
            </Navbar.Group>
        </Navbar>
        <div>
            <div style={{display: "flex", flexDirection: "column", gap: 5, padding: 10 }}>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading> Turn </Navbar.Heading>
                        <HTMLSelect>
                            <option value={0}> Setup </option>
                        </HTMLSelect>
                    </Navbar.Group>
                </Navbar>
                <div>
                    Ship List
                </div>
                <div>
                    Orders List
                </div>
                <ControlGroup>
                    <NumericInput size={3} min={0} value={exampleOrder[0]} rightElement={<Tag>Ship ID</Tag>} onValueChange={(v) => {}}/>
                    <HTMLSelect value={exampleOrder[1]} onChange={(e) => {}}>
                        <option value="P"> Port </option>
                        <option value="H"> Hold Course </option>
                        <option value="S"> Starboard </option>
                    </HTMLSelect>
                    <NumericInput size={3} min={0} value={exampleOrder[2]} rightElement={<Tag>Turn Points</Tag>} onValueChange={(v) => {}}/>
                    <NumericInput size={3} min={0} value={exampleOrder[3]} rightElement={<Tag>Velocity Points</Tag>} onValueChange={(v) => {}}/>
                </ControlGroup>
            </div>
        </div>
    </div>
}