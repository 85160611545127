import { useRef, useEffect, useState } from "react"
import { Stage, Layer, Rect, Circle, Image, Text, Html, Group } from 'react-konva';
import useImage from 'use-image';

export default function Token({ src, heading = 0, position = { x: 0, y: 0 }, tokenSize = 1, muSize = 100, onClick = ()=>{}, draggable = false, onDragEnd = ()=>{}, selected = false, properties = {} }) {
    const [image] = useImage(src);
    const imageRef = useRef(undefined)
    const [offset, setOffset] = useState({ x:0, y:0 })
    useEffect(() => {
        let size = imageRef.current.size();
        if(size.height !== undefined) {
            setOffset({
                x: size.width/2,
                y: size.height/2
            })
        }
    }, [image])
    
    let tokenScale = () => {
        if(imageRef.current ==  undefined) { return {x: 1, y: 1} }
        let size = imageRef.current.size();
        let tokenBaseMuSize = size.height/muSize;
        if(size.width > size.height) { tokenBaseMuSize = muSize/size.width; }
        let tokenScaleFactor = tokenSize/tokenBaseMuSize;
        return { x: tokenScaleFactor, y: tokenScaleFactor }
    }

    return <Group 
            rotation={heading}
            x={position.x} 
            y={position.y} 
            onClick={onClick.bind(null, {
                heading: heading,
                position: position,
                ...properties
            })}
            onTap={onClick.bind(null, {
                heading: heading,
                position: position,
                ...properties
            })} 
            draggable={draggable} 
            onDragEnd={onDragEnd}
        >
        <Image scale={tokenScale()} ref={imageRef} image={image} offset={offset} />
        {(selected ? <Circle x={0} y={0} stroke={"red"} radius={tokenSize/2 * muSize + 10} onClick={() => { console.log("Test Click - Origin")}} /> : <></> )}
    </Group>;
};


