function cube_to_axial(cube) {
    var q = cube.q
    var r = cube.r
    return { q: q, r: r }
}

function axial_to_cube(hex) {
    var q = hex.q
    var r = hex.r
    var s = -q-r
    return { q: q, r: r, s: s }
}

function axial_to_oddq(hex) {
    let col = hex.q;
    let row = hex.r + (hex.q - (hex.q&1)) / 2
    return { row: row, col: col }
}

function oddq_to_axial(hex) {
    let q = hex.col
    let r = hex.row - (hex.col - (hex.col&1)) / 2
    return { q: q, r: r }
}

function cube_subtract(a, b) {
    return { q: a.q - b.q, r: a.r - b.r, s: a.s - b.s }
}

function cube_distance(a, b) {
    var vec = cube_subtract(a, b)
    return (Math.abs(vec.q) + Math.abs(vec.r) + Math.abs(vec.s)) / 2
}

function axial_distance(a, b) {
    var ac = axial_to_cube(a)
    var bc = axial_to_cube(b)
    return cube_distance(ac, bc)
}

export function oddq_distance(a, b) {
    return axial_distance(oddq_to_axial(a), oddq_to_axial(b))
}

export function hexToPixel(gridSize, gridX, gridY, invertY = true) {
    let pixelX = gridSize * (3/2 * gridX)
    let pixelY = gridSize * ((Math.sqrt(3)/2 * gridX) + (Math.sqrt(3) * gridY)) * (invertY ? -1 : 1)
    return { x: pixelX, y: pixelY }
}

export function oddQHexToPixel(gridSize, gridX, gridY, invertY = true) {
    let pixelX = gridSize * (3/2) * gridX
    let pixelY = gridSize * Math.sqrt(3) * (gridY + 0.5 * (gridX & 1)) * (invertY ? -1 : 1)
    return { x: pixelX, y: pixelY }
}

export function pointToFlatHex(cellSize, x, y) {
    // Algorithm from /u/Saevax on Reddit, based on playtechs
    const edge = cellSize
    const diameter = Math.sqrt(3) * cellSize
    const radius = diameter / 2

    let t1 = x / edge
    let t2 = y / diameter

    let r = Math.floor( (Math.floor(y / radius) + Math.floor(t2 - t1) + 2 ) / 3)
    let q = Math.floor( (Math.floor(t1 - t2) + Math.floor(t1 + t2) + 2 ) / 3)

    return {q: q, r: r}
}

const moveOnFacing = (origin, heading, steps) => {
    let directionVectors = [
        [{x: 0, y: +1},{x: 1, y: 0},{x: 1, y: -1},{x: 0, y: -1},{x: -1, y: -1},{x: -1, y: 0}], 
        [{x: 0, y: +1},{x: 1, y: 1},{x: 1, y: 0},{x: 0, y: -1},{x: -1, y: 0},{x: -1, y: 1}]
    ]
    let directionUnitVector = directionVectors[origin.x&1].at(Math.floor((heading % 360)/60));
    
    let newPosition = { x: origin.x + directionUnitVector.x, y: origin.y + directionUnitVector.y };
    if(steps > 1) { return moveOnFacing(newPosition, heading, steps - 1); }
    return newPosition
}

export function projectCourse(origin, heading, startingVelocity, velocityThrust, headingThrust) {
    let finalVelocity = startingVelocity + velocityThrust;
    if(finalVelocity < 0) { finalVelocity = 0; }
    let turnPoint = moveOnFacing(origin, heading, finalVelocity - Math.abs(headingThrust))
    let newHeading = heading + Math.floor(headingThrust/Math.abs(headingThrust)) * 60
    console.log(newHeading)
    let finalPoint = moveOnFacing(turnPoint, newHeading, Math.abs(headingThrust))
    return [origin, turnPoint, finalPoint];
}