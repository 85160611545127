import { useRef, useEffect, useState } from "react"
import { Button, ProgressBar, FormGroup, HTMLSelect, H2, ControlGroup, Checkbox, Collapse, Icon } from "@blueprintjs/core"
import ToggleableComponent from "./ToggleableComponent"
import HullDisplay from "components/HullDisplay"
import ArmorRowDisplay from "components/ArmorRowDisplay"
import FighterGroupStatusDocument from "./FighterGroupStatusDocument"
import { SaveableTextField } from "./SaveableTextField"
import { WeaponIconKeyMap, SystemIconKeyMap, OrdnanceIconKeyMap } from "data/shipTools"
import { rollDN } from "data/util"

export default function ShipStatusDocument({ 
    shipID, 
    onShipUpdate = () => {},
    missionMessageBus
}) {

    const sectionHeaderStyle = { width: "100%", minHeight: 20, padding: 3,  backgroundColor: "black", color: "white", fontWeight: "bold", fontSize: "1.2rem", display: "flex", justifyContent: "space-between" }

    let [shipConfig, setShipConfig] = useState(null);
    let [editMode, setEditMode] = useState("display");
    let [pendingFighterType, setPendingFighterType] = useState("standard");
    let [pendingFighterMods, setPendingFighterMods] = useState([]);
    let [fightersExpanded, setFightersExpanded] = useState(false);

    let systemNameMap = SystemIconKeyMap
    let weaponNameMap = WeaponIconKeyMap
    let ordnanceNameMap = OrdnanceIconKeyMap

    useEffect(() => {
        console.log("JUS", missionMessageBus)
        const sus = missionMessageBus.subscribe("update_ship", (data) => {
            if(data.ship_id == shipID) { loadShipById() }
        })  

        return function () {
            missionMessageBus.unsubscribe(sus);
        }
    }, [])

    const loadShipById = async () => {
        let response = await fetch(`/ship/${shipID}`, { method: "GET"})
        if(response.status == 200) { 
            let data = await response.json()
            setShipConfig(data) 
        } else { console.error("Error Loading Ship", response.body)}
    }

    const updateShip = async (data) => {
        let response = await fetch(`/ship/${shipID}`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify(data) })
        if(response.status == 200) { onShipUpdate(data); loadShipById(); } else { console.error("Error Updating Ship", response.body)}
    }

    const removeSystem = (target_array, index) => {
        if(confirm("Are you sure you want to remove this system?")) {
            switch(target_array) {
                case "system": { shipConfig.systems.splice(index, 1); updateShip(shipConfig); } break;
                case "weapon": { shipConfig.weapons.splice(index, 1); updateShip(shipConfig); } break;
                case "ordnance": { shipConfig.ordnance.splice(index, 1); updateShip(shipConfig); } break;
                default: {} break;
            }
        }
    }

    const rollDamageThreshold = (shipConfig) => {
        let psc = { ...shipConfig }
        let rowSize = (psc.hull.points / psc.hull.rows);
        let damageRowCount = Math.floor(psc.hull.damage / rowSize);
        let threshold = 7 - damageRowCount;
        psc.systems.map((sys, idx) => {
            if(!sys.disabled) {
                let damageRoll = rollDN(6);
                if(damageRoll >= threshold) { sys.disabled = true; }
            }
        })
        psc.weapons.map((sys, idx) => {
            if(!sys.disabled) {
                let damageRoll = rollDN(6);
                if(damageRoll >= threshold) { sys.disabled = true; }
            }
        })
        console.log("Rolled Damage", rowSize, damageRowCount);
        setShipConfig(psc);
        updateShip(psc)
    }

    const resetShip = (shipConfig) => {
        if(confirm("Are you sure you want to reset this ship to its undamaged state? This cannot be undone.")) {
            let psc = { ...shipConfig }
            psc.systems.map((sys, idx) => { sys.disabled = false; sys.destroyed = false; sys.activated = false; })
            psc.weapons.map((sys, idx) => { sys.disabled = false; sys.destroyed = false; sys.activated = false; })
            psc.hull.damage = 0;
            psc.armor.map((row) => { row[2] = 0; row[3] = 0 })
            setShipConfig(psc);
            updateShip(psc)
        }
    }

    const addFighterGroup = () => {
        let psc = { ...shipConfig }
        let fighterGroups = [...psc.fighters];
        fighterGroups.push({
            type: pendingFighterType,
            mods: pendingFighterMods,
            size: [0,6],
            endurance: [0,6],
            name: `${shipConfig.name} Fighter Group ${fighterGroups.length + 1}`,
            parent_ship: shipConfig.id,
            initiative: 0
        })
        psc.fighters = fighterGroups;
        setShipConfig(psc);
        updateShip(psc)
    }

    const removeFighterGroup = (index) => {
        let psc = { ...shipConfig }
        let fighterGroups = [...psc.fighters];
        fighterGroups.splice(index, 1)
        psc.fighters = fighterGroups;
        setShipConfig(psc);
        updateShip(psc)
    }

    useEffect(() => {
        loadShipById(shipID)
    }, [shipID])

    if(shipConfig == null) { return <ProgressBar/> }
    let armorRowElements = shipConfig.armor.map((row, index) => { return <ArmorRowDisplay 
            key={`sar_${index}`} 
            row={index} 
            armorCount={row[0]} 
            regenerativeCount={row[1]} armorDamage={row[2] || 0} 
            regenerativeDamage={row[3] || 0} 
            onArmorDamageUpdate={(row, new_damage) => {
                shipConfig.armor[row][2] = new_damage;
                updateShip(shipConfig)
            }}
            onRegenerativeDamageUpdate={(row, new_damage) => {
                shipConfig.armor[row][3] = new_damage;
                updateShip(shipConfig)
            }}
        /> 
        
    })

    let editModeButtonText = `Edit Ship`
    let editModeButtonIcon = "presentation";
    if(editMode == "display") { editModeButtonText = `Edit Ship`; editModeButtonIcon = "edit" }
    if(editMode == "delete") { editModeButtonText = `Removing Systems`; editModeButtonIcon = "remove";}
    if(editMode == "add") { editModeButtonText = `Adding Systems`; editModeButtonIcon = "add"; }

    return <div>
        <H2 style={{ padding: 5 }}>Ship Status Document</H2>
        <div style={sectionHeaderStyle}>Actions</div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 5, padding: 5 }}>
            <Button intent="warning" onClick={rollDamageThreshold.bind(this, shipConfig)}>Roll Damage Threshold</Button>
            <Button intent="danger" onClick={resetShip.bind(this, shipConfig)}>Reset Ship</Button>
            <Button icon={editModeButtonIcon} disabled={true} onClick={() => {
                if(editMode == "display") { setEditMode("delete"); }
                if(editMode == "delete") { setEditMode("add"); }
                if(editMode == "add") { setEditMode("display"); }
            }}>{editModeButtonText}</Button>
        </div>
        <div style={sectionHeaderStyle}>Information</div>
        <div style={{ padding: 5, display: "flex", flexDirection: "row", flexBasis: "25%", gap: 5, flexWrap: "wrap" }}>
            <SaveableTextField value={shipConfig.name} label="Ship Name" onSave={(new_name) => {
                shipConfig.name = new_name
                updateShip(shipConfig)
            }}/>
            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <div style={{ flexBasis: "10%", flexGrow: 1 }}>
                    <SaveableTextField value={shipConfig.initiative_order} label="Initiative" onSave={(new_value) => {
                        shipConfig.initiative_order = parseInt(new_value, 10)
                        updateShip(shipConfig)
                    }}/>
                </div>
                <div style={{ flexBasis: "25%", flexGrow: 1 }}>
                    <SaveableTextField value={shipConfig.mass} label="Mass" onSave={(new_value) => {
                        shipConfig.mass = parseInt(new_value, 10)
                        updateShip(shipConfig)
                    }}/>
                </div>
                <div style={{ flexBasis: "40%", flexGrow: 1 }}>
                    <SaveableTextField value={shipConfig.class} label="Class" onSave={(new_value) => {
                        shipConfig.class = new_value
                        updateShip(shipConfig)
                    }}/>
                </div>
                <div style={{ flexBasis: "25%", flexGrow: 1 }}>
                    <FormGroup label="Faction">
                        <HTMLSelect value={shipConfig.faction} onChange={(e) => {
                            shipConfig.faction = e.target.value
                            updateShip(shipConfig)
                        }}>
                            <option value={"blue"}> Blue </option>
                            <option value={"red"}> Red </option>
                            <option value={"green"}> Green </option>
                            <option value={"orange"}> Orange </option>
                            <option value={"purple"}> Purple </option>
                            <option value={"cyan"}> Cyan </option>
                        </HTMLSelect>
                    </FormGroup>
                </div>
            </div>
            <SaveableTextField value={shipConfig.state_storage.movement_order} type="area" label="Orders/Notes" onSave={(new_value) => {
                shipConfig.state_storage.movement_order = new_value
                updateShip(shipConfig)
            }}/>
            <div>
                <ControlGroup>
                    <Checkbox label="Moved" checked={(shipConfig.state_storage?.moved === true)} onChange={() => {
                        shipConfig.state_storage.moved = !(shipConfig.state_storage?.moved || false)
                        updateShip(shipConfig)
                    }}/>
                    <Checkbox label="Attacked" checked={(shipConfig.state_storage?.attacked === true)} onChange={() => {
                        shipConfig.state_storage.attacked = !(shipConfig.state_storage?.attacked || false)
                        updateShip(shipConfig)
                    }}/>
                    <Checkbox label="DT Crossed" checked={(shipConfig.state_storage?.threshold_check_required === true)} onChange={() => {
                        shipConfig.state_storage.threshold_check_required = !(shipConfig.state_storage?.threshold_check_required || false)
                        updateShip(shipConfig)
                    }}/>
                    <Checkbox label="DT Applied" checked={(shipConfig.state_storage?.threshold_check_applied === true)} onChange={() => {
                        shipConfig.state_storage.threshold_check_applied = !(shipConfig.state_storage?.threshold_check_applied || false)
                        updateShip(shipConfig)
                    }}/>
                    <Checkbox label="Repaired" checked={(shipConfig.state_storage?.repaired === true)} onChange={() => {
                        shipConfig.state_storage.repaired = !(shipConfig.state_storage?.repaired || false)
                        updateShip(shipConfig)
                    }}/>
                    
                </ControlGroup>
            </div>
        </div>
        <div style={sectionHeaderStyle}>Hull</div>
        <div style={{ paddingTop: 5, paddingBottom: 5, overflow: "auto" }}>
            {armorRowElements}
            <HullDisplay shipMass={shipConfig.mass} hullStrength={shipConfig.hull.points} hullRows={shipConfig.hull.rows} currentDamage={shipConfig.hull?.damage || 0} onDamageUpdate={(new_damage) => {
                shipConfig.hull.damage = new_damage;
                updateShip(shipConfig)
            }}/>
        </div>
        <div style={sectionHeaderStyle}>Systems</div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 5, padding: 5 }}>
            {shipConfig.systems.sort((a,b) => { return a.name.localeCompare(b.name)}).map((s,i) => {
                let disabled = s?.disabled || false;
                let destroyed = s?.destroyed || false;
                let activated = s?.activated || false;
                let componentSymbol = systemNameMap[s.name] || (() => { return <GenericSystem/> })
                return <ToggleableComponent key={`ss_${i}`} disabled={disabled} destroyed={destroyed} activated={activated} componentSymbol={componentSymbol(s)} width={35} height={35} onToggle={(e) => { 
                    if(editMode == "add") { removeSystem("system", i) }
                    if(editMode == "display") {
                        if(disabled && destroyed) {
                            shipConfig.systems[i]["disabled"] = false;
                            shipConfig.systems[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }

                        if(disabled && !destroyed) {
                            shipConfig.systems[i]["disabled"] = true;
                            shipConfig.systems[i]["destroyed"] = true;
                            updateShip(shipConfig)
                            return
                        }

                        if(!disabled && !destroyed) {
                            shipConfig.systems[i]["disabled"] = true;
                            shipConfig.systems[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }

                    }
                }}
                onActivate={(e) => { 
                    e.preventDefault();  
                    shipConfig.systems[i]["activated"] = !activated;
                    updateShip(shipConfig)
                }}/>
            })}
        </div>
        {( shipConfig.ordnance.length > 0 ? <div style={sectionHeaderStyle}>Ordnance
        </div> : "" )}
        {( shipConfig.ordnance.length > 0 ? <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 5, padding: 5 }}>
            <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <SaveableTextField value={shipConfig.state_storage.missile_count} label="Missile Count" onSave={(new_value) => {
                    shipConfig.state_storage.missile_count = parseInt(new_value, 10)
                    updateShip(shipConfig)
                }}/>
                <SaveableTextField value={shipConfig.state_storage.mine_count} label="Mine Count" onSave={(new_value) => {
                    shipConfig.state_storage.mine_count = parseInt(new_value, 10)
                    updateShip(shipConfig)
                }}/>
                <SaveableTextField value={shipConfig.state_storage.boarding_torpedo_count} label="BT Count" onSave={(new_value) => {
                    console.log("MCUD", shipConfig)
                    shipConfig.state_storage.boarding_torpedo_count = parseInt(new_value, 10)
                    updateShip(shipConfig)
                }}/>
                <SaveableTextField value={shipConfig.state_storage.marine_count} label="Marine Count" onSave={(new_value) => {
                    shipConfig.state_storage.marine_count = parseInt(new_value, 10)
                    updateShip(shipConfig)
                }}/>
                <SaveableTextField value={shipConfig.state_storage.intruder_count} label="Intruder Count" onSave={(new_value) => {
                    shipConfig.state_storage.intruder_count = parseInt(new_value, 10)
                    updateShip(shipConfig)
                }}/>
            </div>
            {shipConfig.ordnance.sort((a,b) => { return a.name.localeCompare(b.name)}).map((s,i) => {
                let disabled = s?.disabled || false;
                let destroyed = s?.destroyed || false;
                let activated = s?.activated || false;
                let componentSymbol = ordnanceNameMap[s.name] || (() => { return <GenericSystem/> })
                return <ToggleableComponent key={`ss_${i}`} disabled={disabled} destroyed={destroyed} activated={activated} componentSymbol={componentSymbol(s)} width={35} height={35} onToggle={() => { 
                    if(editMode == "add") { removeSystem("ordnance", i) }
                    if(editMode == "display") {
                        if(disabled && destroyed) {
                            shipConfig.ordnance[i]["disabled"] = false;
                            shipConfig.ordnance[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }

                        if(disabled && !destroyed) {
                            shipConfig.ordnance[i]["disabled"] = true;
                            shipConfig.ordnance[i]["destroyed"] = true;
                            updateShip(shipConfig)
                            return
                        }

                        if(!disabled && !destroyed) {
                            shipConfig.ordnance[i]["disabled"] = true;
                            shipConfig.ordnance[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }
                    }
                }}
                onActivate={(e) => { 
                    e.preventDefault();  
                    shipConfig.ordnance[i]["activated"] = !activated;
                    updateShip(shipConfig)
                }}/>
            })}
        </div> : "" )}
        <div style={sectionHeaderStyle}>Weapons</div>
        <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 5, padding: 5 }}>
        {shipConfig.weapons.sort((a,b) => { return a.name.localeCompare(b.name)}).map((s,i) => {
                let disabled = s?.disabled || false;
                let destroyed = s?.destroyed || false;
                let activated = s?.activated || false;
                let componentSymbol = weaponNameMap[s.name] || (() => { return <GenericSystem/> })
                return <ToggleableComponent key={`sw_${i}`} componentSymbol={componentSymbol(s)} disabled={disabled} destroyed={destroyed} activated={activated} onToggle={() => { 
                    if(editMode == "add") { removeSystem("weapon", i) }
                    if(editMode == "display") {
                        if(disabled && destroyed) {
                            shipConfig.weapons[i]["disabled"] = false;
                            shipConfig.weapons[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }

                        if(disabled && !destroyed) {
                            shipConfig.weapons[i]["disabled"] = true;
                            shipConfig.weapons[i]["destroyed"] = true;
                            updateShip(shipConfig)
                            return
                        }

                        if(!disabled && !destroyed) {
                            shipConfig.weapons[i]["disabled"] = true;
                            shipConfig.weapons[i]["destroyed"] = false;
                            updateShip(shipConfig)
                            return
                        }
                    }
                }}
                onActivate={(e) => { 
                    e.preventDefault();  
                    shipConfig.weapons[i]["activated"] = !activated;
                    updateShip(shipConfig)
                }}
                />
            })}
        </div>
        {( shipConfig.systems.filter((s) =>{ return s.name == "hangar"; }).length > 0 ? <div style={sectionHeaderStyle} onClick={() => { setFightersExpanded(!fightersExpanded) }}>Fighters <Button minimal={true} icon={(fightersExpanded)? "chevron-up" : "chevron-down"}/></div> : "" )}
        <Collapse isOpen={fightersExpanded}>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 5, padding: 5 }}>
                {shipConfig.fighters.map((fg, i) => {
                    return <div key={`fg-${shipConfig.id}-${i}`}> <FighterGroupStatusDocument config={fg} onUpdate={(newConfig) => {
                        var pendingConfig = {...shipConfig };
                        pendingConfig["fighters"][i] = newConfig;
                        setShipConfig(pendingConfig);
                        updateShip(pendingConfig)
                    }}/> <Button intent="danger" icon="delete" onClick={removeFighterGroup.bind(null, i)}>Remove</Button></div>
                })}
                <FormGroup label="Add Fighter Group">
                    <ControlGroup>
                        <HTMLSelect value={pendingFighterType} onChange={(e) => setPendingFighterType(e.target.value)}>
                            <option value="assault">Assault Shuttles</option>
                            <option value="attack">Attack Fighter</option>
                            <option value="graser">Graser Fighter</option>
                            <option value="interceptor">Interceptor</option>
                            <option value="lightAttack">Light Attack Fighter</option>
                            <option value="light">Light Fighter</option>
                            <option value="lightInterceptor">Light Interceptor</option>
                            <option value="missile">Missile Fighter</option>
                            <option value="MKP">MKP Fighter</option>
                            <option value="multiRole">Multi-Role Fighter</option>
                            <option value="plasma">Plasma Fighter</option>
                            <option value="standard">Standard Fighter</option>
                            <option value="torpedo">Torpedo Fighter</option>
                        </HTMLSelect>
                        <Button icon="add" onClick={addFighterGroup}> Add Group </Button>
                    </ControlGroup>
                </FormGroup>
            </div>
        </Collapse>
        
        
    </div>
}