import { useRef, useEffect, useState } from "react"
import { Button, H2, FormGroup, ControlGroup, HTMLTable, InputGroup, AnchorButton, HTMLSelect } from "@blueprintjs/core"
export default function Missions() {

    let [missionList, setMissionList] = useState([])
    let [pendingName, setPendingName] = useState("")
    let [pendingMovementStyle, setPendingMovementStyle] = useState("hex")

    const addMission = async () => {
        let response = await fetch("/mission", {
            method: "POST",
            headers: { 
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: pendingName,
                movement_mode: pendingMovementStyle
            })
        })
        if(response.status == 200) {
            setPendingName("")
            getMissionList()
        } else {
            console.log(response.body)
        }
    }

    const getMissionList = async() => {
        let response = await fetch("/missions")
        if(response.status == 401) { window.location = "/#/login"; }
        let missions = await response.json()
        setMissionList(missions)
    }

    const removeMission = async (id) => {
        if(confirm("Are you sure you want to remove this mission?")) {
            let response = await fetch(`/mission/${id}`, {
                method: "delete"
            });
            if(response.status) { getMissionList() }
        }
    }
    

    useEffect(() => {
        getMissionList()
    }, [])

    return (<div style={{ display: "flex", justifyContent:"center", flexDirection: "column", padding: 20 }}>
        <H2>Mission List</H2>
        <div>
            <HTMLTable>
                <thead>
                    <tr>
                        <th> ID </th>
                        <th> Name </th>
                        <th> Quick Key </th>
                        <th> Movement Mode </th>
                        <th> Current Turn </th>
                        <th> Ships </th>
                        <th> Map </th>
                        <th> Delete </th>
                    </tr>
                </thead>
                <tbody>
                    {missionList.map((m, i) => {
                        return <tr key={`mission_${m.id}`}>
                            <td>{m.id}</td>
                            <td>{m.name}</td>
                            <td>{m.url_key}</td>
                            <td>{(m.movement_mode == "hex" ? "Hex Grid" : "Euclidian")}</td>
                            <td>{(m.current_turn_number == 0 ? "Setup" : m.current_turn_number )}</td>
                            <td><AnchorButton intent="primary" icon="share" href={`/#/mission/${m.id}`}> Ship List </AnchorButton></td>
                            <td><AnchorButton intent="primary" icon="share" href={`/#/map/${m.id}`}> Map </AnchorButton></td>
                            <td><Button intent="danger" icon="cross" onClick={removeMission.bind(null,m.id)}> Delete </Button></td>
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td> ID </td>
                        <td> Name </td>
                        <td> Quick Key </td>
                        <td> Movement Mode </td>
                        <td> Current Turn </td>
                        <td> Ships </td>
                        <td> Map </td>
                        <td> Delete </td>
                    </tr>
                </tfoot>
            </HTMLTable>
        </div>
        <div>
            <FormGroup>
                <ControlGroup>
                    <InputGroup value={pendingName} onChange={(e) => setPendingName(e.target.value)}/>
                    <HTMLSelect value={pendingMovementStyle} onChange={(e) => setPendingMovementStyle(e.currentTarget.value)}>
                        <option value={"hex"}>Hex Grid (q-odd)</option>
                        <option value={"euclidian"}>Euclidian</option>
                    </HTMLSelect>
                    <Button icon="plus" onClick={addMission}> Add Mission </Button>
                </ControlGroup>
            </FormGroup>
        </div>
    </div>)
}