import { rollDN } from "data/util"

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

export let WeaponConfigMap = {
    "ads" : { roll_damage: (target_range, target_screen = 0, target_stealth = 0, target_type = "normal", rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, 2, target_stealth, 6);
        let hits = buildResults(dice_count, (target_type == "ship" ? () => { 
            let roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result)
            return (roll == 6 ? 1 : 0)
        } : rollBeamHit.bind(null, target_screen, false, rollOverride)))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
    } },
    "beam" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "emp" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "subsystem:emp" };
    } },
    "fusion" : { roll_damage: (target_range, target_advanced_screen = 0, target_stealth = 0, mode, rollOverride) => {
        let flareMode = [1,2,3,4,5,6]
        let torpedoMode = [6,5,4,3,2,1]
        let range_band_size = 6 * (1 - (target_stealth * (1/6)))
        let rangeBand = Math.floor(target_range/range_band_size)
        let hitThreshold, diceCount = (mode == "flare" ? flareMode[rangeBand] : torpedoMode[rangeBand])
        let hitDieResult = rollDN(6);
        if(hitDieResult >= hitThreshold) {
            let hits = buildResults(diceCount, rollBeamHit.bind(null, target_advanced_screen, false, rollOverride))
            return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
        } else {
            return { damage_total: 0, hits: [0], type: "normal" }
        }
    } },
    "gatling" : { roll_damage: (target_range, target_screen = 0, target_stealth = 0, rollOverride) => {
        let max_range = 12 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range <= max_range ? 6 : 0)
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "grapeshot" : { roll_damage: { roll_damage: (target_range, target_screen = 0, target_stealth = 0, target_type = "normal", rollOverride) => {
        let max_range = 6 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range > max_range ? 0 : 4);
        let hits = buildResults(dice_count, (target_type == "ship" ? () => { return (rollDN(6) == 6 ? 1 : 0)} : rollBeamHit(target_screen, false)))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
    } } },
    "graser" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, heavy = false, high_intensity = false, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, (heavy ? 18 : 12));
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, (!heavy || (heavy && high_intensity))))
        let damage = hits.map((h) => buildResults(h, rollDN.bind(null,3)).reduce((dt, d) => dt+d, 0))
        return { damage_total: damage.reduce((dt,d) => dt+d,0), hits: damage, type: "sap" };
    } },
    "gravitic" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, target_velocity, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, true, rollOverride));
        let velocityDamage = Math.floor(target_velocity / 6);
        let damage = hits.map((h) => h * velocityDamage)
        return { damage_total: damage.reduce((dt,d) => dt+d,0), hits: damage, type: "penetrating" };
    } },
    "kgun" : { roll_damage: (target_range, weapon_class, target_advanced_screen = 0, target_stealth = 0, range_band_size = 6, rollOverride) => {
        let didHit = rollProjectileHit(target_range, target_stealth, range_band_size)
        return { damage_total: (didHit ? rollProjectileDamage(weapon_class, target_advanced_screen) : 0), hits: [(didHit ? 1 : 0)], type: "ap" }
    } },
    "meson" : { roll_damage: (target_range, target_stealth = 0, rollOverride) => {
        let max_range = 48 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range <= max_range ? 1 : 0)
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "mkp" : { roll_damage: (target_range, target_stealth = 0, rollOverride) => {
        let roll = rollDN(6)
        let max_range = 48 * (1 - (target_stealth * (1/6)))
        let didHit = (roll >=4 && target_range < max_range)
        return { damage_total: (didHit ? 4 : 0), hits: [(didHit ? 4 : 0)], type: "ap" }
    } },
    "needle" : { roll_damage: (target_range, weapon_class, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, 0, false, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "subsystem:needle" };
    } },
    "phaser" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, true))
        let damage = hits.map((h) => buildResults(h, rollDN.bind(null,3)).reduce((dt, d) => dt+d, 0))
        return { damage_total: damage.reduce((dt,d) => dt+d,0), hits: damage, type: "sap" };
    } },
    "pbl" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let max_range = 6 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range > max_range ? 0 : weapon_class);
        let hits = buildResults(dice_count, rollPlasmaHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "plasmaCannon" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollPlasmaHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "pds" : { roll_damage: (target_range, target_screen = 0, target_stealth = 0, target_type = "normal", rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, 1, target_stealth, 6);
        let hits = buildResults(dice_count, (target_type == "ship" ? () => { 
            let roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result)
            return (roll == 6 ? 1 : 0)
        } : rollBeamHit.bind(null, target_screen, false, rollOverride)))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
    } },
    "torpedoPulse" : { roll_damage: (target_range, target_stealth = 0, range_band_size  = 6, rollOverride) => {
        let didHit = rollProjectileHit(target_range, target_stealth, range_band_size)
        let damageRoll = (didHit ? rollDN(6) : 0)
        return { damage_total: damageRoll, hits: [damageRoll], type: "sap" }
    } },
    "pulser" : { roll_damage: (target_range, weapon_mode, target_screen, target_stealth, rollOverride) => {
        let mode_map = [6, 2, 1]
        let range_map = [12, 24, 48].map((r) => r * (1 - (target_stealth * (1/6))))
        let range_limit = range_map[weapon_mode]
        if(target_range > range_limit) { return { damage_total: 0, hits: [0] } }
        let dice_count = mode_map[weapon_mode]
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "scatterGun" : { roll_damage: { roll_damage: (target_range, target_screen = 0, target_stealth = 0, target_type = "missile", rollOverride) => {
        let max_range = 6 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range > max_range ? 0 : 1);
        let hitFunction = () => { return rollDN(6) }
        if(target_type == "heavy_fighter") { hitFunction = () => { return rollDN(3) }}
        if(target_type == "pbl" || target_type == "gunboat") { hitFunction = () => { return rollBeamHit(target_screen, false) }}
        let hits = buildResults(dice_count, hitFunction)
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "pentrating" };
    } } },
    "spinalBeam" : { roll_damage: (target_range, weapon_class, target_screen = 0, rollOverride) => {
        let max_range = (weapon_class * 12) + 12;
        if (target_range > max_range) { return { damage_total: 0, hits: [] } }
        let dice_count = 12
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "spinalNova" : { roll_damage: (target_range, weapon_class, target_screen = 0, rollOverride) => {
        let max_range = (weapon_class * 12) + 12;
        if (target_range > max_range) { return { damage_total: 0, hits: [] } }
        let dice_count = 6;
        let hits = buildResults(dice_count, rollPlasmaHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "spinalPlasma" : { roll_damage: (target_range, weapon_class, target_screen = 0, rollOverride) => {
        let max_range = (weapon_class * 12) + 12;
        if (target_range > max_range) { return { damage_total: 0, hits: [] } }
        let dice_count = 6;
        let hits = buildResults(dice_count, rollPlasmaHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "spinalSingularity" : { roll_damage: (target_range, weapon_class, target_screen = 0, rollOverride) => {
        let max_range = (weapon_class * 12) + 12;
        if (target_range > max_range) { return { damage_total: 0, hits: [] } }
        let dice_count = 2
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
    } },
    "spinalWave" : { roll_damage: (target_range, weapon_class, target_screen = 0, rollOverride) => {
        let max_range = (weapon_class * 12) + 12;
        if (target_range > max_range) { return { damage_total: 0, hits: [], type: "normal" } }
        let dice_count = 6;
        let hits = buildResults(dice_count, rollPlasmaHit.bind(null, target_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "normal" };
    } },
    "transporter" : { roll_damage: (target_range, weapon_class, target_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, weapon_class, target_stealth, 12);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "special" };
    } },
    "submunition" : { roll_damage: (target_range, target_advanced_screen = 0, target_stealth = 0, rollOverride) => {
        let dice_count = generateBeamDiceCount(target_range, 3, target_stealth, 6);
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_advanced_screen, true, rollOverride));
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
    "particle" : { roll_damage: (target_range, target_screen = 0, target_stealth = 0, rollOverride) => {
        let max_range = 24 * (1 - (target_stealth * (1/6)))
        let dice_count = (target_range <= max_range ? 2 : 0)
        let hits = buildResults(dice_count, rollBeamHit.bind(null, target_screen, false))
        return { damage_total: hits.reduce((dt,d) => dt+d,0), hits: hits, type: "penetrating" };
    } },
}

export const missileSalvoResolver = function (salvoSize, pdsCount, weaponCount, targetScreenLevel) {
    let onTargetCount = rollDN(6);
    let pdsHitCount = buildResults(pdsCount, rollBeamHit.bind(null, 0, true, undefined, [0,0,0,1,1,2])).reduce((dt,d) => dt+d,0);
    let weaponHitCount = buildResults(weaponCount, rollBeamHit.bind(null, 0, true, undefined, [0,0,0,0,1,1])).reduce((dt,d) => dt+d,0);
    let salvoPenCount = clamp(onTargetCount - pdsHitCount - weaponHitCount, 0, salvoSize);
    return buildResults(salvoPenCount, rollDN.bind(null, 6)).map((d) => clamp(d - targetScreenLevel, 0, Infinity));
}

export const generateBeamDiceCount = function (rangeToTarget, beamClass = 1, targetStealthRating = 0, rangeBandSize = 12) {
    if (targetStealthRating > 2) { targetStealthRating = 2 }
    if (targetStealthRating < 0) { targetStealthRating = 0; }
    let rangeBandRatio = (1 - (targetStealthRating/6));
    let maxRange = beamClass * rangeBandSize * rangeBandRatio;
    if (rangeToTarget == rangeBandSize ) { return beamClass; }
    if (rangeToTarget == maxRange ) { return 1; }
    if (rangeToTarget > maxRange) { return 0; }
    if (rangeToTarget == 0) { return (maxRange/rangeBandSize); }
    return Math.ceil((maxRange - rangeToTarget)/rangeBandSize)
}

export function rollBeamHit (targetScreenClass, shouldRerollCriticals = true, rollOverride = {}, outcomeTableOverride) {
    let totalDamage = 0;
    let outcomes = [0,0,0,1,1,2]
    switch(targetScreenClass) {
        case 1: { outcomes = [0,0,0,0,1,2] } break;
        case 2: { outcomes = [0,0,0,0,1,1] } break;
    }
    if(outcomeTableOverride !== undefined) { outcomes = outcomeTableOverride }
    let roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result);
    totalDamage += outcomes[roll - 1]

    
    if (shouldRerollCriticals) {
        let reroll_count = 0;
        let max_depth = (rollOverride.max_reroll_depth !== undefined ? rollOverride.max_reroll_depth : 10)
        while(roll == 6 && reroll_count < max_depth) {
            reroll_count += 1;
            totalDamage += outcomes[roll - 1]
            roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result);
        }
    }
    return totalDamage
}

export function rollPlasmaHit (targetScreenClass, shouldRerollCriticals = true, rollOverride = {}) {
    let totalDamage = 0;
    let outcomes = [0,0,1,2,3,4]
    switch(targetScreenClass) {
        case 1: { outcomes = [0,0,0,1,2,3] } break;
        case 2: { outcomes = [0,0,0,0,1,2] } break;
    }
    let roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result);
    totalDamage += outcomes[roll - 1]
    if (shouldRerollCriticals) {
        let reroll_count = 0;
        let max_depth = (rollOverride.max_reroll_depth !== undefined ? rollOverride.max_reroll_depth : 10)
        while(roll == 6 && reroll_count < max_depth) {
            reroll_count += 1;
            totalDamage += outcomes[roll - 1]
            roll = (rollOverride?.result == undefined ? rollDN(6) : rollOverride?.result);
        }
    }
    return totalDamage
}

export function rollProjectileHit (rangeToTarget, targetStealthRating = 0, rangeBandSize = 6) {
    let rangeBandRatio = (1 - (targetStealthRating/6));
    if (rangeToTarget > (rangeBandSize * rangeBandRatio * 6)) { return false; };
    let hitThreshold = (Math.ceil(rangeToTarget / (rangeBandSize * rangeBandRatio)));
    if(hitThreshold < 2) { hitThreshold = 2; }
    if(hitThreshold > 6) { hitThreshold = 6; }
    let roll = rollDN(6)
    return roll >= hitThreshold;
}

export function rollProjectileDamage (weaponClass, advancedScreenClass = 0) {
    let roll = rollDN(6)
    if (roll == 6) { return weaponClass; }
    if (roll <= weaponClass - advancedScreenClass) { return weaponClass * 2 }
    return weaponClass;
}

export function beamDiceCount(rangeToTarget, beamClass = 1, targetStealthRating = 0, rangeBandSize = 12) {
    return generateBeamDiceCount(rangeToTarget, beamClass, targetStealthRating, rangeBandSize)
}

export function heavyGraserDiceCount (rangeToTarget, beamClass = 1, targetStealthRating = 0) {
    return generateBeamDiceCount(rangeToTarget, beamClass, targetStealthRating, 18)
}

export function buildResults (count, generator) {
    let results = [];
    
    for(let i = 0; i < count; i++) { results.push(generator()) }
    return results;
}

/**
 * -- Generic Hit Calculator --
 */

const getHitDiceCount = function (rangeToTarget, weaponType = "beam", weaponClass = 1) {
    switch(weaponType) {
        case "projectile": { return 1; }
        case "gatling-battery": { return (rangeToTarget > 12 ? 0 : 6); }
        case "meson-projector": { return (rangeToTarget > 48 ? 0 : 1); }
        case "twin-particle-array": { return (rangeToTarget > 24 ? 0 : 2); }
        case "rocket": { return 1; } 
        case "heavy-graser": { return generateBeamDiceCount(rangeToTarget, weaponClass, targetStealthRating, 18) } break;
        default: { return generateBeamDiceCount(rangeToTarget, weaponClass, targetStealthRating, 12) } break;
    }
}

const rollHitDice = function (rangeToTarget, weaponType = "beam", targetScreenClass = 0, targetStealthRating = 0) {
    switch(weaponType) {
        case "projectile": { return (rollProjectileHit(rangeToTarget, targetStealthRating) ? 1 : 0) } break;
        case "heavy-graser": { return rollBeamHit(targetScreenClass, false); } break;
        default: { return rollBeamHit(targetScreenClass, true);  } break;
    }
}

const rollHitDamage = function (hitCount, diceType, rollMod = 0) {
    if(["flat", "hit"].includes(diceType)) { return hitCount + rollMod; }
    return hitCount * rollDN(diceType) + rollMod
}

const rollKineticHitDamage = function (weaponClass = 1) {
    let roll = rollDN(6)
    if (roll == 6) { return weaponClass; }
    if (roll <= weaponClass) { return weaponClass * 2 }
    return weaponClass;
}

const rollKineticHitsDamage = function (hitCount, weaponClass = 1) { 
    let currentHit = 0;
    let damageTotal = 0;
    while(currentHit < hitCount) {
        damageTotal += rollKineticHitDamage(weaponClass)
        currentHit += 1
    }
    return damageTotal;
}
