import { useRef, useEffect, useState } from "react"
import { InputGroup, Tag, FormGroup, Button, TextArea } from "@blueprintjs/core";

export function SaveableTextField({ 
    onSave = (nv) => { console.warn(`SaveableTextField has no onSave action: ${nv}`); console.trace() }, 
    value = "", 
    label = undefined,
    resetOnBlur = false,
    size = 20,
    type = "line"
}) {
    let [pendingValue, setPendingValue] = useState(value);
    let [isDirty, setDirty] = useState(false)

    useEffect(() => { setPendingValue(value); }, [value])

    return <FormGroup label={label} style={{ width: "100%" }}>
        {(type == "area" ? [<TextArea 
            key={"fg_tae"}
            type="text" 
            value={pendingValue} 
            fill={true}
            growVertical={true}
            onChange={(e) => { setPendingValue(e.target.value); setDirty(true); }} 
            onBlur={() => { if(isDirty && resetOnBlur) { setPendingValue(value); setDirty(false); } }}
            onKeyDown={(e) => { if(e.key.toLocaleLowerCase() == "enter" && isDirty) { setDirty(onSave(pendingValue) || false) } }}
        />,(isDirty ? <Button key={"fg_tasb"} intent={"success"} minimal={true} onClick={(e) => { setDirty(onSave(pendingValue) || false) }}>Save</Button> : <span></span>)] : <InputGroup 
            type="text" 
            value={pendingValue} 
            fill={true}
            size={size}
            onChange={(e) => { setPendingValue(e.target.value); setDirty(true); }} 
            onBlur={() => { if(isDirty && resetOnBlur) { setPendingValue(value); setDirty(false); } }}
            onKeyDown={(e) => { if(e.key.toLocaleLowerCase() == "enter" && isDirty) { setDirty(onSave(pendingValue) || false) } }}
            rightElement={(isDirty ? <Button intent={"success"} minimal={true} onClick={(e) => { setDirty(onSave(pendingValue) || false) }}>Save</Button> : <span></span>)}
        />)}
    </FormGroup>
}