import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, H2, FormGroup, ControlGroup, HTMLTable, InputGroup, AnchorButton, NonIdealState, ProgressBar, Card, Dialog, Checkbox, HTMLSelect } from "@blueprintjs/core"
import { Popover2, Classes } from "@blueprintjs/popover2";
import ShipWizard from "./ShipWizard"
import ShipDetails from "./ShipDetails"
import ShipStatusDocument from "components/ShipStatusDocument"
import FighterGroupStatusDocument from "components/FighterGroupStatusDocument"
import SalvoBuilder from "components/SalvoBuilder"
import ErrorBoundary from "components/ErrorBoundary"
import { SaveableTextField } from "components/SaveableTextField"


let randomString = function(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export default function ShipList({
    mission_id,
    missionMessageBus
}) {

    let [ships, setShips] = useState([])
    let [isEditingShip, setEditingShip] = useState(false)
    let [dialogOpen, setDialogOpen] = useState(false)
    let [dialogComponent, setDialogComponent] = useState(<div> Dialog Component Here </div>);
    let [hideDestroyed, setHideDestroyed] = useState(false)
    let [activeFaction, setActiveFaction] = useState("all")

    useEffect(() => {
        let wscs = missionMessageBus.subscribe("websocket_connected", () => { missionMessageBus.send("join_mission", mission_id) })
        let ujms = missionMessageBus.subscribe("user_joined_mission", (data) => {})
        let uss = missionMessageBus.subscribe("update_ship", (data) => { loadMissionShips() })
        let uis = missionMessageBus.subscribe("update_initiative", (data) => { loadMissionShips() })
        let trs = missionMessageBus.subscribe("turn_reset", (data) => { loadMissionShips() })

        return function () {
            missionMessageBus.unsubscribe(wscs);
            missionMessageBus.unsubscribe(uss);
            missionMessageBus.unsubscribe(ujms);
            missionMessageBus.unsubscribe(uis);
            missionMessageBus.unsubscribe(trs);
        }
    }, [mission_id, missionMessageBus])

    const addShipToMission = async (shipData) => {
        let response = await fetch(`/ship/${mission_id}`, { method: "POST", headers: { "Content-Type": "application/json"}, body: JSON.stringify(shipData)})
        if(response.status == 200) { loadMissionShips() } else { console.error("Error Loading Ships", response.body)}
    }
    
    const loadMissionShips = async () => {
        let response = await fetch(`/ships?mission=${mission_id}`)
        let ships = await response.json()
        setShips(ships)
    }

    const removeShipFromMission = async (ship_id) => {
        if(confirm("Delete Ship?")) {
            let response = await fetch(`/ship/${ship_id}`, { method: "DELETE" })
            if(response.status == 200) { loadMissionShips() } else { console.error("Error Loading Ships", response.body)}
        }
    }
    const onShipUpdate = async (index, ship) => {
        let currentShips = [...ships];
        currentShips[index] = ship;
        let response = await fetch(`/ship/${ship.id}`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify(ship) })
        if(response.status == 200) { setShips(currentShips) } else { console.error("Error Updating Ship", response.body)}
    }

    const rollInitiative = async () => {
        let response = await fetch(`/ships/roll_initiative/${mission_id}`, { method: "POST", headers: { "Content-Type": "application/json"}, body: JSON.stringify({})})
        if(response.status == 200) { 
            let ships = await response.json()
            setShips(ships)    
        } else { console.error("Error Loading Ships", response.body)}
    }

    useEffect(() => {
        loadMissionShips()
    }, [])

    // let rowSize = (psc.hull.points / psc.hull.rows);
    // let damageRowCount = Math.floor(psc.hull.damage / rowSize);

    let shipList = ships.sort((a,b) => { return (a.initiative_order - b.initiative_order)} ).filter((s) => {
        if(hideDestroyed && s.state_storage?.destroyed === true) { return false; }
        if(activeFaction !== "all") { return s.faction === activeFaction; }
        return true;
    }).map((s,i,sa) => {
        let hullDamage = s.hull.points - s.hull.damage
        let factionColor = "rgba(0,0,0,0.1)"
        if(s.faction == "red") { factionColor = "rgba(255,0,0,0.1)" }
        if(s.faction == "green") { factionColor = "rgba(0,255,0,0.1)" }
        if(s.faction == "blue") { factionColor = "rgba(0,0,255,0.1)" }
        if(s.faction == "purple") { factionColor = "rgba(160,32,240,0.1)" }
        if(s.faction == "orange") { factionColor = "rgba(255,165,0,0.1)" }
        if(s.faction == "cyan") { factionColor = "rgba(0,255,255,0.1)" }
        return <tr key={s.id} style={{ backgroundColor: factionColor }}>
            <td style={{ width: 100 }}><SaveableTextField value={s.initiative_order} onSave={(new_value) => {
                s.initiative_order = parseInt(new_value, 10)
                onShipUpdate(i, s)
            }}/></td>
            <td style={{ display: "flex", gap: 5, flexDirection: "column" }}><div>
                    <SaveableTextField value={s.name} onSave={(new_value) => {
                        s.name = new_value
                        onShipUpdate(i, s)
                    }}/>
                </div>
                <div> <Button minimal={true}>{s.class}</Button> </div>
            </td>
            <td><HTMLSelect value={s.faction} onChange={(e) => {
                    s.faction = e.target.value
                    onShipUpdate(i, s)
                }}>
                    <option value={"blue"}> Blue </option>
                    <option value={"red"}> Red </option>
                    <option value={"green"}> Green </option>
                    <option value={"orange"}> Orange </option>
                    <option value={"purple"}> Purple </option>
                    <option value={"cyan"}> Cyan </option>
                </HTMLSelect></td>
            <td>
                Hull: {(isNaN(hullDamage) ? s.hull.points : hullDamage)}/{s.hull.points} 
                <Checkbox label="Destroyed" checked={(s.state_storage?.destroyed === true)} onChange={() => {
                    s.state_storage.destroyed = !(s.state_storage?.destroyed || false)
                    onShipUpdate(i, s)
                }}/>
                <Checkbox label="Captured" checked={(s.state_storage?.captured === true)} onChange={() => {
                    s.state_storage.captured = !(s.state_storage?.captured || false)
                    onShipUpdate(i, s)
                }}/> 
            </td>
            <td style={{ width: 100 }}>
                <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
                    <SaveableTextField value={s.velocity} onSave={(new_value) => {
                        s.velocity = parseInt(new_value, 10)
                        onShipUpdate(i, s)
                    }}/>
                    <HTMLSelect value={s.heading} onChange={(e) => {
                        s.heading = e.target.value
                        onShipUpdate(i, s)
                    }}>
                        <option value={0}> A </option>
                        <option value={60}> B </option>
                        <option value={120}> C </option>
                        <option value={180}> D </option>
                        <option value={240}> E </option>
                        <option value={300}> F </option>
                    </HTMLSelect>
                </div>
            </td>
            <td>
                <ControlGroup>
                    <SaveableTextField value={s.state_storage.movement_order} type="area" onSave={(new_value) => {
                        s.state_storage.movement_order = new_value
                        onShipUpdate(i, s)
                    }}/>
                </ControlGroup>
                <div style={{ display: "flex", flexDirection: "row", gap: 5 }}>
                    <SaveableTextField label="Missile Count" value={s.state_storage.missile_count} onSave={(new_value) => {
                        s.state_storage.missile_count = new_value
                        onShipUpdate(i, s)
                    }}/>
                    <SaveableTextField label="Mine Count" value={s.state_storage.mine_count} onSave={(new_value) => {
                        s.state_storage.mine_count = new_value
                        onShipUpdate(i, s)
                    }}/>
                    <SaveableTextField label="Brd Trp Count" value={s.state_storage.boarding_torpedo_count} onSave={(new_value) => {
                        s.state_storage.boarding_torpedo_count = new_value
                        onShipUpdate(i, s)
                    }}/>
                    <SaveableTextField label="Marine Count" value={s.state_storage.marine_count} onSave={(new_value) => {
                        s.state_storage.marine_count = new_value
                        onShipUpdate(i, s)
                    }}/>
                    <SaveableTextField label="Intruder Count" value={s.state_storage.intruder_count} onSave={(new_value) => {
                        s.state_storage.intruder_count = new_value
                        onShipUpdate(i, s)
                    }}/>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <Checkbox label="Moved" checked={(s.state_storage?.moved === true)} onChange={() => {
                        s.state_storage.moved = !(s.state_storage?.moved || false)
                        onShipUpdate(i, s)
                    }}/>
                    <Checkbox label="Attacked" checked={(s.state_storage?.attacked === true)} onChange={() => {
                        s.state_storage.attacked = !(s.state_storage?.attacked || false)
                        onShipUpdate(i, s)
                    }}/>
                    <Checkbox label="DT Crossed" checked={(s.state_storage?.threshold_check_required === true)} onChange={() => {
                        s.state_storage.threshold_check_required = !(s.state_storage?.threshold_check_required || false)
                        onShipUpdate(i, s)
                    }}/>
                    <Checkbox label="DT Applied" checked={(s.state_storage?.threshold_check_applied === true)} onChange={() => {
                        s.state_storage.threshold_check_applied = !(s.state_storage?.threshold_check_applied || false)
                        onShipUpdate(i, s)
                    }}/>
                    <Checkbox label="Repaired" checked={(s.state_storage?.repaired === true)} onChange={() => {
                        s.state_storage.repaired = !(s.state_storage?.repaired || false)
                        onShipUpdate(i, s)
                    }}/>
                    
                </div>
            </td>
            <td style={(s.fighters.length > 0 ? { display:"flex", flexDirection: "row", gap: 5 } : {})}>{s.fighters.map((fg, fgi) => {
                
                fg = Object.assign({
                    type: 'standard',
                    mods: [],
                    size: [0,6],
                    endurance: [0,6],
                    name: `Standard Fighter Group`,
                    parentShip: "none",
                    initiative: 0,
                    launched: false
                }, fg)

                let group_intent = "none";
                if (fg.launching == true) { group_intent = "primary"; }
                if (fg.launched == true) { group_intent = "success"; }
                if(fg.endurance[0] >= fg.endurance[1]) { group_intent = "warning"; }
                if(fg.size[0] >= fg.size[1]) { group_intent = "danger"; }

                let group_icon = "airplane"

                return <Popover2 
                    key={`fg_${i}_${fgi}`}
                    interactionKind="click"
                    popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                    placement="bottom"
                    content={<Card><FighterGroupStatusDocument config={fg} onUpdate={(newConfig) => {
                        var pendingConfig = { ...s };
                        pendingConfig["fighters"][fgi] = newConfig;
                        onShipUpdate(i, pendingConfig)
                    }} targetList={ships} missionMessageBus={missionMessageBus}/></Card>} 
                    renderTarget={({ isOpen, ref, ...targetProps }) => ( <Button {...targetProps} elementRef={ref} intent={group_intent} icon={group_icon} /> )}
                />
            })}</td>
            <td>
                <ControlGroup>
                    <Popover2 
                        interactionKind="click"
                        popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
                        placement="bottom"
                        content={<Card><ShipDetails ship={s} onUpdate={onShipUpdate.bind(null, i)}/></Card>} 
                        renderTarget={({ isOpen, ref, ...targetProps }) => ( <Button {...targetProps} elementRef={ref} intent="primary" icon="compass" /> )}
                    />
                    <Button intent="success" icon="info-sign" onClick={() => { setDialogComponent(<ErrorBoundary><ShipStatusDocument shipID={s.id} onShipUpdate={onShipUpdate.bind(null, i)} missionMessageBus={missionMessageBus} /></ErrorBoundary>); setDialogOpen(true); }}/>
                    <Button intent="danger" icon="locate" onClick={() => { setDialogComponent(<ErrorBoundary><SalvoBuilder shipConfig={s} targetList={ships} missionMessageBus={missionMessageBus}/></ErrorBoundary>); setDialogOpen(true); }}/>
                    <Button intent="none" icon="duplicate" onClick={() => {
                        if(confirm(`Are you sure you want to duplicate ${s.name}?`)) {
                            let clonedShip = { ...s }
                            delete clonedShip.id;
                            addShipToMission(clonedShip); 
                        }
                    }}></Button>
                    <Button intent="danger" icon="cross" onClick={removeShipFromMission.bind(null, s.id)}></Button>
                </ControlGroup>
            </td>
        </tr>
    })

    return (<div>
        <div>
            <H2> Ship List </H2>
            <HTMLTable>
                <thead>
                    <tr>
                        <th><Button intent={"primary"} minimal={true} icon="random" onClick={rollInitiative}>Act Ord</Button></th>
                        <th>Name</th>
                        <th>
                            Faction
                            <div>
                                <HTMLSelect minimal={true} value={activeFaction} onChange={(e) => { setActiveFaction(e.target.value); }}>
                                    <option value={"all"}> All </option>
                                    <option value={"blue"}> Blue </option>
                                    <option value={"red"}> Red </option>
                                    <option value={"green"}> Green </option>
                                    <option value={"orange"}> Orange </option>
                                    <option value={"purple"}> Purple </option>
                                    <option value={"cyan"}> Cyan </option>
                                </HTMLSelect>
                            </div>
                        </th>
                        <th>
                            Status
                            <Checkbox label="Hide Destroyed" checked={hideDestroyed} onChange={() => { setHideDestroyed(!hideDestroyed) }}/>
                        </th>
                        <th>Velocity/Heading</th>
                        <th>Orders/Notes</th>
                        <th>Flight Ops</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {shipList}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Act Ord</th>
                        <th>Name</th>
                        <th>Faction</th>
                        <th>Status</th>
                        <th>Velocity/Heading</th>
                        <th>Orders/Notes</th>
                        <th>Flight Ops</th>
                        <th>Actions</th>
                    </tr>
                </tfoot>
            </HTMLTable>
        </div>
        {/* <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row", flexBasis: "50%", gap: 10 }}>
            {ships.sort((a,b) => { return (a.initiative_order - b.initiative_order)} ).map((s,i) => {
               return <ShipStatusDocument style={{ maxWidth: 600 }} key={s.id} shipID={s.id} onShipUpdate={onShipUpdate.bind(null, i)} messageBus={missionMessageBus.current} />
            })}
        </div> */}
        <ShipWizard onAddShip={addShipToMission} ships={ships}/>
        <Dialog isOpen={dialogOpen} onClose={() => { setDialogOpen(false) }} children={dialogComponent}/>
    </div>)
}