import { Layer, Group, Text } from 'react-konva';
import HexCell from './HexCell'
import { hexToPixel, oddQHexToPixel } from '../Util/hexTools';
import { useEffect, useState } from "react"

const leftPad = (s, length, padCharacter) => {
    while(s.length < length) { s = `${padCharacter}${s}` }
    return s;
}

export default function HexMap({
    gridSize = { x: 5, y: 5 }, 
    cellSize = 50, 
    color = "black", 
    invertY = true,
    onDragStart = () => {},
    onDragEnd = () => {},
    onCellClick = () => {}
}) {
    let [cells, setCells] = useState([])
    let [cellLabels, setCellLabels] = useState([])
    let [clickStartCell, setClickStart] = useState(null)
    let [currentMouseCell, setCurrentMouseCell] = useState(null)

    useEffect(() => {
        let cellElements = [];
        let cellLabelElements = []
        for(var x = 0; x < gridSize.x; x++) {
            for(var y = 0; y < gridSize.y; y++) {
                cellElements.push(<HexCell 
                    key={`ghc_${x}:${y}`} 
                    cellSize={cellSize} 
                    gridX={x} 
                    gridY={y} 
                    color={color} 
                    invertY={invertY} 
                    onMouseEnter={(cell) => { setCurrentMouseCell(cell); }}
                    onMouseDown={(cell) => { setClickStart(cell); onDragStart() }}
                    onMouseUp={(cell) => { 
                        onDragEnd(clickStartCell, currentMouseCell); 
                    }}
                    onClick={onCellClick}
                />)
                let labelPosition = oddQHexToPixel(cellSize, x, y)
                let labelText = `${leftPad(`${x}`, 2, "0")}${leftPad(`${y}`, 2, "0")}`
                cellLabelElements.push(<Text key={`ghcl_${x}:${y}`}  
                    text={labelText} 
                    fill={(((currentMouseCell?.x == x) && (currentMouseCell?.y == y)) ? "white" : "#c6c6c6")}
                    strokeWidth={0}
                    x={labelPosition.x - (labelText.length * (6/2))} 
                    y={labelPosition.y - 6} 
                    stroke={"black"}
                    listening={false}
                />)
            }
        }
        setCellLabels(cellLabelElements)
        setCells(cellElements)
    }, [gridSize, cellSize])
    
    return (
        <Group onMouseLeave={() => setCurrentMouseCell(null)}>
            {cells}
            {cellLabels}
        </Group>
    )
}