import { useEffect, useState, useReducer, useRef } from "react"
import styles from "./SystemAttributeManager.module.css"
import { InputGroup, NumericInput, FormGroup, Checkbox } from "@blueprintjs/core"
export default function SystemAttributeManager({
    arcs,
    onUpdate = () => {},
}) {

    const toggleArc = (arc) => {
        let new_arcs = "";
        if(arcs.includes("a")) { new_arcs += "a" }
        if(arcs.includes("b")) { new_arcs += "b" }
        if(arcs.includes("c")) { new_arcs += "c" }
        if(arcs.includes("d")) { new_arcs += "d" }
        if(arcs.includes("e")) { new_arcs += "e" }
        if(arcs.includes("f")) { new_arcs += "f" }
        if(new_arcs.includes(arc)) { new_arcs = new_arcs.replace(arc, "") } else { new_arcs += arc }
        onUpdate(new_arcs)
    }

    return <div className={styles.sam_base}>
        <Checkbox label="A" checked={arcs.includes("a")} onChange={toggleArc.bind(null, "a")}/>
        <Checkbox label="B" checked={arcs.includes("b")} onChange={toggleArc.bind(null, "b")}/>
        <Checkbox label="C" checked={arcs.includes("c")} onChange={toggleArc.bind(null, "c")}/>
        <Checkbox label="D" checked={arcs.includes("d")} onChange={toggleArc.bind(null, "d")}/>
        <Checkbox label="E" checked={arcs.includes("e")} onChange={toggleArc.bind(null, "e")}/>
        <Checkbox label="F" checked={arcs.includes("f")} onChange={toggleArc.bind(null, "f")}/>
    </div>
}