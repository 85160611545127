import { RegularPolygon, Circle, Group, Text } from 'react-konva';
import { hexToPixel, oddQHexToPixel } from '../Util/hexTools';
import { useState } from "react"

export default function HexCell({
        cellSize, 
        gridX, 
        gridY, 
        color = "black", 
        invertY = true, 
        showCoordinates = true,
        onClick = () => {}
    }) {
    let originPixel = oddQHexToPixel(cellSize, gridX, gridY, invertY);
    return <Group x={originPixel.x} y={originPixel.y}>
        <RegularPolygon 
            sides={6}    
            radius={cellSize}  
            stroke={color} 
            rotation={30} 
            fill={"white"}
            strokeWidth={1} 
            onClick={() => { onClick({x: gridX, y: gridY}) }}
            onTap={() => { onClick({x: gridX, y: gridY}) }}/>
        <Text  
            visible={showCoordinates}
            text={`${gridX},${gridY}`} 
            fill={"black"}
            strokeWidth={0} 
            listening={false}
        />
      </Group>
}