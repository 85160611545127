import { RegularPolygon, Line, Group, Text, Circle, Rect, Path, Arrow } from 'react-konva';
import { hexToPixel, oddQHexToPixel } from '../Util/hexTools';
import { useState, useEffect } from "react"

export default function HexCourseProjection({
        cellSize, 
        waypoints = [],
        lineWidth = 2,
        color = "red", 
        invertY = true,
        finalHeading = null
    }) {

    let [coursePoints, setCoursePoints] = useState([])

    useEffect(() => {
        let hexPoints = waypoints.reduce((pts, wp) => {
            let originPixel = oddQHexToPixel(cellSize, wp.x, wp.y, invertY);
            pts.push(originPixel.x)
            pts.push(originPixel.y)
            return pts;
        },[])
        setCoursePoints(hexPoints)
    }, [waypoints])

    return <Group>
        <Line points={coursePoints} listening={false} closed={false} stroke={color} strokeWidth={lineWidth}/>
        {(finalHeading == null ? <Circle radius={0}/> : <Circle radius={3} x={coursePoints.at(-2)} y={coursePoints.at(-1)} rotation={finalHeading} fill={color}/> )}
        {(finalHeading == null ? <Circle radius={0}/> : <Arrow x={coursePoints.at(-2)} y={coursePoints.at(-1)} fill={color} rotation={finalHeading - 90} offsetX={-20}/> )}
      </Group>
}
