export function rollDN(n, rollOverride) {
    // Override Roll Output with specific number (Unit Testing Support)
    if(typeof rollOverride == "number") { return rollOverride }
    return Math.floor(Math.random() * n) + 1
}

export function randomInt(n) {
    let val = Math.floor(Math.random() * n);
    return val;
}

export function randomFromArray(array) {
    return array[Math.floor(Math.random() * array.length)]
}

export function randomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

export function getCircularSlice (sourceArray, si, sliceSize) {
    let startIndex = si % sourceArray.length;
    if (startIndex + sliceSize <= sourceArray.length) { 
        return sourceArray.slice(startIndex, startIndex + sliceSize)
    }
    let front = sourceArray.slice(startIndex)
    let back = sourceArray.slice(0, sliceSize - front.length)
    return front.concat(back)
    
}