import { useState, useEffect, useRef } from 'react';
import { H3, FormGroup, InputGroup, NumericInput, HTMLSelect, Button, ControlGroup, HTMLTable } from "@blueprintjs/core";
import styles from "./ShipBuilder.module.css";
import { WeaponConfigMap, SystemConfigMap, OrdnanceConfigMap, WeaponIconKeyMap, SystemIconKeyMap, OrdnanceIconKeyMap } from 'data/shipTools';
import ToggleableComponent from "components/ToggleableComponent";
import { GenericSystem } from "components/componentSymbols";
import SystemAttributeManager from './SystemAttributeManager';
import { randomString } from "data/util"
export default function ShipBuilder({ id }) {
    let [name, setName] = useState("");
    let [token, setToken] = useState("");
    let [shipClass, setShipClass] = useState("Ship");
    let [mass, setMass] = useState(1);
    let [pointValue, setPointValue] = useState(0);
    let [combatPointValue, setCombatPointValue] = useState(0);
    let [hull, setHull] = useState({
        "points": 4,
        "rows": 4,
        "stealth": 0,
        "streamlining": "none"
    })
    let [armor, setArmor] = useState([])
    let [systems, setSystems] = useState([
        {...SystemConfigMap.drive, id: randomString(10)},
    ])
    let [weapons, setWeapons] = useState([])
    let [ordnance, setOrdnance] = useState([])
    let [extras, setExtras] = useState([])
    let [fighters, setFighters] = useState([])

    let [pendingWeapon, setPendingWeapon] = useState("beam")
    let [pendingSystem, setPendingSystem] = useState("drive")
    let [pendingOrdnance, setPendingOrdnance] = useState("amt")

    let removeWeapon = (index, id) => {
        let updatedList = [...weapons]
        updatedList.splice(index, 1)
        setWeapons(updatedList)
    }
    let removeSystem = (index, id) => {
        let updatedList = [...systems]
        updatedList.splice(index, 1)
        setSystems(updatedList)
    }
    let removeOrdnance = (index, id) => {
        let updatedList = [...ordnance]
        updatedList.splice(index, 1)
        setOrdnance(updatedList)
    }

    let updateWeapon = (index, data) => {
        let system = { ...weapons[index], ...data }
        let updatedList = [...weapons]
        updatedList[index] = system
        setWeapons(updatedList)
    }
    let updateSystem = (index, data) => {
        let system = { ...systems[index], ...data }
        let updatedList = [...systems]
        updatedList[index] = system
        setSystems(updatedList)
    }
    let updateOrdnance = (index, data) => {
        let system = { ...ordnance[index], ...data }
        let updatedList = [...ordnance]
        updatedList[index] = system
        setOrdnance(updatedList)
    }

    

    useEffect(() => {
        if(id !== "new") { 
            // Load Existing Ship 
            loadShip(id)
        }
    }, [id])

    let loadShip = async () => {
        let response = await fetch(`/ship_template/${shipID}`, { method: "GET" })
        if(response.status == 200) { 
            let payload = await response.json()
            setName(payload.name)
            setToken(payload.toke_url)
            setShipClass(payload.class)
            setMass(payload.mass)
            setPointValue(payload.point_value)
            setCombatPointValue(payload.combat_point_value)
            setHull(payload.hull)
            setArmor(payload.armor)
            setSystems(payload.systems)
            setWeapons(payload.weapons)
            setOrdnance(payload.ordnance)
            setFighters(payload.fighters)
            setExtras(payload.extras)
        } else { console.error("Error Loading Ship", response.body)}
    }

    let saveShip = async () => {
        let data = {}
        let response = await fetch(`/ship_template/${shipID}`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify(data) })
        if(response.status == 200) { 
            let payload = await response.json()
            loadShip(payload.id);
        } else { console.error("Error Saving Ship", response.body)}
    }

    let addWeapon = () => {
        let newWeapon = { ...WeaponConfigMap[pendingWeapon], id: randomString(10) }
        setWeapons([...weapons, newWeapon])
    }
    let addSystem = () => {
        let newSystem = {...SystemConfigMap[pendingSystem], id: randomString(10)}
        setSystems([...systems, newSystem])
    }
    let addOrdnance = () => {
        let newSystem = {...OrdnanceConfigMap[pendingOrdnance], id: randomString(10)}
        setOrdnance([...ordnance, newSystem])
    }


    return <div> 
        <H3>Ship Builder</H3> 
        <div className={styles.section_header}> <span className={styles.section_title}> Basic Information </span> </div>
        <div className={styles.section_body}>
            <FormGroup label="Name"> <InputGroup type="text" value={name} onChange={e => setName(e.target.value)}/> </FormGroup>
            <FormGroup label="Class"> <InputGroup type="text" value={shipClass} onChange={e => setShipClass(e.target.value)}/> </FormGroup>
            <FormGroup label="Mass"> <InputGroup type="text" value={mass} readOnly={true}/> </FormGroup>
        </div>
        <div className={styles.section_header}> <span className={styles.section_title}> Hull </span> </div>
        <div className={styles.section_body}>
            <FormGroup label="Hull Strength"> <NumericInput value={hull.points} onValueChange={(v) => {
                let updatedHull = { ...hull }
                updatedHull.points = parseInt(v);
                if(v < 1) { updatedHull.points = 1 }
                setHull(updatedHull);
            }}/> </FormGroup>
            <FormGroup label="Hull Row Count"> <NumericInput value={hull.rows} onValueChange={(v) => {
                let updatedHull = { ...hull }
                updatedHull.rows = parseInt(v);
                if(v < 3) { updatedHull.rows = 3; }
                if(v > 6) { updatedHull.rows = 6; }
                setHull(updatedHull);
            }}/> </FormGroup>
            <FormGroup label="Stealth Rating"> 
                <HTMLSelect value={hull.stealth} onChange={(e) => {}}>
                    <option value={0}> None </option>
                    <option value={1}> Level 1 </option>
                    <option value={2}> Level 2 </option>
                </HTMLSelect>
            </FormGroup>
            <FormGroup label="Hull Streamlining"> 
                <HTMLSelect value={hull.stealth} onChange={(e) => {}}>
                    <option value={"none"}> None </option>
                    <option value={"partial"}> Partial </option>
                    <option value={"full"}> Full </option>
                </HTMLSelect>
            </FormGroup>
        </div>
        <div className={styles.section_header}><span className={styles.section_title}> Weapons </span>  </div>
        <div className={styles.list_section_body}>
            <div className={styles.system_list}>
                {(weapons.map((s, i) => { 
                    let componentSymbol = WeaponIconKeyMap[s.name] || (() => { return <GenericSystem/> })
                    return <div className={styles.system_list_row} key={s.id}> 
                        <div> <Button intent="danger" icon={"cross"} minimal={true} onClick={removeWeapon.bind(null, i, s.id)}/></div>
                        <div> <ToggleableComponent key={`ss_${i}`} disabled={false} destroyed={false} componentSymbol={componentSymbol(s)} width={35} height={35}/></div>
                        <div>{s.label}</div>
                        <div><SystemAttributeManager config={s} onUpdate={(new_config) => {
                            updateWeapon(i, new_config)
                        }}/></div>
                    </div> 
                }))  }
            </div>
            <FormGroup>
                <ControlGroup>
                    <HTMLSelect value={pendingWeapon} onChange={(e) => setPendingWeapon(e.target.value)}>
                        {Object.keys(WeaponConfigMap).map((k) => <option key={k} value={k}>{WeaponConfigMap[k]["label"]}</option>)}
                    </HTMLSelect>
                    <Button intent="primary" icon="add" onClick={addWeapon}> Add Weapon </Button>
                </ControlGroup>
            </FormGroup>
        </div>
        <div className={styles.section_header}><span className={styles.section_title}> Systems </span>  </div>
        <div className={styles.list_section_body}>
            <div className={styles.system_list}>
                {(systems.map((s, i) => { 
                    let componentSymbol = SystemIconKeyMap[s.name] || (() => { return <GenericSystem/> })
                    return <div className={styles.system_list_row} key={s.id}> 
                        <div> <Button intent="danger" icon={"cross"} minimal={true} onClick={removeSystem.bind(null, i, s.id)}/></div>
                        <div> <ToggleableComponent key={`ss_${i}`} disabled={false} destroyed={false} componentSymbol={componentSymbol(s)} width={35} height={35}/></div>
                        <div>{s.label}</div>
                        <div><SystemAttributeManager config={s} onUpdate={(new_config) => {
                            updateSystem(i, new_config)
                        }}/></div>
                    </div> 
                }))  }
            </div>
            <FormGroup>
                <ControlGroup>
                    <HTMLSelect value={pendingSystem} onChange={(e) => setPendingSystem(e.target.value)}>
                        {Object.keys(SystemConfigMap).map((k) => <option key={k} value={k}>{SystemConfigMap[k]["label"]}</option>)}
                    </HTMLSelect>
                    <Button intent="primary" icon="add" onClick={addSystem}> Add System </Button>
                </ControlGroup>
            </FormGroup>
        </div>
        <div className={styles.section_header}><span className={styles.section_title}> Ordnance </span>  </div>
        <div className={styles.list_section_body}>
            <div className={styles.system_list}>
                    {(ordnance.map((s, i) => { 
                        let componentSymbol = OrdnanceIconKeyMap[s.name] || (() => { return <GenericSystem/> })
                        return <div className={styles.system_list_row} key={s.id}> 
                            <div> <Button intent="danger" icon={"cross"} minimal={true} onClick={removeOrdnance.bind(null, i, s.id)}/></div>
                            <div> <ToggleableComponent key={`ss_${i}`} disabled={false} destroyed={false} componentSymbol={componentSymbol(s)} width={35} height={35}/></div>
                            <div>{s.label}</div>
                            <div><SystemAttributeManager config={s} onUpdate={(new_config) => {
                                updateOrdnance(i, new_config)
                            }}/></div>
                        </div> 
                    }))  }
                </div>
                <FormGroup>
                    <ControlGroup>
                        <HTMLSelect value={pendingOrdnance} onChange={(e) => setPendingOrdnance(e.target.value)}>
                            {Object.keys(OrdnanceConfigMap).map((k) => <option key={k} value={k}>{OrdnanceConfigMap[k]["label"]}</option>)}
                        </HTMLSelect>
                        <Button intent="primary" icon="add" onClick={addOrdnance}> Add Ordnance </Button>
                    </ControlGroup>
                </FormGroup>
        </div>
        <div className={styles.section_header}><span className={styles.section_title}> Fighters </span>  </div>
        <div className={styles.section_body}>
            Fighters Here
        </div>

    </div>

}