import { useRef, useEffect, useState } from "react"
import HullDisplay from "components/HullDisplay"
import ArmorRowDisplay from "components/ArmorRowDisplay"
import SalvoBuilder from "components/SalvoBuilder"
import { WeaponConfigMap, FighterWeaponTypeConfigMap } from "data/shipTools"
import { FormGroup, InputGroup, HTMLSelect, ControlGroup, NumericInput, Checkbox, NonIdealState } from "@blueprintjs/core";
import { range } from "lodash"

export default function FighterGroupStatusDocument({ config, onUpdate = () => {}, targetList = [], missionMessageBus = null }) {

    config = Object.assign({
        type: 'standard',
        mods: [],
        size: [0,6],
        endurance: [0,6],
        name: `Standard Fighter Group`,
        parentShip: "none",
        initiative: 0,
        launched: false,
        launching: false,
        moved: false,
        attacked: false
    }, config)

    var toggleMod = (mod) => {
        var updatedMods = new Set(config.mods)
        if(updatedMods.has(mod)) { updatedMods.delete(mod) } else { updatedMods.add(mod); }
        if(updatedMods.has("longRange")) { config.endurance[1] = 9; } else { config.endurance[1] = 6; }
        onUpdate(Object.assign({...config}, { mods: Array.from(updatedMods.values()) }))
    }

    var setType = (event) => {
        var newType = event.target.value;
        if(config.type.includes("light")) { 
            config.size[1] = 8; 
            config.endurance[1] = 4; 
        } else { 
            config.size[1] = 6; 
            config.endurance[1] = 6;
        }
        if(config.mods.includes("longRange")) { 
            config.endurance[1] = 9; 
        } else { 
            config.endurance[1] = 6; 
        }

        onUpdate(Object.assign({...config}, { type: newType }))
    }

    if(config.type.includes("light")) { 
        config.size[1] = 8; 
        config.endurance[1] = 4; 
    } else { 
        config.size[1] = 6; 
        config.endurance[1] = 6;
    }
    if(config.mods.includes("longRange")) { 
        config.endurance[1] = 9; 
    } else { 
        config.endurance[1] = 6; 
    }

    return <div>
        <FormGroup label="Group Name/Type/Initiative">
            <ControlGroup>
                <InputGroup value={config.name} onChange={event => onUpdate(Object.assign({...config}, { name: event.target.value }))} />
                <HTMLSelect value={config.type} onChange={setType}>
                    <option value="assault">Assault Shuttles</option>
                    <option value="attack">Attack Fighter</option>
                    <option value="graser">Graser Fighter</option>
                    <option value="interceptor">Interceptor</option>
                    <option value="lightAttack">Light Attack Fighter</option>
                    <option value="light">Light Fighter</option>
                    <option value="lightInterceptor">Light Interceptor</option>
                    <option value="missile">Missile Fighter</option>
                    <option value="MKP">MKP Fighter</option>
                    <option value="multiRole">Multi-Role Fighter</option>
                    <option value="plasma">Plasma Fighter</option>
                    <option value="standard">Standard Fighter</option>
                    <option value="torpedo">Torpedo Fighter</option>
                </HTMLSelect>
                <NumericInput size={2} value={config.initiative} onChange={(value) => onUpdate(Object.assign({...config}, { initiative: value }))} />
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Flight Status">
            <ControlGroup>
                <Checkbox checked={config.launching == true} disabled={config.launched == true} label="Launching" onChange={(value) => onUpdate(Object.assign({...config}, { launching: !config.launching }))}/>
                <Checkbox checked={config.launched == true} label="Launched" onChange={(value) => onUpdate(Object.assign({...config}, { launched: !config.launched }))}/>
                <Checkbox checked={config.moved == true} disabled={config.launched == false} label="Moved" onChange={(value) => onUpdate(Object.assign({...config}, { moved: !config.moved }))}/>
                <Checkbox checked={config.attacked == true} disabled={config.launched == false} label="Attacked" onChange={(value) => onUpdate(Object.assign({...config}, { attacked: !config.attacked }))}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Mods">
            <ControlGroup>
                <Checkbox checked={config.mods.includes("heavy")} label="Heavy" disabled={config.type.includes("light")} onChange={toggleMod.bind(null, "heavy")}/>
                <Checkbox checked={config.mods.includes("fast")} label="Fast" onChange={toggleMod.bind(null, "fast")}/>
                <Checkbox checked={config.mods.includes("ftl")} label="FTL" onChange={toggleMod.bind(null, "ftl")}/>
                <Checkbox checked={config.mods.includes("longRange")} label="Long Range" disabled={config.type.includes("light")} onChange={toggleMod.bind(null, "longRange")}/>
                <Checkbox checked={config.mods.includes("robot")} label="Robot" onChange={toggleMod.bind(null, "robot")}/>
                <Checkbox checked={config.mods.includes("gunboat")} label="Gunboat" onChange={toggleMod.bind(null, "gunboat")}/>
                <Checkbox checked={config.mods.includes("screened")} label="Screened" onChange={toggleMod.bind(null, "screened")}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Endurance">
            <ArmorRowDisplay 
                row={0} 
                armorCount={config.endurance[1]} 
                armorDamage={config.endurance[0]} 
                onArmorDamageUpdate={(row, new_damage) => {
                    config.endurance[0] = new_damage;
                    onUpdate(config)
                }}
            /> 
        </FormGroup>
        <FormGroup label="Group Status">
            <HullDisplay 
                shipMass={0} 
                hullStrength={config.size[1]} 
                hullRows={1} 
                currentDamage={config.size[0]} 
                onDamageUpdate={(new_damage) => {
                    config.size[0] = new_damage;
                    onUpdate(config)
                }}/> 
        </FormGroup>
        {( ((targetList.length > 0) && (config.endurance[0] < config.endurance[1])) ? <SalvoBuilder shipConfig={{
            id: `ps_${config.parentShip}_fg}`,
            weapons: range(0, (config.size[1] - config.size[0]), 1).map(() => {
                return WeaponConfigMap[FighterWeaponTypeConfigMap[config.type]];
            })
        }} targetList={targetList} missionMessageBus={missionMessageBus} />: <NonIdealState title="Attack Disabled" icon="delete" description="No Targets Available/Endurance Depleted"/> )}

    </div>
}