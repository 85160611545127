import { useRef, useEffect, useState } from "react"
import ToggleableComponent from "./ToggleableComponent"
import { HullComponent, ArmorComponent, RegenerativeArmorComponent } from "./componentSymbols"
export default function HullDisplay({ 
    hullStrength = 4,
    hullRows = 4,
    currentDamage = 0,
    type = "hull",
    shipMass = 5,
    onDamageUpdate = () => {}
}) {

    function* range(start, end) {
        if (start > end) return;
        yield start;
        if (start === end) return;
        yield* range(start + 1, end);
    }

    let hullComponent = (<HullComponent/>)
    let dcpCount = Math.floor(shipMass / 20);
    let roundedDCP = (shipMass % 20) != 0;
    let dcpIndex = Math.floor(hullStrength / dcpCount);

    if(type == "armor") { hullComponent = <ArmorComponent/> }
    if(type == "regenerative") { hullComponent = <RegnerativeArmorComponent/> }

    let rowBaseLength = Math.floor(hullStrength/hullRows)
    let oddBoxCount =  hullStrength - (hullRows * rowBaseLength)
    let hullDamageIndex = 0;
    let hullRowsElements = [...range(0, hullRows-1)].map((rowIndex) => {
        let hullColumnElements = [...range(0, rowBaseLength - 1)].map((columnIndex) => {
            hullComponent = <HullComponent hasDCP={(hullDamageIndex % dcpIndex == (dcpIndex-1)) || (hullDamageIndex == (hullStrength - 1) && roundedDCP)}/> 
            let damaged = hullDamageIndex < currentDamage;
            hullDamageIndex += 1
            return <ToggleableComponent key={`${rowIndex}_${columnIndex}`} disabled={damaged} componentSymbol={hullComponent} width={25} height={25} onToggle={() => {
                onDamageUpdate(currentDamage + (damaged ? -1 : 1))
            }}/>
        })
        if(rowIndex < oddBoxCount) { 
            let damaged = hullDamageIndex < currentDamage
            hullDamageIndex += 1
            hullColumnElements.push(<ToggleableComponent key={`${rowIndex}_odd`} disabled={damaged} componentSymbol={hullComponent} width={25} height={25} onToggle={() => {
                onDamageUpdate(currentDamage + (damaged ? -1 : 1))
            }}/>)
        }
        return <div key={`hull_row_${rowIndex}`} style={{display: "flex", flexDirection: "row", paddingLeft: 5 }}>
            {hullColumnElements}
        </div>
        
    })

    return <div style={{display: "flex", flexDirection: "column", justifyContent: "center", minWidth: 50 }}>
        { hullRowsElements }
    </div>
}