import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, Checkbox, Tag, H4, NumericInput } from "@blueprintjs/core"
import { rollBeamHit, generateBeamDiceCount, rollProjectileHit, rollProjectileDamage, beamDiceCount, heavyGraserDiceCount, buildResults } from "data/salvoTools"
import { rollDN } from "data/util"
import { useState } from 'react'

export default function SystemDamageResolver() {
    let [crossedThresholdCount, setCrossedThresholdCount] = useState(0)
    let [systemCount, setSystemCount] = useState(0)
    let [results, setResults] = useState("-- No Results --")
    
    let clearResults = () => {
        setResults("-- No Results --");
    }

    let rollResults = () => {
        let damageThreshold = 6 - crossedThresholdCount;
        let thresholdResults = buildResults(systemCount, rollDN.bind(null, 6)).map((r,i) => {
            return (r >= damageThreshold ? <div>{`System ${i +1}:`} <span style={{ color: 'red' }}>Damaged</span></div> : <div>{`System ${i +1}:`} <span style={{ color: 'green' }}>Undamaged</span></div>)
        })
        setResults(thresholdResults)
    }

    return <div style={{ borderRadius: 10, backgroundColor: "#f7f7f7", padding: 20 }}> 
        <H4> Threshold Damage </H4>
        <FormGroup>
            <ControlGroup>
                <NumericInput size={3} min={0} value={crossedThresholdCount} rightElement={<Tag>Damaged Hull Rows</Tag>} onValueChange={(v) => setCrossedThresholdCount(v)}/>
                <NumericInput size={3} min={0} value={systemCount} rightElement={<Tag>System Count</Tag>} onValueChange={(v) => setSystemCount(v)}/>
            </ControlGroup>
        </FormGroup>    
        <FormGroup>
            <ControlGroup>
                <Button style={{ marginTop:-3 }} intent={"primary"} onClick={rollResults}> Roll Damage Results </Button>
            </ControlGroup>
        </FormGroup>
        <H4> Threshold Damage Results </H4>
        <div style={{ fontWeight: "bold", display: "flex", flexDirection: "column" }}>
            {results}
        </div>
    </div>
}