import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button, H2, FormGroup, ControlGroup, HTMLTable, InputGroup, AnchorButton, NonIdealState, ProgressBar } from "@blueprintjs/core"
import ShipBuilder from "./ShipBuilder"
let randomString = function(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export default function FleetManager() {
    return (<div>
        Fleet Manager
        <ShipBuilder id={"new"}/>
    </div>)
}