import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, Checkbox, Tag, H4, NumericInput } from "@blueprintjs/core"
import { rollBeamHit, generateBeamDiceCount, rollProjectileHit, rollProjectileDamage, beamDiceCount, heavyGraserDiceCount, buildResults } from "data/salvoTools"
import { rollDN } from "data/util"

import { useState } from "react"

export default function KineticResolver({rangeToTarget, targetScreenRating, targetScreenType, targetStealthRating}) {

    let [kineticClassCounts, setKineticClassCounts] = useState([0,0,0,0,0,0])
    let [projectileCount, setProjectileCount] = useState(0)
    let [rangeBandSize, setRangeBandSize] = useState(6)
    let [results, setResults] = useState("-- No Results --");

    let updateClassCount = (index, val) => {
        let currentCounts = [...kineticClassCounts]
        if(isNaN(val)) { val = 0; }
        currentCounts[index] = val
        setKineticClassCounts(currentCounts)
    }

    let clearResults = () => {
        setResults("-- No Results --");
    }

    let updateBandSize = (val) => {
        if(isNaN(val)) { val = 0; }
        setRangeBandSize(val)
    }


    let rollResults = () => {
        let hitResults = kineticClassCounts.map((count) => {
            return buildResults(count, rollProjectileHit.bind(null, rangeToTarget, targetStealthRating, rangeBandSize)).reduce((hc,h) => {
                if (h) { return hc + 1 }
                return hc;
            }, 0)
        })
        
        let damageResults = hitResults.map((classHitCount, index) => {
            var advancedScreenClass = 0;
            if (targetScreenType == "advanced") { advancedScreenClass = targetScreenRating * -1 }
            let gunResults = buildResults(classHitCount, rollProjectileDamage.bind(null, (index + 1), advancedScreenClass))
            let gunTotal = gunResults.reduce((dt,d) => dt+d,0)
            
            return {results: gunResults, total: gunTotal}
        })

        let torpedoHitResults = buildResults(projectileCount, rollProjectileHit.bind(null, rangeToTarget, targetStealthRating, rangeBandSize))
        let torpedoDamageResults = torpedoHitResults.map((h) => { 
            var baseDamage = (h ? rollDN(6) : 0) 
            var damageMod = 0;
            if (targetScreenType == "advanced") { damageMod = targetScreenRating * -1 }
            let damageTotal = baseDamage + damageMod;
            return (damageTotal < 0 ? 0 : damageTotal)
        })


        let totalDamage = damageResults.reduce((dt,d) => dt+d.total,0) + torpedoDamageResults.reduce((dt,d) => dt+d,0)
        setResults(`Total Damage: ${totalDamage}\nKinetic Components: \n${damageResults.map((r,i) => {
            return `    Class: ${i + 1} - Hits: ${hitResults[i]} - Damage: [${r.results.join(",")}] (${r.total})`;
        }).join("\n")}\n \nTorpedo Components: [${torpedoDamageResults.join(",")}]`)
    }

    return <div style={{ borderRadius: 10, backgroundColor: "#f7f7f7", padding: 20 }}> 
        <H4> Projectile Salvo </H4>
        <FormGroup>
            <ControlGroup>
                <NumericInput leftIcon="numerical" type="text" value={rangeBandSize} rightElement={<div>
                    <Button intent='primary' icon={(rangeBandSize == 4 ? "tick" : "dot")} onClick={() => updateBandSize(4)}> Short </Button>
                    <Button intent='none' icon={(rangeBandSize == 6 ? "tick" : "dot")} onClick={() => updateBandSize(6)}> Standard </Button>
                    <Button intent='warning' icon={(rangeBandSize == 9 ? "tick" : "dot")} onClick={() => updateBandSize(6)}> Long </Button>
                    </div>} onValueChange={updateBandSize}/>
                <NumericInput size={3}  min={0} value={projectileCount} rightElement={<Tag>Torpedo</Tag>} onValueChange={(v) => setProjectileCount(v)}/>
                {kineticClassCounts.map((c, i) => {
                    return <NumericInput size={3} min={0} key={"kcc_" + i} rightElement={<Tag>Class {i + 1}</Tag>} icon="numerical"  value={c} onValueChange={updateClassCount.bind(null, i)}/>
                })}
            </ControlGroup>
        </FormGroup>    
        <FormGroup>
            <ControlGroup>
                <Button style={{ marginTop:-3 }} intent={"primary"} onClick={rollResults}> Roll Salvo </Button>
            </ControlGroup>
        </FormGroup>
        <H4> Projectile Salvo Results </H4>
        <pre style={{ fontWeight: "bold" }}>
            {results}
        </pre>
    </div>
}