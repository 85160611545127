import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, Checkbox, Tag, H4, NumericInput } from "@blueprintjs/core"
import { rollBeamHit, generateBeamDiceCount, rollProjectileHit, rollProjectileDamage, beamDiceCount, heavyGraserDiceCount, buildResults } from "data/salvoTools"
import { rollDN } from "data/util"

import { useState, useEffect } from "react"

export default function BeamResolver({rangeToTarget, targetScreenRating, targetScreenType, targetStealthRating}) {
    let [beamDiceCount, setBeamDiceCount] = useState(1)
    let [beamDiceCountMod, setBeamDiceCountMod] = useState(0)
    let [autoUpdateCount, setAutoUpdateCount] = useState(true)
    let [beamClassCounts, setBeamClassCounts] = useState([0,0,0,0,0])
    let [rerollCriticals, setRerollCriticals] = useState(true);
    let [beamDistanceBandSize, setBeamDistanceBandSize] = useState(12);
    let [damageDice, setDamageDice] = useState("hit_count")
    let [results, setResults] = useState("-- No Results --");

    useEffect(() => {
        getSalvoDiceCount()
    }, [rangeToTarget, targetScreenRating, targetStealthRating, beamDistanceBandSize])
    
    let rollResults = () => {
        let hitResults = buildResults(beamDiceCount + beamDiceCountMod, rollBeamHit.bind(null, targetScreenRating, rerollCriticals))
        let damageResults = hitResults.map((h) => {
            if (damageDice == "hit_count") { return h }
            if (damageDice.startsWith("d")) { 
                return buildResults(h, rollDN.bind(null,parseInt(damageDice.slice(1)))).reduce((dt, d) => dt+d, 0)
            }
        })
        let totalDamage = damageResults.reduce((dt,d) => dt+d,0)
        setResults(`Total Damage: ${totalDamage}\nComponents: [${damageResults.join(",")}]`)
    }
    
    let clearResults = () => {
        setResults("-- No Results --");
    }
    
    let updateDiceCount = (val) => {
        if(isNaN(val)) { val = 0; }
        setBeamDiceCount(val)
    }

    let updateBandSize = (val) => {
        if(isNaN(val)) { val = 0; }
        setBeamDistanceBandSize(val)
    }

    let updateClassCount = (index, val) => {
        let currentCounts = [...beamClassCounts]
        if(isNaN(val)) { val = 0; }
        currentCounts[index] = val
        setBeamClassCounts(currentCounts)
        if (autoUpdateCount) { getSalvoDiceCount(currentCounts) }
        
    }

    let getSalvoDiceCount = (beamCounts = beamClassCounts) => {
        let count = beamCounts.reduce((diceCount, classCount, classIndex) => {
            if (classCount < 1) { return diceCount; }
            return diceCount + (generateBeamDiceCount(rangeToTarget, classIndex+1, targetStealthRating, beamDistanceBandSize) * classCount)
        }, 0)
        setBeamDiceCount(count);
    }

    return <div style={{ borderRadius: 10, backgroundColor: "#f7f7f7", padding: 20 }}> 
        <H4> Beam Salvo </H4>
        <FormGroup label="Beam Salvo Composition">
            <ControlGroup>
                {beamClassCounts.map((c, i) => {
                    return <NumericInput size={3} min={0} key={"bcc_" + i} rightElement={<Tag>Class {i + 1}</Tag>} icon="numerical" value={c} onValueChange={updateClassCount.bind(null, i)}/>
                })}
                <Checkbox label={"Auto Update Beam Counts"} checked={autoUpdateCount} onChange={() => setAutoUpdateCount(!autoUpdateCount)}/>
                <Button style={{ marginTop:-3 }} intent={"primary"} onClick={getSalvoDiceCount} disabled={autoUpdateCount}> Calculate Salvo Dice Count </Button>
            </ControlGroup>
        </FormGroup>
        <FormGroup label="Beam Hit Dice Config">
            <ControlGroup>
                <NumericInput size={2} leftIcon="numerical" type="text" value={beamDiceCount} onValueChange={updateDiceCount}/>
                <NumericInput size={2} leftIcon="plus" type="text" value={beamDiceCountMod} onValueChange={(v) => setBeamDiceCountMod(v)}/>
                <HTMLSelect value={damageDice} onChange={(e) => setDamageDice(e.currentTarget.value)}>
                    <option value="hit_count"> Standard Beam </option>
                    <option value="d3"> d3 Damage </option>
                    <option value="d6"> d6 Damage </option>
                </HTMLSelect>
                <NumericInput leftIcon="numerical" type="text" value={beamDistanceBandSize} rightElement={<div><Button intent='primary' icon={(beamDistanceBandSize == 12 ? "tick" : "dot")} onClick={() => updateBandSize(12)}> Beam </Button>
<Button intent='warning' icon={(beamDistanceBandSize == 18 ? "tick" : "dot")} onClick={() => updateBandSize(18)}> Heavy Graser </Button></div>} onValueChange={updateBandSize}/>
                <Checkbox label={"Reroll 6s"} checked={rerollCriticals} onChange={() => setRerollCriticals(!rerollCriticals)}/>
            </ControlGroup>
        </FormGroup>
        <FormGroup>
            <ControlGroup>
                <Button style={{ marginTop:-3 }} intent={"primary"} onClick={rollResults}> Roll Salvo </Button>
            </ControlGroup>
        </FormGroup>
        <H4> Beam Salvo Results </H4>
        <pre style={{ fontWeight: "bold" }}>
            {results}
        </pre>
    </div>
}