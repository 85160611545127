import React from "react"
import { NonIdealState, Callout } from "@blueprintjs/core"

export default class ErrorBoundary extends React.Component {
    constructor(props) { super(props); this.state = { hasError: false }; }
    static getDerivedStateFromError(error) { return { hasError: true }; }
    componentDidCatch(error, errorInfo) { 
        this.setState({ error: error, errorInfo: errorInfo }); 
        console.error(error, errorInfo);
    }
    render() { if (this.state.hasError) { return <div style={{ background: "white", padding: 5, borderRadius: 5 }}> <Callout><NonIdealState icon="error" title={"Component Error"} description={
        <div>
            The following error occured in this component or it's children ({window.location.toString()}):
            <Callout style={{ marginTop: 10, marginBottom: 10 }} intent="danger">{this.state.error?.message || "Unknown"}</Callout>
            {( this.props.module !== undefined ? <Callout style={{ marginTop: 10, marginBottom: 10 }} intent="info">{this.props?.module || "Unknown Module"}</Callout> : "")}
            Please screenshot this error and email it to the development team for debugging.
        </div>
    }/></Callout></div>; } return this.props.children; } 
}