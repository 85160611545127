import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';

import Mission from './Mission';
import FleetManager from './FleetManager';
import Tools from './Tools';
import OrderSheet from './OrderSheet';
import Navbar from "./Navbar"
import RegisterUser from './Account/Register';
import Login from './Account/Login';
import MissionList from './Missions';
import Map from './Map';
import UserManager from './UserManager';

const Router = HashRouter

const App = () => {
    return <div>
        <Navbar/>
        <Router>
            <Routes>
                <Route path="/order_sheet" element={<OrderSheet/>}/>
                <Route path="/ships" element={<FleetManager/>}/>
                <Route path="/missions" element={<MissionList/>}/>
                <Route path="/map/:mission_id" element={<Map/>}/>
                <Route path="/mission/:mission_id" element={<Mission/>}/>
                <Route path="/tools" element={<Tools/>}/>
                <Route path="/register" element={<RegisterUser/>}/>
                <Route path="/users" element={<UserManager/>}/>
                <Route path="/login" element={<Login/>}/>
            </Routes>
        </Router>
    </div>
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
