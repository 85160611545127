import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Tree, H4, NonIdealState } from "@blueprintjs/core"
import UserDetails from "./UserDetails";
export default function UserManager() {

    let params = useParams();

    let [userListFilter, setUserListFilter] = useState("")
    let [users, setUsers] = useState([])
    let [activeUser, setActiveUser] = useState(null);

    useEffect(() => {
        loadUsers()
    }, [])

    const loadUsers = async () => {
        let response = await fetch(`/users`)    
        let data = await response.json()
        setUsers(data)
    }

    const getUser = async (id) => {
        let response = await fetch(`/user/${id}`)    
        let data = await response.json()
        setActiveUser(data)
    }

    const saveUser = async (userData) => {
        let response = await fetch(`/user`, { method: "PUT", headers: { "Content-Type": "application/json"}, body: JSON.stringify(userData) })
        if(response.status == 200) { getUser(userData.id) } else { console.error("Error Updating User", response.body)}
    }

    const removeUser = async (userData) => {
        if(confirm("Are you sure you want to delete this user?")) {
            let response = await fetch(`/user`, { method: "DELETE", headers: { "Content-Type": "application/json"}, body: JSON.stringify(userData) })
            if(response.status == 200) { getUser(userData.id) } else { console.error("Error Updating User", response.body)}
        }
    }
    
    return (<div style={{ padding: 5, display: "flex" }}>
        <div style={{ padding: 5, maxWidth: "25%", minWidth: "100px" }}>
            <H4> User List </H4>
            <Tree contents={users.map(u => {
                return { 
                    id: u.id,
                    label: u.username,
                    icon: "user"
                }
            })}
            onNodeClick={(e) => getUser(e.id)}
            />
        </div>
        <div style={{ padding: 5, maxWidth: "70%", minWidth: "300px" }}>
            {(activeUser == null ? <NonIdealState title="Select A User"/> : <UserDetails onSave={saveUser} onRemove={removeUser} onClose={() => setActiveUser(null)} user={activeUser}/>)}
        </div>
    </div>)
}