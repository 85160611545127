import { useRef, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { 
    Button, 
    H2, 
    FormGroup, 
    ControlGroup, 
    HTMLTable, 
    InputGroup, 
    AnchorButton, 
    NonIdealState, 
    ProgressBar, 
    NumericInput, 
    HTMLSelect, 
    Tab, 
    Tabs,
    FileInput
} from "@blueprintjs/core"

let randomString = function(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export default function ShipWizard({
    onAddShip = () => {}
}) {

    let [pendingName, setPendingName] = useState("New Ship")
    let [pendingMass, setPendingMass] = useState(10)
    let [pendingClass, setPendingClass] = useState("Starship")
    let [pendingFaction, setPendingFaction] = useState("blue")
    let [pendingFighterType, setPendingFighterType] = useState("")

    let [activeTab, setActiveTab] = useState("manual")

    let buildShip = () => {
        let ship = {
            name: pendingName,
            mass: pendingMass,
            class: pendingClass,
            faction: pendingFaction
        }
        onAddShip(ship)
    }
    
    const handleFile = (evt) => {
        if(evt.target.files.length < 1) { return; }
        let file = evt.target.files[0];
        let reader = new FileReader();
        reader.onload = function (evt) {
          parseFile(evt.target.result);
        }
        reader.readAsText(file);
      }

      const parseFile = (data) => {
        let shipConfig = JSON.parse(data);
        shipConfig.armor = shipConfig.armour || shipConfig.armor
        shipConfig.point_value = shipConfig.points || shipConfig.point_value
        shipConfig.combat_point_value = shipConfig.cpv || shipConfig.cpv
        onAddShip(shipConfig)
      }

    return (<div>
        Ship Setup Wizard
        <div style={{ maxWidth: 350}}>
            <Tabs selectedTabId={activeTab} onChange={(nt) => setActiveTab(nt)}>
                <Tab id="manual" title="Manual" panel={<div>
                    <FormGroup label="Name">
                        <InputGroup type="text" value={pendingName} onChange={(e) => setPendingName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup label="Mass">
                        <NumericInput value={pendingMass} onValueChange={(v) => setPendingMass(v)}/>
                    </FormGroup>
                    <FormGroup label="Class">
                        <InputGroup type="text" value={pendingClass} onChange={(e) => setPendingClass(e.target.value)}/>
                    </FormGroup>
                    <FormGroup label="Faction">
                        <HTMLSelect value={pendingFaction} onChange={(e) => setPendingFaction(e.target.value)}>
                            <option value={"blue"}> Blue </option>
                            <option value={"red"}> Red </option>
                            <option value={"green"}> Green </option>
                            <option value={"orange"}> Orange </option>
                            <option value={"purple"}> Purple </option>
                            <option value={"cyan"}> Cyan </option>
                        </HTMLSelect>
                    </FormGroup>
                    <Button intent="primary" onClick={buildShip}> Add Ship </Button>
                </div>}/>
                <Tab id="upload"  title="JSON" panel={<div>
                    <FormGroup label="Ship SSD File (JSON Format)">
                        <FileInput text="Choose JSON..." onInputChange={handleFile} inputProps={{ onClick: (e) => { e.target.value = null; } }}/>
                    </FormGroup>
                </div>}/>
            </Tabs>
        </div>
    </div>)
}