import { useState } from "react"
import { InputGroup, ControlGroup, FormGroup, HTMLSelect, Button, H4, NumericInput, H3 } from "@blueprintjs/core"
import { rollBeamHit, rollProjectileHit, rollProjectileDamage, beamDiceCount, heavyGraserDiceCount, buildResults } from "data/salvoTools"
import { rollDN } from "data/util"

import BeamResolver from "./BeamResolver"
import KineticResolver from "./KineticResolver"
import GraserResolver from "./GraserResolver"
import HeavyGraserResolver from "./HeavyGraserResolver"
import BoardingResolver from "./BoardingResolver"
import SystemDamageResolver from "./SystemDamageResolver"
import MissileSalvoResolver from "./MissileSalvoResolver"

export default function SalvoResolver() {
    let [targetRange, setTargetRange] = useState(0)
    let [targetStealthRating, setTargetStealthRating] = useState(0)
    let [targetScreenRating, setTargetScreenRating] = useState(0)
    let [targetScreenType, setTargetScreenType] = useState('standard')

    return <div style={{ display: "flex", flexDirection: "column", gap: 10 }}> 
        <H3> Salvo Tool </H3>
        <div style={{ borderRadius: 10, backgroundColor: "#f7f7f7", padding: 20 }}>
            <H4> Target Config </H4>
            <FormGroup label="Range to Target / Screen Rating / Stealth Rating">
                <ControlGroup>
                    <NumericInput leftIcon="graph" min={0} size={5} value={targetRange} onValueChange={(number) => { setTargetRange(number); }}/>
                    <NumericInput leftIcon="selection" min={0} max={2} size={5} value={targetScreenRating} onValueChange={(number) => { setTargetScreenRating(number); }}
                        rightElement={<HTMLSelect value={targetScreenType} onChange={(e) => { setTargetScreenType(e.currentTarget.value)}}>
                        <option value="standard"> Standard </option>
                        <option value="advanced"> Advanced </option>
                    </HTMLSelect>}
                    />
                    <NumericInput leftIcon="eye-off" size={5} min={0} max={2} value={targetStealthRating} onValueChange={(number) => { setTargetStealthRating(number); }}/>
                </ControlGroup>
            </FormGroup>
        </div>

        <BeamResolver rangeToTarget={targetRange} targetScreenRating={targetScreenRating} targetStealthRating={targetStealthRating} targetScreenType={targetScreenType}/>

        <GraserResolver rangeToTarget={targetRange} targetScreenRating={targetScreenRating} targetStealthRating={targetStealthRating} targetScreenType={targetScreenType}/>
        
        <HeavyGraserResolver rangeToTarget={targetRange} targetScreenRating={targetScreenRating} targetStealthRating={targetStealthRating} targetScreenType={targetScreenType}/>
        
        <KineticResolver rangeToTarget={targetRange} targetScreenRating={targetScreenRating} targetStealthRating={targetStealthRating} targetScreenType={targetScreenType}/>
        
        <MissileSalvoResolver targetScreenRating={targetScreenRating}/>
        
        <SystemDamageResolver/>
    </div>
}